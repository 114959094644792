import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import CourtBlockFilter     from "./CourtBlockFilter";
import CourtBlockEdit       from "./CourtBlockEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Court Block List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CourtBlockList(props) {
    const { type } = props;
    const { fetch, load, loadTab, loadFilter } = useList("courtBlock", type);

    const data = Store.useState("courtBlock");
    const { loading, canEdit, list, total, activityID, filters, sort } = data;

    const { deleteElem } = Store.useAction("courtBlock");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isTab) {
            loadTab(elemID);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Close
    const handleClose = (update) => {
        endAction();
        if (update) {
            fetch();
        }
    };


    // Do the Render
    const showActivity = type !== "RESERVE_TYPE";
    const elemName     = Utils.getValue(list, "courtBlockID", elemID, "name");

    return <Main>
        <Header message="COURT_BLOCKS_NAME" icon="court-block">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content>
            <TabList selected={sort.filter} onAction={handleAction}>
                <TabItem message="COURT_BLOCKS_CURRENT_TAB"   value="Current"   />
                <TabItem message="COURT_BLOCKS_COMPLETED_TAB" value="Completed" />
                <TabItem message="COURT_BLOCKS_INACTIVE_TAB"  value="Inactive"  />
                <TabItem message="GENERAL_ALL"                value="All"       />
            </TabList>
            <Table
                fetch={load}
                sort={sort}
                none="COURT_BLOCKS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"               message="GENERAL_NAME"                isTitle grow="2" bigMobile />
                    <TableHeader field="courtBlockTypeName" message="COURT_BLOCK_TYPES_SINGULAR"  />
                    <TableHeader field="activityName"       message="ACTIVITIES_SINGULAR"         isHidden={!showActivity} />
                    <TableHeader field="courtCount"         message="COURTS_NAME"                 />
                    <TableHeader field="fromTime"           message="GENERAL_FROM_DATE"           minWidth="120" maxWidth="120" />
                    <TableHeader field="toTime"             message="GENERAL_TO_DATE"             minWidth="120" maxWidth="120" />
                    <TableHeader field="repeatsDay"         message="COURT_BLOCKS_REPEATS_WEEKLY" />
                    <TableHeader field="status"             message="GENERAL_STATUS"              maxWidth="100" align="center" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.courtBlockID} elemID={elem.courtBlockID}>
                        <TableCell message={elem.name}               />
                        <TableCell message={elem.courtBlockTypeName} />
                        <TableCell message={elem.activityName}       />
                        <TableCell message={elem.courtNames}         />
                        <TableCell message={elem.fromTimeDate}       />
                        <TableCell message={elem.toTimeDate}         />
                        <TableCell message={elem.repeatText}         />
                        <TableCell message={elem.statusName}         textColor={elem.statusColor} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="COURT_BLOCKS_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="COURT_BLOCKS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <CourtBlockFilter
            open={action.isFilter}
            filters={filters}
            activityID={activityID}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <CourtBlockEdit
            open={action.isVCE}
            elemID={elemID}
            activityID={activityID}
            onSubmit={handleEdit}
            onClose={handleClose}
        />
        <DeleteDialog
            open={action.isDelete}
            title="COURT_BLOCKS_DELETE_TITLE"
            message="COURT_BLOCKS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CourtBlockList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default CourtBlockList;
