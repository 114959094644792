import React                from "react";
import Styled               from "styled-components";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import SubscriptionContent  from "./Subscription/SubscriptionContent";
import SubscriptionEdit     from "./Subscription/SubscriptionEdit";
import PersonContent        from "./Person/PersonContent";
import CourseEdit           from "./Course/CourseEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import Content              from "Dashboard/Components/Core/Content";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";

// The API
import {
    CourseSubscription,
} from "Utils/API";



// Styles
const Container = Styled(Content)`
    position: relative;
`;



/**
 * The Course Container
 * @returns {React.ReactElement}
 */
function CourseContainer() {
    const { activityID, courseID, courseTab } = Navigate.useParams();
    const navigate = Navigate.useGoto();
    const parent   = Navigate.useParent(2);

    const { elem, canEdit } = Store.useState("course");
    const { fetchElem, confirmElem } = Store.useAction("course");

    // The Current State
    const [ exporting, setExporting ] = React.useState(false);
    const [ updates,   setUpdates   ] = React.useState(0);
    const [ dialog,    setDialog    ] = React.useState("");


    // Load the Data
    React.useEffect(() => {
        fetchElem(courseID);
    }, [ courseID ]);

    // Handle the Tab
    React.useEffect(() => {
        if (!courseTab) {
            navigate("ACTIVITIES", activityID, "COURSES", courseID, "SUBSCRIPTIONS");
        }
    }, [ courseTab ]);

    // Handles the Tab
    const handleTab = (url) => {
        navigate("ACTIVITIES", activityID, "COURSES", courseID, url);
    };

    // Handles the Export
    const handleExport = async () => {
        setExporting(true);
        const source = CourseSubscription.export({ courseID : elem.id });
        await Utils.download(source, "inscripciones.xlsx");
        setExporting(false);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        fetchElem(courseID);
        setDialog("");
    };

    // Handles the Confirm Submit
    const handleConfirm = async () => {
        setDialog("");
        const result = await confirmElem(courseID);
        if (result.success) {
            fetchElem(courseID);
            setUpdates(updates + 1);
        }
    };


    // Variables
    const canConfirm      = canEdit && elem.canConfirm;
    const isSubscriptions = Navigate.isUrl("SUBSCRIPTIONS", courseTab);
    const isPersons       = Navigate.isUrl("PERSONS", courseTab);


    // Do the Render
    return <Main>
        <Header
            message={elem.name || "SUBSCRIPTIONS_NAME"}
            icon="course"
            href={parent}
            subTitle={elem.statusName}
            subCircle={elem.statusColor}
        >
            <ActionList>
                <ActionItem
                    isHidden={!canEdit}
                    icon="export"
                    message="GENERAL_EXPORT"
                    isLoading={exporting}
                    onClick={() => handleExport()}
                />
                <ActionItem
                    isHidden={!canEdit}
                    icon="edit"
                    message="GENERAL_EDIT"
                    onClick={() => setDialog("edit")}
                />
                <ActionItem
                    isHidden={!canEdit}
                    icon="create"
                    message="SUBSCRIPTIONS_ACTION"
                    onClick={() => setDialog("subscribe")}
                />
                <ActionItem
                    isHidden={!canConfirm}
                    icon="check"
                    message="GENERAL_CONFIRM"
                    onClick={() => setDialog("confirm")}
                />
            </ActionList>
        </Header>

        <Container>
            <TabList
                selected={courseTab || NLS.url("SUBSCRIPTIONS")}
                onClick={handleTab}
            >
                <TabItem
                    message="SUBSCRIPTIONS_NAME"
                    url="SUBSCRIPTIONS"
                />
                <TabItem
                    message="COURSES_PERSONS_NAME"
                    url="PERSONS"
                />
            </TabList>

            {isSubscriptions && <SubscriptionContent
                updates={updates}
            />}
            {isPersons && <PersonContent
                updates={updates}
            />}
        </Container>

        <CourseEdit
            open={dialog === "edit"}
            isCopy={false}
            elemID={courseID}
            onSubmit={handleEdit}
            onClose={() => setDialog("")}
        />
        <SubscriptionEdit
            open={dialog === "subscribe"}
            courseID={courseID}
            onSubmit={handleEdit}
            onClose={() => setDialog("")}
        />
        <ConfirmDialog
            open={dialog === "confirm"}
            icon="check"
            title="COURSES_CONFIRM_TITLE"
            message="COURSES_CONFIRM_TEXT"
            primary="GENERAL_CONFIRM"
            content={elem.name}
            onSubmit={handleConfirm}
            onClose={() => setDialog("")}
        />
    </Main>;
}

export default CourseContainer;
