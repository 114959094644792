import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Content              from "Dashboard/Components/Core/Content";
import Menu                 from "Dashboard/Components/Menu/Menu";
import MenuItem             from "Dashboard/Components/Menu/MenuItem";
import NoneAvailable        from "Dashboard/Components/Common/NoneAvailable";
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled.main`
    box-sizing: border-box;
    height: var(--calendar-height);
    border: var(--border-width) solid var(--border-color-light);
    border-radius: var(--border-radius);
    overflow: auto;
`;

const Calendar = Styled.section.attrs(({ rows, columns }) => ({ rows, columns }))`
    display: grid;
    grid-template-rows: 60px repeat(${(props) => props.rows}, 70px);
    grid-template-columns: 60px repeat(${(props) => props.columns}, 1fr);
    grid-gap: 2px;
`;

const Cell = Styled(Html).attrs(({ isHeader, isHour, isReserve, isBlock, isInvalid, isBlank, isActive, canEdit }) => ({ isHeader, isHour, isReserve, isBlock, isInvalid, isBlank, isActive, canEdit }))`
    box-sizing: border-box;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4px;
    transition: all 0.2s;

    ${(props) => props.isHeader && `
        position: sticky;
        top: 0;
        height: 60px;
        align-self: start;
        font-weight: bold;
        font-size: 18px;
        background-color: var(--lighter-gray);
        z-index: 2;
    `}
    ${(props) => props.isHour && `
        position: sticky;
        left: 0;
        width: 60px;
        min-width: 0;
        font-weight: bold;
        font-size: 16px;
        background-color: var(--lighter-gray);
        z-index: 2;
    `}
    ${(props) => (props.isHeader && props.isHour) && `
        z-index: 3;
    `}

    ${(props) => props.isReserve && `
        font-size: 11px;
        background-color: var(--primary-color);
        color: white;
        gap: 2px;
    `}
    ${(props) => props.isBlock && `
        color: white;
    `}
    ${(props) => props.isInvalid && `
        background: repeating-linear-gradient(45deg, white, white 8px, var(--light-gray) 8px, var(--light-gray) 16px);
        cursor: not-allowed;
    `}

    ${(props) => (props.isBlank && props.isActive) && `
        background-color: var(--lightest-gray);
    `}
    ${(props) => (props.isBlank && !props.isActive) && `
        background: repeating-linear-gradient(45deg, white, white 6px, var(--lighter-gray) 6px, var(--lighter-gray) 8px);
    `}

    ${(props) => props.canEdit && `
        cursor: pointer;
        :hover {
            opacity: 0.8;
        }
    `}
`;



/**
 * The Calendar Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CalendarContent(props) {
    const { onClick, onAction } = props;

    const data = Store.useState("reserveCalendar");
    const { loading, canEdit, courts, times, reserves, blocks, invalids, blanks } = data;


    // The Current State
    const [ showMenu, setShowMenu ] = React.useState(false);
    const [ top,      setTop      ] = React.useState(0);
    const [ left,     setLeft     ] = React.useState(0);
    const [ courtID,  setCourtID  ] = React.useState(0);
    const [ fromHour, setFromHour ] = React.useState("");
    const [ toHour,   setToHour   ] = React.useState("");


    // Handles the Menu Open
    const openMenu = (e, courtID, fromHour, toHour, isActive) => {
        if (canEdit && isActive) {
            setShowMenu(true);
            setTop(e.clientY);
            setLeft(e.clientX);
            setCourtID(courtID);
            setFromHour(fromHour);
            setToHour(toHour);
        }
        e.preventDefault();
    };

    // Handles the Menu Close
    const closeMenu = () => {
        setShowMenu(false);
    };

    // Returns the Styles
    const getStyles = (gridRowStart, gridRowEnd, gridColumn, backgroundColor) => {
        return { gridRowStart, gridRowEnd, gridColumn, backgroundColor };
    };


    // Do the Render
    const isAvailable = Boolean(times.length);

    return <Content isLoading={loading}>
        <NoneAvailable
            isHidden={isAvailable}
            message="RESERVE_CALENDAR_NONE_AVAILABLE"
        />

        {isAvailable && <Container>
            <Calendar rows={times.length} columns={courts.length}>
                <Cell
                    style={getStyles(1, 1, 1)}
                    isHeader
                    isHour
                    showEmpty
                />

                {courts.map(({ key, rowStart, rowEnd, column, text }) => <Cell
                    key={key}
                    style={getStyles(rowStart, rowEnd, column)}
                    content={text}
                    canEdit={canEdit}
                    isHeader
                />)}

                {times.map(({ key, rowStart, rowEnd, column, text }) => <Cell
                    key={key}
                    style={getStyles(rowStart, rowEnd, column)}
                    content={text}
                    canEdit={canEdit}
                    isHour
                />)}

                {invalids.map(({ key, rowStart, rowEnd, column }) => <Cell
                    key={key}
                    style={getStyles(rowStart, rowEnd, column)}
                    canEdit={canEdit}
                    isInvalid
                    showEmpty
                />)}

                {reserves.map(({ key, rowStart, rowEnd, column, id, text }) => <Cell
                    key={key}
                    style={getStyles(rowStart, rowEnd, column)}
                    content={text.replaceAll("<br />", "")}
                    onClick={() => onClick("RESERVE", id)}
                    canEdit={canEdit}
                    isReserve
                    showEmpty
                />)}

                {blocks.map(({ key, rowStart, rowEnd, column, id, text, color }) => <Cell
                    key={key}
                    style={getStyles(rowStart, rowEnd, column, color)}
                    content={text}
                    onClick={() => onClick("BLOCK", id)}
                    canEdit={canEdit}
                    isBlock
                    showEmpty
                />)}

                {blanks.map(({ key, rowStart, rowEnd, column, courtID, hour, toHour, isActive }) => <Cell
                    key={key}
                    style={getStyles(rowStart, rowEnd, column)}
                    onClick={(e) => openMenu(e, courtID, hour, toHour, isActive)}
                    canEdit={canEdit && isActive}
                    isActive={isActive}
                    isBlank
                    showEmpty
                />)}
            </Calendar>
        </Container>}

        <Menu
            open={showMenu}
            top={top}
            left={left}
            onClose={closeMenu}
            gap={4}
        >
            <MenuItem
                action="RESERVE"
                message="RESERVES_CREATE_TITLE"
                onClick={() => onAction("RESERVE", courtID, fromHour, toHour)}
            />
            <MenuItem
                action="BLOCK"
                message="COURT_BLOCKS_CREATE_TITLE"
                onClick={() => onAction("BLOCK", courtID, fromHour, toHour)}
            />
        </Menu>
    </Content>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CalendarContent.propTypes = {
    onClick  : PropTypes.func.isRequired,
    onAction : PropTypes.func.isRequired,
};

export default CalendarContent;
