import React                from "react";
import PropTypes            from "prop-types";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import ViewField            from "Dashboard/Components/Form/ViewField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Notification View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function NotificationView(props) {
    const { open, elemID, onClose } = props;

    // Load the Data
    const { loading, elem } = useDialog("notification", open, elemID);


    // Do the Render
    return <ViewDialog
        open={open}
        icon="notification"
        title="NOTIFICATIONS_VIEW_TITLE"
        onClose={onClose}
        isLoading={loading}
        withSpacing
    >
        <Columns amount={elem.sentDateTime ? "2" : "1"}>
            <ViewField
                label="GENERAL_CREATED_FEM"
                value={elem.createdDateTime}
            />
            <ViewField
                label="GENERAL_SENT_TO_FEM"
                value={elem.sentDateTime}
            />
        </Columns>

        <ViewField
            label="GENERAL_SEND_TO"
            value={elem.credentialName}
        />
        <ViewField
            label="GENERAL_TITLE"
            value={elem.title}
        />
        <ViewField
            label="GENERAL_MESSAGE"
            value={elem.body}
        />
        <ViewField
            label="GENERAL_URL"
            value={process.env.REACT_APP_URL + elem.url}
        />
        <ViewField
            label="NOTIFICATIONS_EXTERNAL_ID"
            value={elem.externalID}
        />
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
NotificationView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default NotificationView;
