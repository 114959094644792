import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Relation Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function RelationEdit(props) {
    const { open, elemCode, onClose, onSubmit } = props;

    const { states, categories } = Store.useState("memberRelation");
    const { editElem } = Store.useAction("memberRelation");


    // The Initial Data
    const initialData = {
        relationCode  : 0,
        name          : "",
        stateCodes    : [],
        categoryCodes : [],
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("memberRelation", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("memberRelation", open, elemCode, null, setElem);


    // Do the Render
    return <EditDialog
        open={open}
        icon="relation"
        title="MEMBERS_RELATIONS_EDIT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="chooser"
            name="stateCodes"
            label="MEMBERS_STATES_NAME"
            options={states}
            value={data.stateCodes}
            error={errors.stateCodes}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="categoryCodes"
            label="CATEGORIES_NAME"
            options={categories}
            value={data.categoryCodes}
            error={errors.categoryCodes}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
RelationEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemCode : PropTypes.string,
};

export default RelationEdit;
