import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Notification Type Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function NotificationTypeEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { activities } = Store.useState("notificationType");
    const { editElem } = Store.useAction("notificationType");


    // The Initial Data
    const initialData = {
        notificationTypeID : 0,
        name               : "",
        forActivity        : 0,
        activityID         : 0,
        position           : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("notificationType", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("notificationType", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="notification-type"
        title={elemID ? "NOTIFICATION_TYPES_EDIT_TITLE" : "NOTIFICATION_TYPES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            type="toggle"
            name="forActivity"
            label="NOTIFICATION_TYPES_FOR_ACTIVITY"
            value={data.forActivity}
            error={errors.forActivity}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!data.forActivity}
            type="select"
            name="activityID"
            label="ACTIVITIES_SINGULAR"
            options={activities}
            value={data.activityID}
            error={errors.activityID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
NotificationTypeEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default NotificationTypeEdit;
