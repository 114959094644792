import React                from "react";

// Components
import ContactList          from "./Contact/ContactList";
import CategoryList         from "./Category/CategoryList";
import SocialList           from "./Social/SocialList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Contact Container
 * @returns {React.ReactElement}
 */
function ContactContainer() {
    return <>
        <SecondaryNav icon="contact" message="CONTACTS_SINGULAR">
            <NavigationList>
                <NavigationItem
                    message="CONTACTS_NAME"
                    url="CONTACTS"
                    icon="contact"
                />
                <NavigationItem
                    message="CATEGORIES_NAME"
                    url="CATEGORIES"
                    icon="category"
                />
                <NavigationItem
                    message="SOCIALS_NAME"
                    url="SOCIALS"
                    icon="social"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="CONTACTS"   component={ContactList}  />
            <AdminRoute url="CATEGORIES" component={CategoryList} />
            <AdminRoute url="SOCIALS"    component={SocialList}   />
        </Router>
    </>;
}

export default ContactContainer;
