import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";



/**
 * The Member View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MemberView(props) {
    const { open, elemID, onClose } = props;

    const { fetchElem, registerElem, unregisterElem } = Store.useAction("member");


    // The Current State
    const [ update,         setUpdate         ] = React.useState(false);
    const [ showUnregister, setShowUnregister ] = React.useState(false);

    // Load the Data
    const { loading, elem } = useDialog("member", open, elemID);


    // Handles the Unregister Submit
    const handleSecondary = async () => {
        let response = {};
        if (elem.isRegistered) {
            response = await unregisterElem(elemID);
        } else {
            response = await registerElem(elemID);
        }
        if (response.success) {
            fetchElem(elemID);
            setUpdate(true);
        }
    };

    // Handles the Dialog Close
    const handleClose = () => {
        onClose(update);
    };


    // Do the Render
    return <>
        <ViewDialog
            open={open}
            icon="member"
            title="MEMBERS_VIEW_TITLE"
            secondary={elem.isRegistered ? "MEMBERS_UNREGISTER_TITLE" : "MEMBERS_REGISTER_TITLE"}
            onSecondary={handleSecondary}
            onClose={handleClose}
            isLoading={loading}
            withSpacing
        >
            <Columns amount={2}>
                <ViewField
                    label="GENERAL_MEMBER_NUMBER"
                    value={elem.memberNumber}
                />
                <ViewField
                    label="MEMBERS_FATHER_NUMBER"
                    value={elem.fatherNumber}
                />
                <ViewField
                    label="MEMBERS_MOTHER_NUMBER"
                    value={elem.motherNumber}
                />
                <ViewField
                    label="MEMBERS_SPOUSE_NUMBER"
                    value={elem.spouseNumber}
                />
                <ViewField
                    label="MEMBERS_IN_CHARGE_NUMBER"
                    value={elem.inChargeNumber}
                />
                <ViewField
                    label="MEMBERS_FAMILY_GROUP"
                    value={elem.familyGroup}
                    showEmpty
                />

                <ViewField
                    label="CATEGORIES_SINGULAR"
                    value={elem.categoryName}
                />
                <ViewField
                    label="GENERAL_STATUS"
                    value={elem.stateName}
                />

                <ViewField
                    label="GENERAL_FIRST_NAME"
                    value={elem.firstName}
                    showEmpty
                />
                <ViewField
                    label="GENERAL_LAST_NAME"
                    value={elem.lastName}
                    showEmpty
                />

                <ViewField
                    label="GENERAL_DNI"
                    value={elem.dni}
                    showEmpty
                />
                <ViewField
                    label="GENERAL_BIRTH_DATE"
                    value={elem.birthDate}
                    showEmpty
                />
                <ViewField
                    label="GENERAL_EMAIL"
                    value={elem.email}
                    showEmpty
                />
                <ViewField
                    label="GENERAL_CELLPHONE"
                    value={elem.cellphone}
                    showEmpty
                />
                <ViewField
                    label="GENERAL_PHONE"
                    value={elem.phone}
                    showEmpty
                />

                <ViewField
                    label="MEMBERS_IS_REGISTERED"
                    value={elem.isRegisteredText}
                    viewClass={elem.isRegisteredClass}
                />
                <ViewField
                    label="MEMBERS_REGISTERED_DATE"
                    value={elem.registeredTimeText}
                    showEmpty
                />
                <ViewField
                    label="GENERAL_MODIFIED_AT"
                    value={elem.modifiedTimeText}
                    showEmpty
                />
            </Columns>
        </ViewDialog>

        <ConfirmDialog
            open={showUnregister}
            icon="check"
            title="MEMBERS_UNREGISTER_TITLE"
            message="MEMBERS_UNREGISTER_TEXT"
            content={elem.fullName}
            onSubmit={handleSecondary}
            onClose={() => setShowUnregister(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MemberView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default MemberView;
