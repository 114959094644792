import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Components
import ActivityEdit         from "./Activity/ActivityEdit";
import ButtonList           from "./Button/ButtonList";
import PageList             from "./Page/PageList";
import CourseList           from "./Course/Course/CourseList";
import CourseContainer      from "./Course/CourseContainer";
import ShiftContainer       from "./Shift/ShiftContainer";
import ShiftSubscription    from "./Shift/Subscription/SubscriptionList";
import TrainingContainer    from "./Training/TrainingContainer";
import TrainingSubscription from "./Training/Subscription/SubscriptionList";
import TrainingMember       from "./Training/Member/MemberList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Activity Container
 * @returns {React.ReactElement}
 */
function ActivityContainer() {
    const navigate = Navigate.useGoto();
    const { activityID } = Navigate.useParams();

    const { closeMenu } = Store.useAction("core");

    const { elem, reserveTypes } = Store.useState("activity");
    const { fetchElem } = Store.useAction("activity");

    // The Current State
    const [ action, setAction ] = Action.useAction();



    // Load the Data
    React.useEffect(() => {
        fetchElem(activityID);
    }, [ activityID ]);

    // Handles the Action
    const handleAction = (action) => {
        if (action.isCE) {
            closeMenu();
            setAction(action);
        }
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        fetchElem(activityID);
        setAction();
    };

    // Handles the Click
    const handleClick = (...urlParts) => {
        navigate("ACTIVITIES", elem.activityID, ...urlParts);
    };


    // Creates the Navigation
    const nav = React.useMemo(() => {
        const result = [];
        for (const { key, value } of reserveTypes) {
            result.push({
                key,
                message : NLS.format("RESERVES_OF", value),
                route   : "RESERVE_TYPES",
                icon    : "reserve",
            });
        }
        return result;
    }, [ JSON.stringify(reserveTypes) ]);


    // Do the Render
    return <>
        <SecondaryNav
            message={elem.name}
            fallback="ACTIVITIES_SINGULAR"
            href={Navigate.useFrom()}
            onAction={handleAction}
            canEdit
        >
            <NavigationList>
                <NavigationItem
                    message="BUTTONS_NAME"
                    url="BUTTONS"
                    icon="button"
                />
                <NavigationItem
                    message="PAGES_NAME"
                    url="PAGES"
                    icon="page"
                />
            </NavigationList>

            <NavigationList isHidden={!nav.length}>
                {nav.map((elem) => <NavigationItem
                    key={elem.key}
                    message={elem.message}
                    onClick={() => handleClick(elem.route, elem.key)}
                    icon={elem.icon}
                />)}
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="COURSES_NAME"
                    url="COURSES"
                    icon="course"
                />
                <NavigationItem
                    message="SHIFTS_NAME"
                    url="SHIFT_PAGE"
                    icon="shift"
                />
                <NavigationItem
                    message="TRAININGS_SINGULAR"
                    url="TRAINING_PAGE"
                    icon="training"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ACTIVITY">
            <AdminRoute url="BUTTONS"             component={ButtonList}           />
            <AdminRoute url="PAGES"               component={PageList}             />

            <AdminRoute url="COURSES"             component={CourseList}           />
            <AdminRoute url="COURSE"              component={CourseContainer}      />
            <AdminRoute url="COURSE_TAB"          component={CourseContainer}      />

            <AdminRoute url="SHIFT_PAGE"          component={ShiftContainer}       />
            <AdminRoute url="SHIFT_TAB"           component={ShiftContainer}       />
            <AdminRoute url="SHIFT"               component={ShiftSubscription}    />

            <AdminRoute url="TRAINING_PAGE"       component={TrainingContainer}    />
            <AdminRoute url="TRAINING_TAB"        component={TrainingContainer}    />
            <AdminRoute url="TRAINING"            component={TrainingSubscription} />
            <AdminRoute url="TRAINING_WHITE_LIST" component={TrainingMember}       />
        </Router>

        <ActivityEdit
            open={action.isEdit}
            elemID={activityID}
            onSubmit={handleEdit}
            onClose={setAction}
        />
    </>;
}

export default ActivityContainer;
