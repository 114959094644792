import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import { Member }           from "Utils/API";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Subscription Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubscriptionEdit(props) {
    const { open, courseID, elemID, onClose, onSubmit } = props;

    const { questions, members } = Store.useState("courseSubscription");
    const { fetchEditData, editElem } = Store.useAction("courseSubscription");


    // The Initial Data
    const initialData = {
        subscriptionID : 0,
        courseID       : 0,
        memberNumber   : 0,
        memberName     : "",
        persons        : "[{}]",
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({
            ...elem,
            courseID : courseID,
            persons  : elem.persons?.length ? JSON.stringify(elem.persons) : "[{}]",
        });
    };

    // Handles the Date
    const handleUpdate = async (name, value, suggestID, newID) => {
        handleChange(name, value, suggestID, newID);
        if (suggestID === "memberNumber") {
            await fetchEditData(data.courseID, newID, false);
        }
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("courseSubscription", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("courseSubscription", open, elemID, { courseID }, handleSet);


    // Variables
    const isEdit = Boolean(elemID);


    // Do the Render
    return <EditDialog
        open={open}
        icon="subscription"
        title={isEdit ? "SUBSCRIPTIONS_EDIT_TITLE" : "SUBSCRIPTIONS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            isHidden={isEdit}
            type="suggest"
            name="memberName"
            label="GENERAL_MEMBER"
            value={data.memberName}
            error={errors.memberNumber}
            suggestID="memberNumber"
            suggestFetch={Member.search}
            onChange={handleUpdate}
            isRequired
        />

        <InputField
            isHidden={!data.memberNumber}
            type="fields"
            name="persons"
            title="SUBSCRIPTIONS_CHILD_TITLE"
            addButton="SUBSCRIPTIONS_ADD_CHILD"
            value={data.persons}
            error={errors.persons}
            errors={errors}
            onChange={handleUpdate}
            columns="2"
        >
            <InputItem
                type="select"
                name="personType"
                label="SUBSCRIPTIONS_TYPE"
                options="SELECT_PERSON_TYPES"
                isRequired
            />
            <InputItem
                hide={(elem) => elem.personType !== "Member"}
                type="select"
                name="memberNumber"
                label="GENERAL_MEMBER_NUMBER"
                options={members}
                isRequired
            />

            <InputItem
                hide={(elem) => elem.personType !== "Minor"}
                name="firstName"
                label="GENERAL_FIRST_NAME"
                isRequired
            />
            <InputItem
                hide={(elem) => elem.personType !== "Minor"}
                name="lastName"
                label="GENERAL_LAST_NAME"
                isRequired
            />
            <InputItem
                hide={(elem) => elem.personType !== "Minor"}
                type="select"
                name="documentType"
                label="GENERAL_DOCUMENT_TYPE"
                options="SELECT_DOCUMENT_TYPES"
                isRequired
            />
            <InputItem
                hide={(elem) => elem.personType !== "Minor"}
                name="documentValue"
                label="GENERAL_DOCUMENT_VALUE"
                isRequired
            />
            <InputItem
                hide={(elem) => elem.personType !== "Minor"}
                type="date"
                name="birthDate"
                label="GENERAL_BIRTH_DATE"
                isRequired
            />

            {questions.map((question) => <InputItem
                hide={(elem) => !elem.personType}
                key={question.id}
                type="radio"
                name={`answer-${question.id}`}
                label={question.question}
                options="GENERAL_YES_NO"
                getValue={(index, elem) => elem[`answer-${question.id}`] ?? (elem.answers?.[question.id] ?? "")}
                isRequired
            />)}
        </InputField>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubscriptionEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    courseID : PropTypes.number,
    elemID   : PropTypes.number,
};

export default SubscriptionEdit;
