import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";

// Components
import CalendarHeader       from "./CalendarHeader";
import CalendarContent      from "./CalendarContent";
import ReserveView          from "../Reserve/ReserveView";
import ReserveEdit          from "../Reserve/ReserveEdit";
import CourtBlockEdit       from "../CourtBlock/CourtBlockEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";



/**
 * The Calendar Page
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CalendarPage(props) {
    const { type } = props;

    const navigate = Navigate.useGoto();
    const { reserveTypeID } = Navigate.useParams();

    const { canEdit, reserveType } = Store.useState("reserveCalendar");
    const { fetchList } = Store.useAction("reserveCalendar");


    // The References
    const timerRef = React.useRef(0);

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();

    const [ currentDate, setCurrentDate ] = React.useState(DateTime.create().toDayStart());
    const [ courtID,     setCourtID     ] = React.useState(0);
    const [ fromHour,    setFromHour    ] = React.useState("");
    const [ toHour,      setToHour      ] = React.useState("");
    const [ update,      setUpdate      ] = React.useState(0);


    // Load the initial data
    React.useEffect(() => {
        fetch(update);
    }, [ update, reserveTypeID ]);

    // Start and clear the Auto-update
    React.useEffect(() => {
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, []);

    // Loads the data and sets an auto-update
    const fetch = (update, date = currentDate, withLoader = true) => {
        fetchList(type, reserveTypeID, date.time, withLoader && !update);

        Utils.clearTimeout(timerRef);
        Utils.setUpdateTimeout(timerRef, setUpdate, update);
    };


    // Handles the new Reserve Type
    const handleReserveType = (newReserveTypeID) => {
        if (isKiosk) {
            navigate("RESERVE", newReserveTypeID);
        }
    };

    // Handles the new Date
    const handleDate = React.useCallback((newDate) => {
        setCurrentDate(newDate);
        fetch(update, newDate, false);
    }, [ update ]);

    // Handles the Click
    const handleClick = (action, elemID) => {
        if (canEdit) {
            startAction(action, elemID);
        }
    };

    // Handles the new Reserve
    const handleAction = (action, courtID, fromHour, toHour) => {
        if (canEdit) {
            setCourtID(courtID);
            setFromHour(fromHour);
            setToHour(toHour);
            startAction(action);
        }
    };

    // Handles the Edit Submit
    const handleEdit = React.useCallback(() => {
        endAction();
        fetch(update);
    }, [ update ]);

    // Handles the Close
    const handleClose = React.useCallback((fetchData) => {
        setCourtID(0);
        setFromHour("");
        setToHour("");
        endAction();
        if (fetchData) {
            fetch(update);
        }
    }, [ update ]);


    // Do the Render
    const isKiosk = type === "API";

    return <Main>
        <CalendarHeader
            startAction={startAction}
            currentDate={currentDate}
            onReserveType={handleReserveType}
            onCurrentDate={handleDate}
            isKiosk={isKiosk}
        />
        <CalendarContent
            onClick={handleClick}
            onAction={handleAction}
        />

        <ReserveView
            open={action.isReserve && !!elemID}
            elemID={elemID}
            reserveTypeID={reserveTypeID}
            onClose={handleClose}
        />
        <ReserveEdit
            open={action.isReserve && !elemID}
            elemID={elemID}
            reserveTypeID={reserveTypeID}
            date={currentDate.toString("dashesReverse")}
            hour={fromHour}
            courtID={courtID}
            onSubmit={handleEdit}
            onClose={handleClose}
        />
        <CourtBlockEdit
            open={action.isBlock}
            elemID={elemID}
            activityID={reserveType.activityID}
            date={currentDate.toString("dashesReverse")}
            fromHour={fromHour}
            toHour={toHour}
            courtID={courtID}
            onSubmit={handleEdit}
            onClose={handleClose}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CalendarPage.propTypes = {
    type : PropTypes.string.isRequired,
};

export default CalendarPage;
