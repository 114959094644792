import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Person Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PersonEdit(props) {
    const { open, elemIDs, courseID, onSubmit, onClose } = props;

    const { statuses, groups } = Store.useState("coursePerson");
    const { editElem } = Store.useAction("coursePerson");


    // The Current State
    const initialData = {
        courseGroupID : 0,
        status        : "Pending",
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await editElem({
            ...data,
            courseID,
            coursePersonIDs : elemIDs,
        });
    };

    // The Form State
    const {
        data, errors, handleChange, handleSubmit,
    } = useForm("coursePerson", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("coursePerson", open, null, { courseID });



    // Do the Render
    return <EditDialog
        open={open}
        icon="edit"
        title="COURSES_PERSONS_EDIT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <DialogMessage
            message={NLS.pluralize("COURSES_PERSONS_EDIT", elemIDs.length)}
        />

        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
        <InputField
            isHidden={data.status !== "Approved" || !groups.length}
            type="select"
            name="courseGroupID"
            label="COURSES_GROUP"
            options={groups}
            value={data.courseGroupID}
            error={errors.courseGroupID}
            onChange={handleChange}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PersonEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onSubmit : PropTypes.func.isRequired,
    onClose  : PropTypes.func.isRequired,
    elemIDs  : PropTypes.arrayOf(PropTypes.number).isRequired,
    courseID : PropTypes.number.isRequired,
};

export default PersonEdit;
