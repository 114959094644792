import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";
import MenuLine             from "Dashboard/Components/Menu/MenuLine";
import PromptDialog         from "Dashboard/Components/Dialogs/PromptDialog";
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Title = Styled.h3`
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 16px 0 0;
    color: var(--primary-color);
    font-family: var(--title-font);
    font-weight: normal;
    font-size: 20px;
`;

const Day = Styled.div`
    min-width: 300px;
    text-align: center;
`;



/**
 * The Calendar Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CalendarHeader(props) {
    const { startAction, currentDate, onReserveType, onCurrentDate, isKiosk } = props;

    const { canEdit, reserveType, reserveTypes } = Store.useState("reserveCalendar");


    // The Current State
    const [ showDate, setShowDate ] = React.useState(false);


    // Handles the Prev day
    const handlePrev = () => {
        const newDate = currentDate.moveDay(-1);
        onCurrentDate(newDate);
    };

    // Handles the Next day
    const handleNext = () => {
        const newDate = currentDate.moveDay(1);
        onCurrentDate(newDate);
    };

    // Handles the Today
    const handleToday = () => {
        const newDate = DateTime.create().toDayStart();
        onCurrentDate(newDate);
    };

    // Handles the Date
    const handleDate = (date) => {
        const newDate = DateTime.fromString(date);
        onCurrentDate(newDate);
        setShowDate(false);
    };


    // Generates the Title
    const title = React.useMemo(() => {
        if (isKiosk && reserveType.name) {
            return reserveType.name;
        }
        return "RESERVE_CALENDAR_NAME";
    }, [ isKiosk, reserveType.name ]);


    // Do the Render
    return <>
        <Header message={title} icon="calendar">
            <Title>
                <IconLink variant="light" icon="prev" onClick={handlePrev} />
                <Day>{currentDate.toString("completeMonth")}</Day>
                <IconLink variant="light" icon="next" onClick={handleNext} />
            </Title>

            <ActionList onAction={startAction}>
                <ActionItem
                    isHidden={!canEdit}
                    icon="more"
                    message="GENERAL_ACTIONS"
                >
                    <ActionOption
                        icon="today"
                        message="RESERVE_CALENDAR_GOTO_TODAY"
                        onClick={handleToday}
                    />
                    <ActionOption
                        icon="date"
                        message="RESERVE_CALENDAR_GOTO_DATE"
                        onClick={() => setShowDate(true)}
                    />

                    <MenuLine />
                    <ActionOption
                        icon="reserve"
                        message="RESERVES_CREATE_TITLE"
                        action="RESERVE"
                    />
                    <ActionOption
                        icon="court-block"
                        message="COURT_BLOCKS_CREATE_TITLE"
                        action="BLOCK"
                    />
                </ActionItem>

                <ActionItem icon="expand">
                    {reserveTypes.map(({ key, value }) => <ActionOption
                        key={key}
                        icon={key === reserveType.id ? "checkedbox" : "checkbox"}
                        message={value}
                        onClick={() => onReserveType(key)}
                    />)}
                </ActionItem>
            </ActionList>
        </Header>

        <PromptDialog
            open={showDate}
            icon="date"
            title="RESERVE_CALENDAR_GOTO_DATE"
            inputType="date"
            inputLabel="GENERAL_DATE"
            initialValue={currentDate.toString("dashesReverse")}
            onSubmit={handleDate}
            onClose={() => setShowDate(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CalendarHeader.propTypes = {
    startAction   : PropTypes.func.isRequired,
    currentDate   : PropTypes.object.isRequired,
    onReserveType : PropTypes.func.isRequired,
    onCurrentDate : PropTypes.func.isRequired,
    isKiosk       : PropTypes.bool.isRequired,
};

export default CalendarHeader;
