import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Button }           from "Utils/API";



// The initial State
const initialState = {
    loading     : true,
    error       : false,
    edition     : 0,
    canCreate   : false,
    canEdit     : false,
    canFilter   : false,
    canImport   : false,
    canExport   : false,
    list        : [],
    total       : 0,
    elem        : {},
    buttonTypes : [],
    pages       : [],
    statuses    : [],
    sort        : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "BUTTON_LOADING" });
    },

    /**
     * Fetches the Button List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "ACTIVITY") {
            params.activityID = elemID;
        }

        const data = await Button.getAll({ ...params });
        data.sort  = params;
        dispatch({ type : "BUTTON_LIST", data });
    },

    /**
     * Fetches a single Button
     * @param {Function} dispatch
     * @param {Number}   buttonID
     * @returns {Promise}
     */
    async fetchElem(dispatch, buttonID) {
        const data = await Button.getOne({ buttonID });
        dispatch({ type : "BUTTON_ELEM", data });
        return data;
    },

    /**
     * Fetches the Button Edit data
     * @param {Function} dispatch
     * @param {Number}   activityID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, activityID) {
        const data = await Button.getEditData({ activityID });
        dispatch({ type : "BUTTON_EDIT", data });
    },

    /**
     * Edits/Creates a Button
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return Button.edit(data);
    },

    /**
     * Deletes the Button
     * @param {Function} dispatch
     * @param {Number}   buttonID
     * @returns {Promise}
     */
    deleteElem(dispatch, buttonID) {
        return Button.delete({ buttonID });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "BUTTON_LIST", "BUTTON_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "BUTTON_LOADING":
        return {
            ...state,
            loading     : true,
        };

    case "BUTTON_LIST":
        return {
            ...state,
            loading     : false,
            error       : false,
            canCreate   : action.data.canCreate,
            canEdit     : action.data.canEdit,
            canFilter   : action.data.canFilter,
            canImport   : action.data.canImport,
            canExport   : action.data.canExport,
            list        : action.data.list,
            total       : action.data.total,
            sort        : action.data.sort,
        };

    case "BUTTON_ELEM":
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            elem        : action.data.elem,
            buttonTypes : action.data.buttonTypes,
            pages       : action.data.pages,
            statuses    : action.data.statuses,
        };

    case "BUTTON_EDIT":
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            buttonTypes : action.data.buttonTypes,
            pages       : action.data.pages,
            statuses    : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
