import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import GeneralAdminRouter   from "Components/Core/GeneralAdmin/GeneralAdminRouter";
import GeneralAdminMenu     from "Components/Core/GeneralAdmin/GeneralAdminMenu";
import SuperAdminRouter     from "Components/Core/SuperAdmin/SuperAdminRouter";
import SuperAdminMenu       from "Components/Core/SuperAdmin/SuperAdminMenu";
import APIMenu              from "Components/Core/API/APIMenu";
import APIRouter            from "Components/Core/API/APIRouter";

// Dashboard
import Container            from "Dashboard/Components/Core/Container";
import TopBar               from "Dashboard/Components/Core/TopBar";
import SideBar              from "Dashboard/Components/Core/SideBar";
import Inside               from "Dashboard/Components/Core/Inside";
import MenuItem             from "Dashboard/Components/Menu/MenuItem";

// Images
import Title                from "Styles/Images/Title.png";



/**
 * The App
 * @returns {React.ReactElement}
 */
function App() {
    const navigate = Navigate.useGoto();

    const { credential, edition } = Store.useState("auth");
    const { logout } = Store.useAction("auth");
    const { hideTooltip } = Store.useAction("core");


    // Handles the User Logout
    const handleLogout = async () => {
        hideTooltip();
        const result = await logout();
        if (result.isUser) {
            navigate("SUBSIDIARIES", "USERS");
        } else if (result.isAdmin) {
            navigate("SETTINGS", "ADMIN");
        }
    };


    // Do the Render
    return <Container withTopBar>
        <TopBar
            variant="light"
            logo={Title}
            avatarUrl="PROFILE"
            avatarEmail={credential.email}
            avatarAvatar={credential.avatar}
            avatarEdition={edition}
            menuItems={[
                <MenuItem
                    key="user"
                    icon="user"
                    message="ACCOUNT_MINE"
                    url="PROFILE"
                />,
                <MenuItem
                    key="logout"
                    icon="logout"
                    message="GENERAL_LOGOUT"
                    onClick={handleLogout}
                />,
            ]}
        />

        <SideBar expandMobile>
            <GeneralAdminMenu />
            <SuperAdminMenu />
            <APIMenu />
        </SideBar>

        <Inside>
            <GeneralAdminRouter />
            <SuperAdminRouter />
            <APIRouter />
        </Inside>
    </Container>;
}

export default App;
