import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { MemberRelation }   from "Utils/API";



// The initial State
const initialState = {
    loading    : false,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    canFilter  : false,
    canImport  : false,
    canExport  : false,
    list       : [],
    total      : 0,
    elem       : {},
    states     : [],
    categories : [],
    sort       : {
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "MEMBER_RELATION_LOADING" });
    },

    /**
     * Fetches the Member Relation List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await MemberRelation.getAll(params);
        data.sort  = params;
        dispatch({ type : "MEMBER_RELATION_LIST", data });
    },

    /**
     * Fetches a single Member Relation
     * @param {Function} dispatch
     * @param {Number}   relationCode
     * @returns {Promise}
     */
    async fetchElem(dispatch, relationCode) {
        const data = await MemberRelation.getOne({ relationCode });
        dispatch({ type : "MEMBER_RELATION_ELEM", data });
    },

    /**
     * Edits/Creates a Member Relation
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return MemberRelation.edit(data);
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.stateCountText    = !elem.stateCount    ? NLS.get("GENERAL_ALL")     : NLS.pluralize("MEMBERS_STATES_COUNT", elem.stateCount);
    elem.categoryCountText = !elem.categoryCount ? NLS.get("GENERAL_ALL_FEM") : NLS.pluralize("CATEGORIES_COUNT",     elem.categoryCount);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "MEMBER_RELATION_LIST", "MEMBER_RELATION_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "MEMBER_RELATION_LOADING":
        return {
            ...state,
            loading    : true,
        };

    case "MEMBER_RELATION_LIST":
        return {
            ...state,
            loading    : false,
            error      : false,
            canCreate  : action.data.canCreate,
            canEdit    : action.data.canEdit,
            canFilter  : action.data.canFilter,
            canImport  : action.data.canImport,
            canExport  : action.data.canExport,
            list       : Utils.parseList(action.data.list, parseElem),
            total      : action.data.total,
            sort       : action.data.sort,
        };

    case "MEMBER_RELATION_ELEM":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            elem       : parseElem(action.data.elem),
            states     : action.data.states,
            categories : action.data.categories,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
