import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import FeeEdit              from "./FeeEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Fee List
 * @returns {React.ReactElement}
 */
function FeeList() {
    const { fetch, load } = useList("fee");

    const data = Store.useState("fee");
    const { loading, canEdit, list, total, sort } = data;

    const { deleteElem } = Store.useAction("fee");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isFile) {
            const elemUrl = Utils.getValue(list, "feeID", elemID, "fileUrl");
            Navigate.gotoUrl(elemUrl, true);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "feeID", elemID, "name");

    return <Main>
        <Header message="FEES_NAME" icon="fee">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="FEES_NONE_AVAILABLE"
                isLoading={loading}
                hasTabs
            >
                <TableHead>
                    <TableHeader field="name"     message="GENERAL_NAME"     isTitle />
                    <TableHeader field="status"   message="GENERAL_STATUS"   align="center" maxWidth="100" />
                    <TableHeader field="position" message="GENERAL_POSITION" maxWidth="80" align="center" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.feeID} elemID={elem.feeID}>
                        <TableCell message={elem.name}       />
                        <TableCell message={elem.statusName} textColor={elem.statusColor} />
                        <TableCell message={elem.position}   />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="FEES_EDIT_TITLE"   />
                    <TableAction action="FILE"   message="FEES_VIEW_TITLE"   />
                    <TableAction action="DELETE" message="FEES_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <FeeEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="FEES_DELETE_TITLE"
            message="FEES_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default FeeList;
