import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Court Block Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CourtBlockEdit(props) {
    const {
        open, elemID, activityID, date, fromHour, toHour, courtID,
        onClose, onSubmit,
    } = props;

    const { activities, blockTypes, courts, statuses } = Store.useState("courtBlock");
    const { editElem, deleteElem } = Store.useAction("courtBlock");


    // The Current State
    const [ showDelete, setShowDelete ] = React.useState(false);

    // The Initial Data
    const initialData = {
        courtBlockID     : 0,
        courtBlockTypeID : 0,
        activityID       : 0,
        courtIDs         : [],
        name             : "",
        repeatsWeekly    : 0,
        repeatsDay       : 0,
        fromDate         : "",
        fromHour         : "",
        toDate           : "",
        toHour           : "",
        status           : "Active",
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({
            activityID,
            fromDate : date,
            fromHour : fromHour,
            toDate   : date,
            toHour   : toHour,
            courtIDs : courtID ? [ courtID ] : [],
            ...elem,
        });
    };

    // Handles the Delete
    const handleDelete = async () => {
        const response = await deleteElem(elemID);
        if (response.success) {
            setShowDelete(false);
            onClose(true);
        }
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("courtBlock", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("courtBlock", open, elemID, null, handleSet);



    // Do the Render
    return <>
        <EditDialog
            open={open}
            icon="court-block"
            title={elemID ? "COURT_BLOCKS_EDIT_TITLE" : "COURT_BLOCKS_CREATE_TITLE"}
            secondary={elemID ? "GENERAL_DELETE" : ""}
            onSecondary={() => setShowDelete(true)}
            error={errors.form}
            onSubmit={handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                name="name"
                label="GENERAL_NAME"
                value={data.name}
                error={errors.name}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="courtBlockTypeID"
                label="COURT_BLOCK_TYPES_SINGULAR"
                options={blockTypes}
                value={data.courtBlockTypeID}
                error={errors.courtBlockTypeID}
                onChange={handleChange}
                isRequired
            />
            <InputField
                isHidden={!!activityID}
                type="select"
                name="activityID"
                label="ACTIVITIES_SINGULAR"
                options={activities}
                value={data.activityID}
                error={errors.activityID}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="chooser"
                name="courtIDs"
                label="COURTS_NAME"
                options={courts[data.activityID] || []}
                value={data.courtIDs}
                error={errors.courtIDs}
                onChange={handleChange}
                isRequired
            />

            <InputField
                type="toggle"
                name="repeatsWeekly"
                label="COURT_BLOCKS_REPEATS_WEEKLY"
                value={data.repeatsWeekly}
                error={errors.repeatsWeekly}
                onChange={handleChange}
                withBorder
            />

            <Columns isHidden={!data.repeatsWeekly}>
                <InputField
                    type="date"
                    name="fromDate"
                    label="GENERAL_FROM_DATE"
                    value={data.fromDate}
                    error={errors.fromDate}
                    onChange={handleChange}
                    isRequired
                    hasClear
                />
                <InputField
                    type="date"
                    name="toDate"
                    label="GENERAL_TO_DATE"
                    value={data.toDate}
                    error={errors.toDate}
                    onChange={handleChange}
                    hasClear
                />
            </Columns>
            <Columns isHidden={!data.repeatsWeekly} amount="3">
                <InputField
                    type="select"
                    name="repeatsDay"
                    label="GENERAL_DAY"
                    options="DATE_DAY_NAMES_MONDAY"
                    value={data.repeatsDay}
                    error={errors.repeatsDay}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="time"
                    name="fromHour"
                    label="GENERAL_FROM_HOUR"
                    value={data.fromHour}
                    error={errors.fromHour}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="time"
                    name="toHour"
                    label="GENERAL_TO_HOUR"
                    value={data.toHour}
                    error={errors.toHour}
                    onChange={handleChange}
                    isRequired
                />
            </Columns>

            <Columns isHidden={!!data.repeatsWeekly}>
                <InputField
                    type="date"
                    name="fromDate"
                    label="GENERAL_FROM_DATE"
                    value={data.fromDate}
                    error={errors.fromDate}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="time"
                    name="fromHour"
                    label="GENERAL_FROM_HOUR"
                    value={data.fromHour}
                    error={errors.fromHour}
                    onChange={handleChange}
                    isRequired
                />

                <InputField
                    type="date"
                    name="toDate"
                    label="GENERAL_TO_DATE"
                    value={data.toDate}
                    error={errors.toDate}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="time"
                    name="toHour"
                    label="GENERAL_TO_HOUR"
                    value={data.toHour}
                    error={errors.toHour}
                    onChange={handleChange}
                    isRequired
                />
            </Columns>

            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
                value={data.status}
                error={errors.status}
                onChange={handleChange}
                hideClear
                isRequired
            />
        </EditDialog>

        <DeleteDialog
            open={showDelete}
            title="COURT_BLOCKS_DELETE_TITLE"
            message="COURT_BLOCKS_DELETE_TEXT"
            content={data.name}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CourtBlockEdit.propTypes = {
    open       : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    elemID     : PropTypes.number,
    activityID : PropTypes.number,
    date       : PropTypes.string,
    fromHour   : PropTypes.string,
    toHour     : PropTypes.string,
    courtID    : PropTypes.number,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
CourtBlockEdit.defaultProps = {
    activityID : 0,
    date       : "",
    fromHour   : "",
    toHour     : "",
    courtID    : 0,
};

export default CourtBlockEdit;
