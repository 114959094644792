import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import PersonView           from "./PersonView";
import PersonEdit           from "./PersonEdit";

// Dashboard
import Filter               from "Dashboard/Components/Filter/Filter";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import Html                 from "Dashboard/Components/Common/Html";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const EditButton = Styled(Button)`
    position: absolute;
    top: 0;
    right: var(--main-padding);
`;



/**
 * The Course Person Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PersonContent(props) {
    const { updates } = props;

    const { fetch, load, loadFilter } = useList("coursePerson", "COURSE");

    const { courseID } = Navigate.useParams();

    const data = Store.useState("coursePerson");
    const { loading, canEdit, canMulti, list, total, statuses, filters, sort } = data;

    const { nullifyElem } = Store.useAction("coursePerson");

    // The Current State
    const [ checked, setChecked ] = React.useState([]);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Fetch new data on update
    React.useEffect(() => {
        fetch();
    }, [ updates ]);

    // Handles the Edit Submit
    const handleEdit = () => {
        handleClose(true);
    };

    // Handles the Nullify Submit
    const handleNullify = async () => {
        endAction();
        const result = await nullifyElem(elemID);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Close
    const handleClose = (update) => {
        endAction();
        if (update) {
            setChecked([]);
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "coursePersonID", elemID, "name");

    return <>
        <EditButton
            isHidden={!canMulti || !checked.length}
            variant="outlined"
            icon="edit"
            message="COURSES_PERSONS_EDIT_TITLE"
            onClick={() => startAction("EDIT")}
        />
        <Filter
            onFilter={loadFilter}
            values={filters}
            hasSearch
        >
            <FilterItem
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
            />
        </Filter>

        <Table
            fetch={load}
            sort={sort}
            none="COURSES_PERSONS_NONE_AVAILABLE"
            isLoading={loading}
            checked={checked}
            setChecked={canMulti ? setChecked : null}
            hasCheckAll
            hasTabs
            hasFilter
        >
            <TableHead>
                <TableHeader field="createdTime"      message="GENERAL_CREATED_AT_FEM"     minWidth="170" maxWidth="170" isTitle />
                <TableHeader field="number"           message="GENERAL_NUMBER"             maxWidth="80" />
                <TableHeader field="parentMemberName" message="GENERAL_MEMBER_RESPONSIBLE" />
                <TableHeader field="name"             message="COURSES_PERSONS_SINGULAR"   isTitle />
                <TableHeader field="personType"       message="GENERAL_TYPE"               maxWidth="120" />
                <TableHeader field="document"         message="GENERAL_DOCUMENT_VALUE"     maxWidth="120" />
                <TableHeader field="birthDate"        message="GENERAL_BIRTH"              maxWidth="100" />
                <TableHeader field="age"              message="GENERAL_AGE"                maxWidth="40" align="center" noSorting />
                <TableHeader field="status"           message="GENERAL_STATUS"             maxWidth="100" />
                <TableHeader field="courseGroupName"  message="COURSES_GROUP"              />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow key={elem.coursePersonID} elemID={elem.coursePersonID}>
                    <TableCell message={elem.createdTimeText} />
                    <TableCell message={elem.numberText}      />
                    <TableCell message={elem.parentName}      />
                    <TableCell message={elem.name}            />
                    <TableCell><Html>{elem.personTypeName}</Html></TableCell>
                    <TableCell message={elem.document}        />
                    <TableCell message={elem.birthDate}       />
                    <TableCell message={elem.age}             />
                    <TableCell message={elem.statusName}      textColor={elem.statusColor} />
                    <TableCell message={elem.courseGroupName} />
                </TableRow>)}
            </TableBody>
            <TablePaging total={total} />
            <TableActionList onAction={startAction} canEdit={canEdit}>
                <TableAction action="VIEW" message="COURSES_PERSONS_VIEW_TITLE" />
            </TableActionList>
        </Table>

        <PersonView
            open={action.isView}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={handleClose}
        />
        <PersonEdit
            open={action.isEdit}
            elemIDs={checked}
            courseID={courseID}
            onSubmit={handleEdit}
            onClose={handleClose}
        />
        <ConfirmDialog
            open={action.isNullify}
            icon="nullify"
            title="SUBSCRIPTIONS_NULLIFY_TITLE"
            message="SUBSCRIPTIONS_NULLIFY_TEXT"
            primary="GENERAL_NULLIFY"
            content={elemName}
            onSubmit={handleNullify}
            onClose={endAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PersonContent.propTypes = {
    updates : PropTypes.number.isRequired,
};

export default PersonContent;
