import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import useMedia             from "Dashboard/Hooks/Media";

// Components
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import EditorField          from "Dashboard/Components/Form/EditorField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Billboard Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function BillboardEdit(props) {
    const { open, isCopy, elemID, onClose, onSubmit } = props;

    const { statuses } = Store.useState("billboard");
    const { editElem } = Store.useAction("billboard");


    // The Initial Data
    const initialData = {
        billboardID : 0,
        publishDate : "",
        title       : "",
        summary     : "",
        content     : "",
        showBefore  : 1,
        status      : "Active",
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await editElem({
            ...data,
            billboardID : isCopy ? 0 : (elemID || 0),
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("billboard", initialData, handleEdit, onSubmit);

    // The Media State
    const {
        showMedia, mediaType, handleMediaOpen, handleMediaSubmit, handleMediaClose,
    } = useMedia();

    // Load the Data
    const { loading } = useDialog("billboard", open, elemID, {}, setElem);


    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "BILLBOARDS_COPY_TITLE";
        }
        if (elemID) {
            return "BILLBOARDS_EDIT_TITLE";
        }
        return "BILLBOARDS_CREATE_TITLE";
    }, [ isCopy, elemID ]);


    // Do the Render
    return <>
        <EditDialog
            open={open}
            icon="billboard"
            title={title}
            error={errors.form}
            onSubmit={handleSubmit}
            onClose={onClose}
            isLoading={loading}
            isWide
        >
            <InputField
                type="date"
                name="publishDate"
                label="ENTRIES_PUBLISH_DATE"
                value={data.publishDate}
                error={errors.publishDate}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="title"
                label="GENERAL_TITLE"
                value={data.title}
                error={errors.title}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="textarea"
                name="summary"
                label="ENTRIES_SUMMARY"
                value={data.summary}
                error={errors.summary}
                onChange={handleChange}
                isRequired
            />
            <EditorField
                name="content"
                value={data.content}
                error={errors.content}
                onChange={handleChange}
                onMedia={handleMediaOpen}
            />
            <Columns>
                <InputField
                    type="toggle"
                    name="showBefore"
                    label="ENTRIES_SHOW_BEFORE_PUBLISH"
                    value={data.showBefore}
                    onChange={handleChange}
                    withBorder
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                    value={data.status}
                    error={errors.status}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
            </Columns>
        </EditDialog>

        <MediaDialog
            open={showMedia}
            mediaType={mediaType}
            onSubmit={handleMediaSubmit}
            onClose={handleMediaClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
BillboardEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    isCopy   : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default BillboardEdit;
