import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { CoursePerson }     from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canFilter : false,
    canImport : false,
    canExport : false,
    canMulti  : false,
    list      : [],
    total     : 0,
    course    : {},
    elem      : {},
    questions : [],
    groups    : [],
    statuses  : [],
    filters   : {
        search   : "",
        status   : "",
        fromDate : "",
        toDate   : "",
    },
    sort      : {
        orderBy  : "numberPosition",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "COURSE_PERSON_LOADING" });
    },

    /**
     * Fetches the Course Person List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "COURSE") {
            params.courseID = elemID;
        }

        const data   = await CoursePerson.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "COURSE_PERSON_LIST", data });
    },

    /**
     * Fetches a single Course Person
     * @param {Function} dispatch
     * @param {Number}   coursePersonID
     * @returns {Promise}
     */
    async fetchElem(dispatch, coursePersonID) {
        const data = await CoursePerson.getOne({ coursePersonID });
        dispatch({ type : "COURSE_PERSON_ELEM", data });
    },

    /**
     * Fetches the Course Person Edit data
     * @param {Function} dispatch
     * @param {Number}   courseID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, courseID) {
        const data = await CoursePerson.getEditData({ courseID });
        dispatch({ type : "COURSE_PERSON_EDIT", data });
    },

    /**
     * Edits a Course Person
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return CoursePerson.edit(data);
    },
};



/**
 * Parses a single Element
 * @param {Object}         elem
 * @param {Array.<Object>} questions
 * @returns {Object}
 */
function parseElem(elem, questions) {
    elem.createdTimeText = DateTime.formatString(elem.createdTime, false);
    elem.parentName      = `${elem.parentMemberNumber} - ${elem.parentMemberName}`;
    elem.numberText      = `#${elem.number} (${elem.position})`;
    elem.age             = DateTime.getAge(elem.birthDate);

    elem.personTypeName = NLS.get("SELECT_PERSON_TYPES", elem.personType);
    if (elem.memberNumber) {
        elem.personTypeName = `<b>${elem.personTypeName}</b>: ${elem.memberNumber}`;
    }

    if (elem.memberDni) {
        elem.document = `${NLS.get("SELECT_DOCUMENT_TYPES", 1)}: ${elem.memberDni}`;
    } else {
        elem.document = `${NLS.get("SELECT_DOCUMENT_TYPES", elem.documentType)}: ${elem.documentValue}`;
    }

    const answers    = [];
    elem.answersText = "";
    if (questions && questions.length && elem.answers) {
        for (const question of questions) {
            const answer = elem.answers[question.id] ?? "";
            answers.push(`<i>${question.question}</i> &nbsp; <b>${Utils.toYesNo(answer)}</b>`);
        }
        elem.answersText = answers.join("<br>");
    }

    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "COURSE_PERSON_LIST", "COURSE_PERSON_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "COURSE_PERSON_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "COURSE_PERSON_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canFilter : action.data.canFilter,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            canMulti  : action.data.canMulti,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            course    : action.data.course,
            statuses  : action.data.statuses,
            filters   : action.data.filters,
            sort      : action.data.sort,
        };

    case "COURSE_PERSON_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem, action.data.questions),
            questions : action.data.questions,
            groups    : action.data.groups,
            statuses  : action.data.statuses,
        };

    case "COURSE_PERSON_EDIT":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            groups    : action.data.groups,
            statuses  : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
