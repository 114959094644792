import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { TicketDepartment } from "Utils/API";



// The initial State
const initialState = {
    loading     : true,
    error       : false,
    edition     : 0,
    canCreate   : false,
    canEdit     : false,
    canFilter   : false,
    canImport   : false,
    canExport   : false,
    list        : [],
    total       : 0,
    elem        : {},
    areas       : [],
    activities  : [],
    credentials : [],
    statuses    : [],
    sort        : {
        orderBy  : "mainPosition",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "TICKET_DEPARTMENT_LOADING" });
    },

    /**
     * Fetches the Ticket Department List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await TicketDepartment.getAll(params);
        data.sort = params;
        dispatch({ type : "TICKET_DEPARTMENT_LIST", data });
    },

    /**
     * Fetches a single Ticket Department
     * @param {Function} dispatch
     * @param {Number}   ticketDepartmentID
     * @returns {Promise}
     */
    async fetchElem(dispatch, ticketDepartmentID) {
        const data = await TicketDepartment.getOne({ ticketDepartmentID });
        dispatch({ type : "TICKET_DEPARTMENT_ELEM", data });
    },

    /**
     * Fetches the Ticket Department Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await TicketDepartment.getEditData();
        dispatch({ type : "TICKET_DEPARTMENT_EDIT", data });
    },

    /**
     * Edits a Ticket Department
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return TicketDepartment.edit(data);
    },

    /**
     * Deletes a Ticket Department
     * @param {Function} dispatch
     * @param {Number}   ticketDepartmentID
     * @returns {Promise}
     */
    deleteElem(dispatch, ticketDepartmentID) {
        return TicketDepartment.delete({ ticketDepartmentID });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "TICKET_DEPARTMENT_LIST", "TICKET_DEPARTMENT_ELEM", "TICKET_DEPARTMENT_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "TICKET_DEPARTMENT_LOADING":
        return {
            ...state,
            loading     : true,
        };

    case "TICKET_DEPARTMENT_LIST":
        return {
            ...state,
            loading     : false,
            error       : false,
            canCreate   : action.data.canCreate,
            canEdit     : action.data.canEdit,
            canFilter   : action.data.canFilter,
            canImport   : action.data.canImport,
            canExport   : action.data.canExport,
            list        : action.data.list,
            total       : action.data.total,
            sort        : action.data.sort,
        };

    case "TICKET_DEPARTMENT_ELEM":
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            elem        : action.data.elem,
            areas       : action.data.areas,
            activities  : action.data.activities,
            credentials : action.data.credentials,
            statuses    : action.data.statuses,
        };

    case "TICKET_DEPARTMENT_EDIT":
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            areas       : action.data.areas,
            activities  : action.data.activities,
            credentials : action.data.credentials,
            statuses    : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
