import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import MediaType            from "Dashboard/Core/MediaType";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Activity Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ActivityEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("activity");


    // The Initial Data
    const initialData = {
        activityID : 0,
        name       : "",
        image      : "",
        icon       : "",
        showInApp  : 1,
        position   : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("activity", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("activity", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="activity"
        title={elemID ? "ACTIVITIES_EDIT_TITLE" : "ACTIVITIES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <MediaField
            name="image"
            label="GENERAL_IMAGE"
            value={data.image}
            error={errors.image}
            onChange={handleChange}
            mediaType={MediaType.IMAGE}
            onlyImages
            isRequired
        />
        <InputField
            name="icon"
            label="GENERAL_ICON"
            value={data.icon}
            error={errors.icon}
            onChange={handleChange}
        />
        <InputField
            type="toggle"
            name="showInApp"
            label="GENERAL_SHOW_IN_APP"
            value={data.showInApp}
            onChange={handleChange}
            withBorder
        />
        <InputField
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ActivityEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default ActivityEdit;
