import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import TicketFilter         from "./TicketFilter";
import TicketView           from "./TicketView";
import TicketEdit           from "./TicketEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import PromptDialog         from "Dashboard/Components/Dialogs/PromptDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Ticket List
 * @returns {React.ReactElement}
 */
function TicketList() {
    const { fetch, load, loadTab, loadFilter } = useList("ticket");

    const path         = Navigate.usePath();
    const isCreate     = path.endsWith(NLS.baseUrl("CREATE"));
    const { ticketID } = Navigate.useParams();

    const data = Store.useState("ticket");
    const { loading, canEdit, canDelete, list, waitlists, total, filters, sort } = data;

    const { deleteTicket, openTicket, waitTicket, closeTicket } = Store.useAction("ticket");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Show the Ticket
    React.useEffect(() => {
        if (isCreate) {
            startAction("CREATE");
        } else if (ticketID) {
            startAction("VIEW", ticketID);
        }
    }, [ isCreate, ticketID ]);


    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isTab) {
            loadTab(elemID);
        } else {
            startAction(action, elemID);
        }
    };


    // Completes an Action
    const completeAction = (update) => {
        endAction();
        if (update) {
            fetch();
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        completeAction(true);
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        const response = await deleteTicket(elemID);
        completeAction(response.success);
    };

    // Handles the Open Submit
    const handleOpen = async () => {
        const response = await openTicket(elemID);
        completeAction(response.success);
    };

    // Handles the Wait Submit
    const handleWait = async (ticketWaitListID) => {
        const response = await waitTicket(elemID, ticketWaitListID);
        completeAction(response.success);
    };

    // Handles the Close Submit
    const handleClose = async () => {
        const response = await closeTicket(elemID);
        completeAction(response.success);
    };

    // Returns true if the Edit is hidden
    const hideEdit = (elemID) => {
        return !Utils.getValue(list, "ticketID", elemID, "canEdit");
    };

    // Returns true if the Open is hidden
    const hideOpen = (elemID) => {
        return !Utils.getValue(list, "ticketID", elemID, "canOpen");
    };

    // Returns true if the Close is hidden
    const hideClose = (elemID) => {
        return !Utils.getValue(list, "ticketID", elemID, "canClose");
    };



    // Do the Render
    const hideWaitlist = sort.filter !== "Waiting";
    const elem         = Utils.getValue(list, "ticketID", elemID);

    return <Main>
        <Header message="TICKETS_NAME" icon="ticket">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content isLoading={loading}>
            <TabList selected={sort.filter} onAction={handleAction}>
                <TabItem message="TICKETS_OPEN_TAB"    value="Open"    />
                <TabItem message="TICKETS_WAITING_TAB" value="Waiting" />
                <TabItem message="TICKETS_CLOSE_TAB"   value="Closed"  />
            </TabList>
            <Table
                fetch={load}
                sort={sort}
                none="TICKETS_NONE_AVAILABLE"
            >
                <TableHead>
                    <TableHeader field="ticketID"             message="GENERAL_ID"                  maxWidth="50" />
                    <TableHeader field="priority"             message="GENERAL_PRIORITY"            maxWidth="80" />
                    <TableHeader field="ticketWaitlistName"   message="TICKET_WAITLISTS_SINGULAR"   isHidden={hideWaitlist} />
                    <TableHeader field="ticketAreaName"       message="TICKET_AREAS_SINGULAR"       />
                    <TableHeader field="ticketDepartmentName" message="TICKET_DEPARTMENTS_SINGULAR" />
                    <TableHeader field="ticketSubjectName"    message="TICKET_SUBJECTS_SINGULAR"    />
                    <TableHeader field="memberFirstName"      message="GENERAL_MEMBER"              />
                    <TableHeader field="credentialFirstName"  message="TICKETS_ASSIGNED"            />
                    <TableHeader field="lastReplyTime"        message="TICKETS_LAST_REPLY"          maxWidth="130" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.ticketID} elemID={elem.ticketID}>
                        <TableCell message={elem.ticketNumber}         />
                        <TableCell message={elem.priorityName}         textColor={elem.priorityColor} />
                        <TableCell message={elem.ticketWaitlistName}   />
                        <TableCell message={elem.ticketAreaName}       />
                        <TableCell message={elem.ticketDepartmentName} />
                        <TableCell message={elem.ticketSubjectName}    />
                        <TableCell message={elem.memberName}           />
                        <TableCell message={elem.credentialName}       />
                        <TableCell message={elem.lastReplyText}        />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"   message="TICKETS_VIEW_TITLE"    />
                    <TableAction action="EDIT"   message="TICKETS_EDIT_TITLE"    hide={hideEdit}       />
                    <TableAction action="OPEN"   message="TICKETS_OPEN_TITLE"    hide={hideOpen}       />
                    <TableAction action="WAIT"   message="TICKETS_WAITING_TITLE" hide={hideEdit}       />
                    <TableAction action="CLOSE"  message="TICKETS_CLOSE_TITLE"   hide={hideClose}      />
                    <TableAction action="DELETE" message="TICKETS_DELETE_TITLE"  isHidden={!canDelete} />
                </TableActionList>
            </Table>
        </Content>

        <TicketFilter
            open={action.isFilter}
            filters={filters}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <TicketView
            open={action.isView}
            elemID={elemID}
            onClose={completeAction}
        />
        <TicketEdit
            open={action.isCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />

        <ConfirmDialog
            open={action.isOpen}
            icon="status"
            title="TICKETS_OPEN_TITLE"
            message="TICKETS_OPEN_TEXT"
            content={elemID}
            onSubmit={handleOpen}
            onClose={endAction}
        />
        <PromptDialog
            open={action.isWait}
            icon="wait"
            title="TICKETS_WAITING_TITLE"
            message="TICKETS_WAITING_TEXT"
            content={elemID}
            inputType="select"
            inputLabel="TICKET_WAITLISTS_SINGULAR"
            initialValue={elem.ticketWaitListID}
            inputOptions={waitlists}
            onSubmit={handleWait}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isClose}
            icon="status"
            title="TICKETS_CLOSE_TITLE"
            message="TICKETS_CLOSE_TEXT"
            content={elemID}
            onSubmit={handleClose}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="TICKETS_DELETE_TITLE"
            message="TICKETS_DELETE_TEXT"
            content={elemID}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default TicketList;
