import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import EntryContainer       from "Components/App/Entry/EntryContainer";
import ActivityList         from "Components/App/Activity/Activity/ActivityList";
import ActivityContainer    from "Components/App/Activity/ActivityContainer";
import ReserveContainer     from "Components/App/Reserve/ReserveContainer";
import ReserveTypeContainer from "Components/App/Reserve/ReserveTypeContainer";
import ScheduleContainer    from "Components/App/Schedule/ScheduleContainer";
import AdministrationCont   from "Components/App/Administration/AdministrationContainer";
import ContactContainer     from "Components/App/Contact/ContactContainer";
import TicketContainer      from "Components/App/Ticket/TicketContainer";
import MemberContainer      from "Components/App/Member/MemberContainer";
import DocumentPage         from "Components/App/Document/DocumentPage";
import ProfileContainer     from "Components/App/Profile/ProfileContainer";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";



/**
 * The General Admin Router
 * @returns {React.ReactElement}
 */
function GeneralAdminRouter() {
    const { isGeneralAdmin } = Store.useState("auth");


    // Do the Render
    if (!isGeneralAdmin) {
        return <React.Fragment />;
    }
    return <Router type="ADMIN">
        <AdminRoute
            url="ENTRIES"
            component={EntryContainer}
        />

        <AdminRoute
            url="ACTIVITIES"
            component={ActivityList}
        />
        <AdminRoute
            url="ACTIVITY"
            component={ActivityContainer}
        />

        <AdminRoute
            url="RESERVE"
            component={ReserveContainer}
        />
        <AdminRoute
            url="RESERVE_TYPE"
            component={ReserveTypeContainer}
        />

        <AdminRoute
            url="SCHEDULE"
            component={ScheduleContainer}
        />
        <AdminRoute
            url="ADMINISTRATION"
            component={AdministrationCont}
        />
        <AdminRoute
            url="CONTACT"
            component={ContactContainer}
        />
        <AdminRoute
            url="TICKET"
            component={TicketContainer}
        />
        <AdminRoute
            url="MEMBER"
            component={MemberContainer}
        />

        <AdminRoute
            url="DOCUMENTS"
            component={DocumentPage}
        />
        <AdminRoute
            url="DOCUMENT"
            component={DocumentPage}
        />

        <AdminRoute
            url="PROFILE"
            component={ProfileContainer}
        />
    </Router>;
}

export default GeneralAdminRouter;
