import Store                from "Dashboard/Core/Store";
import CoreAuth             from "Dashboard/Core/Auth";
import CoreAjax             from "Dashboard/Core/Ajax";
import Utils                from "Dashboard/Utils/Utils";
import { Auth }             from "Utils/API";



// The initial State
const initialState = {
    isAuthenticated : true,
    isAPI           : false,
    isAnyAdmin      : false,
    isUser          : false,
    isSuperAdmin    : false,
    isGeneralAdmin  : false,
    loggedAsUser    : false,
    credential      : {},
    credentialID    : 0,
    email           : "",
    edition         : new Date().getTime(),
};



// The Actions
const actions = {
    /**
     * Sets the Current Credential
     * @param {Function} dispatch
     * @param {Object}   credential
     * @returns {Void}
     */
    setCurrentUser(dispatch, credential) {
        dispatch({ type : "AUTH_CURRENT_USER", credential });
    },

    /**
     * Logs In the User
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    async login(dispatch, data) {
        const result = await Auth.login(data);
        if (result && result.reqPassChange) {
            dispatch({ type : "AUTH_CHANGE_EMAIL", email : result.email });
        }
        return result;
    },

    /**
     * Logs In the User with a Token
     * @param {Function} dispatch
     * @param {String}   accessToken
     * @returns {Promise}
     */
    tokenLogin(dispatch, accessToken) {
        return Auth.tokenLogin({ accessToken });
    },

    /**
     * Logs In the User with a Refresh Token
     * @param {Function} dispatch
     * @returns {Promise}
     */
    refreshTokenLogin(dispatch) {
        return Auth.refreshTokenLogin();
    },

    /**
     * Requests a Password Reset
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    requestReset(dispatch, data) {
        return Auth.requestReset(data);
    },

    /**
     * Verifies a Password Reset
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    verifyReset(dispatch, data) {
        return Auth.verifyReset(data);
    },

    /**
     * Resets a Password
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    resetPass(dispatch, data) {
        return Auth.resetPass(data);
    },

    /**
     * Changes the User's Password
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    changePass(dispatch, data) {
        return Auth.changePass(data);
    },

    /**
     * Logouts the User
     * @param {Function} dispatch
     * @returns {Promise}
     */
    logout(dispatch) {
        const user = CoreAuth.getUser();
        CoreAjax.abort();
        if (user.loggedAsUser) {
            return Auth.logoutAs();
        }
        CoreAuth.unsetToken();
        return Auth.logout();
    },

    /**
     * Logins as an User
     * @param {Function} dispatch
     * @param {Number}   credentialID
     * @returns {Promise}
     */
    loginAs(dispatch, credentialID) {
        return Auth.loginAs({ credentialID });
    },

    /**
     * Deletes the Refresh Token
     * @param {Function} dispatch
     * @param {String}   accessToken
     * @returns {Promise}
     */
    deleteRefreshToken(dispatch, accessToken) {
        return Auth.deleteRefreshToken({ accessToken });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "AUTH_CURRENT_USER": {
        if (Utils.isEmpty(action.credential)) {
            return {
                ...initialState,
                isAuthenticated : false,
            };
        }

        const access = action.credential.accessName;
        return {
            ...initialState,
            isAuthenticated : true,
            credential      : action.credential,
            credentialID    : action.credential.credentialID,
            loggedAsUser    : action.credential.loggedAsUser,
            isAPI           : Boolean(action.credential.apiID),
            isAnyAdmin      : [ "Admin", "SuperAdmin" ].includes(access),
            isUser          : [ "Member" ].includes(access),
            isSuperAdmin    : access === "SuperAdmin",
            isGeneralAdmin  : access === "Admin",
        };
    }

    case "AUTH_CHANGE_EMAIL":
        return {
            ...state,
            isAuthenticated : false,
            credential      : {},
            clientID        : 0,
            email           : action.email,
        };

    case "PROFILE_UPLOAD":
        return {
            ...state,
            edition         : state.edition + 1,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
