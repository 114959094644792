import React                from "react";
import PropTypes            from "prop-types";

// Components
import MediaContent         from "./MediaContent";

// Dashboard
import Dialog               from "Dashboard/Components/Dialog/Dialog";
import DialogHeader         from "Dashboard/Components/Dialog/DialogHeader";
import DialogBody           from "Dashboard/Components/Dialog/DialogBody";
import DialogFooter         from "Dashboard/Components/Dialog/DialogFooter";



/**
 * The Media Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MediaDialog(props) {
    const {
        open, onClose, onSubmit,
        path, mediaType, initialSelect, isRequired,
    } = props;

    // The Current State
    const [ file,   setFile   ] = React.useState("");
    const [ create, setCreate ] = React.useState(false);


    // Do the Render
    return <Dialog
        open={open}
        onClose={onClose}
        zIndex={4000}
        isWide
        noTab
    >
        <DialogHeader
            message="MEDIA_SELECT_TITLE"
            icon="media"
        />
        <DialogBody withSpacing>
            <MediaContent
                path={path}
                mediaType={mediaType}
                create={create}
                endCreate={() => setCreate(false)}
                initialSelect={initialSelect}
                onSelect={setFile}
                canSelect
                inDialog
            />
        </DialogBody>
        <DialogFooter
            primary="GENERAL_SELECT"
            secondary="MEDIA_CREATE_TITLE"
            onSubmit={() => onSubmit(file)}
            onSecondary={() => setCreate(true)}
            isDisabled={isRequired && !file}
        />
    </Dialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MediaDialog.propTypes = {
    open          : PropTypes.bool.isRequired,
    onClose       : PropTypes.func.isRequired,
    onSubmit      : PropTypes.func.isRequired,
    path          : PropTypes.string,
    mediaType     : PropTypes.string,
    initialSelect : PropTypes.string,
    isRequired    : PropTypes.bool,
};

export default MediaDialog;
