import React                from "react";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import CategoryEdit         from "./CategoryEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Category List
 * @returns {React.ReactElement}
 */
function CategoryList() {
    const { fetch, load } = useList("memberCategory");

    const data = Store.useState("memberCategory");
    const { loading, canEdit, list, total, sort } = data;

    const { deleteElem } = Store.useAction("memberCategory");

    // The Current Category
    const [ action, elemCode, startAction, endAction ] = Action.useActionID();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemCode);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "categoryCode", elemCode, "name");

    return <Main>
        <Header message="CATEGORIES_NAME" icon="category">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="CATEGORIES_NONE_AVAILABLE"
                isLoading={loading}
                hasTabs
            >
                <TableHead>
                    <TableHeader field="categoryCode" message="GENERAL_CODE" maxWidth="100" />
                    <TableHeader field="name"         message="GENERAL_NAME" isTitle />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.categoryCode} elemID={elem.categoryCode}>
                        <TableCell message={elem.categoryCode} />
                        <TableCell message={elem.name}         />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="CATEGORIES_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="CATEGORIES_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <CategoryEdit
            open={action.isVCE}
            elemCode={elemCode}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="CATEGORIES_DELETE_TITLE"
            message="CATEGORIES_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default CategoryList;
