import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import SubscriptionEdit     from "./SubscriptionEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Filter               from "Dashboard/Components/Filter/Filter";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";

// The API
import {
    TrainingSubscription,
} from "Utils/API";



/**
 * The Training Subscription List
 * @returns {React.ReactElement}
 */
function SubscriptionList() {
    const { fetch, load, loadFilter } = useList("trainingSubscription", "TRAINING");

    const { trainingID } = Navigate.useParams();
    const parent = Navigate.useParent(1);

    const data = Store.useState("trainingSubscription");
    const { loading, canEdit, list, total, training, statuses, filters, sort } = data;

    const { deleteElem } = Store.useAction("trainingSubscription");

    // The Current State
    const [ exporting, setExporting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isExport) {
            setExporting(true);
            endAction();
            const source = TrainingSubscription.export({ ...filters, trainingID : training.id });
            await Utils.download(source, "inscripciones.xlsx");
            setExporting(false);
        } else if (action.isFile) {
            const elemUrl = Utils.getValue(list, "subscriptionID", elemID, "fileUrl");
            Navigate.gotoUrl(elemUrl, true);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Returns true if the File is hidden
    const hideFile = (elemID) => {
        const fileUrl = Utils.getValue(list, "subscriptionID", elemID, "fileUrl");
        return !fileUrl;
    };


    // Do the Render
    const elemName = Utils.getValue(list, "subscriptionID", elemID, "name");

    return <Main>
        <Header
            message={training.name || "SUBSCRIPTIONS_NAME"}
            icon="training"
            href={parent}
            subTitle={training.statusName}
            subCircle={training.statusColor}
        >
            <ActionList
                data={data}
                onAction={handleAction}
                isExporting={exporting}
            />
        </Header>
        <Content>
            <Filter
                onFilter={loadFilter}
                values={filters}
                hasSearch
            >
                <FilterItem
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                />
            </Filter>

            <Table
                fetch={load}
                sort={sort}
                none="SUBSCRIPTIONS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="createdTime"         message="GENERAL_CREATED_AT_FEM" minWidth="170" maxWidth="170" isTitle />
                    <TableHeader field="memberNumber"        message="GENERAL_MEMBER_NUMBER"  maxWidth="120" />
                    <TableHeader field="firstName"           message="GENERAL_MEMBER"         />
                    <TableHeader field="relatedMemberNumber" message="SUBSCRIPTIONS_MEMBER"   />
                    <TableHeader field="file"                message="TRAININGS_FILE"         maxWidth="100" align="center" />
                    <TableHeader field="status"              message="GENERAL_STATUS"         maxWidth="100" align="center" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.subscriptionID} elemID={elem.subscriptionID}>
                        <TableCell message={elem.createdTimeText}  />
                        <TableCell message={elem.memberNumber}     />
                        <TableCell message={elem.memberName}       />
                        <TableCell message={elem.subscribedMember} />
                        <TableCell message={elem.fileText}         />
                        <TableCell message={elem.statusName}       textColor={elem.statusColor} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="SUBSCRIPTIONS_EDIT_TITLE"   />
                    <TableAction action="FILE"   message="TRAININGS_VIEW_FILE"        hide={hideFile} />
                    <TableAction action="DELETE" message="SUBSCRIPTIONS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <SubscriptionEdit
            open={action.isCE}
            elemID={elemID}
            trainingID={trainingID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="SUBSCRIPTIONS_DELETE_TITLE"
            message="SUBSCRIPTIONS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default SubscriptionList;
