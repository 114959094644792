import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useMedia             from "Dashboard/Hooks/Media";
import Utils                from "Dashboard/Utils/Utils";

// Components
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import EditorField          from "Dashboard/Components/Form/EditorField";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .tox-tinymce.tox-tinymce {
        flex-grow: 2;
        height: 100% !important;
        border: none;
    }

    @media (max-width: 700px) {
        .tox {
            height: auto !important;
        }
    }

    @media (max-width: 700px) {
        .tox {
            height: auto !important;
        }
    }
`;

const Header = Styled.header`
    box-sizing: border-box;
    flex-shrink: 0;
    display: grid;
    grid-template-columns: 3fr 2fr 2fr 1fr 1fr;
    grid-gap: 8px;
    padding: 10px;
    background-color: var(--lighter-gray);
    max-width: var(--document-view-width);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    overflow: auto;
`;

const HeaderField = Styled(InputField)`
    box-sizing: border-box;
    margin: 0;
    min-width: 120px;

    .textfield-label {
        background-color: var(--lighter-gray);
    }
`;

const Footer = Styled.footer`
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    padding: 8px;
    gap: 8px;
    border-top: 1px solid var(--border-color-light);
`;



/**
 * The Document Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DocumentEdit(props) {
    const { elemID, setHasChanges, onSubmit, onDelete, onClose } = props;

    const { elem, parents, statuses } = Store.useState("document");
    const { editDocument, saveDocument } = Store.useAction("document");


    // The References
    const timerRef = React.useRef(0);

    // The Current State
    const [ save, setSave ] = React.useState(0);

    // The Initial Data
    const initialData = {
        documentID : 0,
        parentID   : 0,
        name       : "",
        content    : "",
        url        : "",
        position   : 0,
        status     : "Active",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("document", initialData, editDocument, onSubmit);

    // The Media State
    const {
        showMedia, mediaType, handleMediaOpen, handleMediaSubmit, handleMediaClose,
    } = useMedia();


    // Dialog Opens
    React.useEffect(() => {
        if (elemID) {
            setElem({ ...elem, content : elem.draftContent || elem.content });
            setAutoSave();
        }
    }, [ elemID ]);


    // Handles the Editor Change
    const handleEditor = (name, newValue) => {
        handleChange(name, newValue);
        setHasChanges(true);
    };

    // Handles the Save
    const handleSave = () => {
        setHasChanges(false);
        handleSubmit();
    };


    // Remove the Auto-save
    React.useEffect(() => {
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, []);

    // Handle the Save
    React.useEffect(() => {
        if (save) {
            saveDocument({
                documentID : elemID,
                content    : data.content,
            });
            setAutoSave();
        }
    }, [ save ]);

    // Sets an Auto-save
    const setAutoSave = () => {
        Utils.setTimeout(timerRef, () => {
            setSave(save + 1);
        }, 10 * 1000);
    };

    // Handles the Close
    const handleClose = async () => {
        await saveDocument({
            documentID : elemID,
            content    : "",
        });
        onClose();
    };



    // Do the Render
    return <>
        <Container>
            <Header className="no-scrollbars">
                <HeaderField
                    name="name"
                    label="GENERAL_NAME"
                    value={data.name}
                    error={errors.name}
                    onChange={handleChange}
                    isRequired
                />
                <HeaderField
                    type="select"
                    name="parentID"
                    label="DOCUMENTS_PARENT"
                    value={data.parentID}
                    error={errors.parentID}
                    options={parents}
                    onChange={handleChange}
                />
                <HeaderField
                    name="url"
                    label="DOCUMENTS_URL"
                    value={data.url}
                    error={errors.url}
                    onChange={handleChange}
                />
                <HeaderField
                    type="number"
                    name="position"
                    label="GENERAL_POSITION"
                    value={data.position}
                    error={errors.position}
                    onChange={handleChange}
                    minValue={0}
                />
                <HeaderField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                    value={data.status}
                    error={errors.status}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
            </Header>

            <EditorField
                name="content"
                value={data.content}
                onChange={handleEditor}
                onMedia={handleMediaOpen}
                contentStyle={`
                    a { color: #025ade; }
                    table { width: 100%; border-spacing: 0; }
                    tr td { padding: 8px 12px; border-bottom: 1px solid #ebedf2; border-right: 1px solid #ebedf2; }
                    tr:first-child td { background: #fafbfd; border-top: 1px solid #ebedf2; }
                    tr td:first-child { border-left: 1px solid #ebedf2; }
                    tr td p { margin: 0; }
                    pre { padding: 16px; border: 1px solid #ebedf2; border-radius: 10px; background: #fafbfd; }
                `}
            />

            <Footer>
                <Button
                    variant="primary"
                    message="GENERAL_SAVE"
                    onClick={handleSave}
                />
                <Button
                    isHidden={!elemID}
                    variant="primary"
                    message="GENERAL_DELETE"
                    onClick={onDelete}
                />
                <Button
                    variant="cancel"
                    message="GENERAL_CANCEL"
                    onClick={handleClose}
                />
            </Footer>
        </Container>

        <MediaDialog
            open={showMedia}
            mediaType={mediaType}
            onSubmit={handleMediaSubmit}
            onClose={handleMediaClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DocumentEdit.propTypes = {
    setHasChanges : PropTypes.func.isRequired,
    onClose       : PropTypes.func.isRequired,
    onDelete      : PropTypes.func.isRequired,
    onSubmit      : PropTypes.func.isRequired,
    elemID        : PropTypes.number,
};

export default DocumentEdit;
