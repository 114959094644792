import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Court Block Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CourtBlockFilter(props) {
    const { open, filters, activityID, onClose, onSubmit } = props;

    const { blockTypes, activities, courts, statuses } = Store.useState("courtBlock");


    // The Initial Data
    const initialData = {
        period           : 0,
        fromDate         : "",
        toDate           : "",
        courtBlockTypeID : [],
        activityID       : 0,
        courtID          : [],
        status           : [],
    };

    // The Filter State
    const {
        loading, data, handleChange, handlePeriod, handleSubmit,
    } = useFilter("courtBlock", open, initialData, filters, onSubmit);


    // Do the Render
    return <FilterDialog
        open={open}
        title="COURT_BLOCKS_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                options={Period.getSelect()}
                value={data.period}
                onChange={handlePeriod}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="chooser"
            name="courtBlockTypeID"
            label="COURT_BLOCK_TYPES_NAME"
            options={blockTypes}
            value={data.courtBlockTypeID}
            onChange={handleChange}
        />
        <InputField
            isHidden={!activityID}
            type="select"
            name="activityID"
            label="ACTIVITIES_SINGULAR"
            options={activities}
            value={data.activityID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="courtID"
            label="COURTS_NAME"
            options={courts[activityID || data.activityID] || []}
            value={data.courtID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CourtBlockFilter.propTypes = {
    open       : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    activityID : PropTypes.number,
    filters    : PropTypes.object,
};

export default CourtBlockFilter;
