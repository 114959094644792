import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import { Member }           from "Utils/API";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import ViewField            from "Dashboard/Components/Form/ViewField";
import InputItem            from "Dashboard/Components/Form/InputItem";



// Styles
const Dialog = Styled(EditDialog).attrs(({ hasReserveType, hasMemberNumber, hasMatchType }) => ({ hasReserveType, hasMemberNumber, hasMatchType }))`
    min-height: min(var(--dialog-body), ${(props) => 331 + (props.hasReserveType ? 60 : 0) + (props.hasMemberNumber ? 60 : 0) + (props.hasMatchType ? 60 : 0)}px);
    padding-top: 0px;
    padding-bottom: 0px;
`;

const Tabs = Styled(TabList)`
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
`;



/**
 * The Reserve Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReserveEdit(props) {
    const { open, elemID, reserveTypeID, date, hour, courtID, onClose, onSubmit } = props;

    const { reserveTypes, reserveType, hours, courts, durations, totalPlayers } = Store.useState("reserve");
    const { fetchEditData, editElem } = Store.useAction("reserve");


    // The Current State
    const [ tab, setTab ] = React.useState("info");

    // The Initial Data
    const initialData = {
        reserveID     : 0,
        reserveTypeID : 0,
        date          : "",
        hour          : "",
        courtID       : 0,
        matchType     : "Singles",
        duration      : 0,
        memberNumber  : "",
        memberName    : "",
        players       : "[{}]",
        comments      : "",
    };

    // Handles the Set
    const handleSet = (elem) => {
        setTab("info");
        const duration = getLastDuration(hour, courtID, data.matchType);
        const players  = elem.players?.length ? JSON.stringify(elem.players) : "[{}]";
        setElem({ date, hour, courtID, duration, ...elem, players });
    };

    // Handles the Date
    const handleUpdate = async (name, value) => {
        switch (name) {
        case "reserveTypeID":
            if (data.date && value !== data.reserveTypeID) {
                setData({
                    reserveTypeID : value,
                    hour          : "",
                    courtID       : 0,
                    duration      : 0,
                });
                await fetchEditData(value, data.date, false);
            }
            break;
        case "date":
            if (data.reserveTypeID) {
                setData({
                    date     : value,
                    hour     : "",
                    courtID  : 0,
                    duration : 0,
                });
                await fetchEditData(data.reserveTypeID, value, false);
            }
            break;
        case "hour": {
            const courtID = courts[data.hour]?.[0]?.key ?? 0;
            setData({
                hour     : value,
                courtID  : courtID,
                duration : getLastDuration(value, courtID, data.matchType),
            });
            break;
        }
        case "courtID":
            setData({
                courtID  : value,
                duration : getLastDuration(data.hour, value, data.matchType),
            });
            break;
        case "matchType":
            setData({
                matchType : value,
                duration  : getLastDuration(data.hour, data.courtID, value),
            });
            break;
        default:
            handleChange(name, value);
        }
    };

    // The Form State
    const {
        data, errors, setElem, setData, handleChange, handleSubmit,
    } = useForm("reserve", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("reserve", open, elemID, { reserveTypeID, date }, handleSet);


    // Returns the Last Duration
    const getLastDuration = (hour, courtID, matchType) => {
        const options  = durations?.[`${hour}-${courtID}-${matchType}`] ?? [];
        return options[options.length - 1]?.key ?? 0;
    };

    // Returns the Error Count in the Step
    const getErrorCount = (tab) => {
        let total = 0;
        for (const [ key, error ] of Object.entries(errors)) {
            if (!error) {
                continue;
            }
            if (tab === "players" && key.startsWith(tab)) {
                total += 1;
            } else if (tab === "info" && !key.startsWith("players")) {
                total += 1;
            }
        }
        return total;
    };


    // Variables
    const isEdit          = Boolean(elemID);
    const showReserveType = Boolean(!isEdit && !reserveTypeID);
    const hasReserveType  = Boolean(reserveTypeID || data.reserveTypeID);
    const hasHours        = Boolean(hasReserveType && hours && hours.length);
    const hasMatchType    = Boolean(reserveType && reserveType.allowsDoubles);


    // Do the Render
    return <Dialog
        open={open}
        icon="reserve"
        title={isEdit ? "RESERVES_EDIT_TITLE" : "RESERVES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        hasReserveType={showReserveType}
        hasMemberNumber={!isEdit}
        hasMatchType={hasMatchType}
    >
        <Tabs selected={tab} onClick={setTab} inDialog>
            <TabItem message="GENERAL_INFO"     value="info"    badge={getErrorCount("info")}    />
            <TabItem message="RESERVES_PLAYERS" value="players" badge={getErrorCount("players")} />
        </Tabs>

        <Columns isHidden={tab !== "info"} amount={1}>
            <InputField
                isHidden={isEdit}
                type="suggest"
                name="memberName"
                label="GENERAL_MEMBER"
                value={data.memberName}
                error={errors.memberNumber}
                suggestID="memberNumber"
                suggestFetch={Member.search}
                onChange={handleChange}
                isRequired
            />
            <InputField
                isHidden={!showReserveType}
                type="select"
                name="reserveTypeID"
                label="RESERVE_TYPES_SINGULAR"
                options={reserveTypes}
                value={data.reserveTypeID}
                error={errors.reserveTypeID}
                onChange={handleUpdate}
                isRequired
            />

            <InputField
                isHidden={!hasReserveType}
                type="date"
                name="date"
                label="RESERVES_DATE"
                value={data.date}
                error={errors.date}
                onChange={handleUpdate}
                isRequired
            />
            <ViewField
                isHidden={!hasReserveType || hasHours}
                label="RESERVES_HOUR"
                message="RESERVE_CALENDAR_NONE_AVAILABLE"
            />

            <InputField
                isHidden={!hasHours}
                type="select"
                name="hour"
                label="RESERVES_HOUR"
                options={hours}
                value={data.hour}
                error={errors.hour}
                onChange={handleUpdate}
                isRequired
            />
            <InputField
                isHidden={!hasHours}
                type="select"
                name="courtID"
                label="COURTS_SINGULAR"
                options={courts?.[data.hour] ?? []}
                value={data.courtID}
                error={errors.courtID}
                onChange={handleUpdate}
                isRequired
            />
            <InputField
                isHidden={!hasHours || !hasMatchType}
                type="select"
                name="matchType"
                label="RESERVES_MATCH_TYPE"
                options="SELECT_MATCH_TYPES"
                value={data.matchType}
                error={errors.matchType}
                onChange={handleUpdate}
                isRequired
            />
            <InputField
                isHidden={!hasHours}
                type="select"
                name="duration"
                label="RESERVES_DURATION"
                options={durations?.[`${data.hour}-${data.courtID}-${data.matchType}`] ?? []}
                value={data.duration}
                error={errors.duration}
                onChange={handleUpdate}
                isRequired
            />

            <InputField
                isHidden={!hasHours}
                type="textarea"
                name="comments"
                label="GENERAL_COMMENTS"
                value={data.comments}
                error={errors.comments}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            isHidden={!hasHours || tab !== "players"}
            type="fields"
            name="players"
            title="RESERVES_PLAYERS_TITLE"
            addButton="RESERVES_ADD_PLAYER"
            value={data.players}
            errors={errors}
            onChange={handleChange}
            columns="2"
            maxAmount={totalPlayers[data.matchType] ?? 0}
            isSortable
        >
            <InputItem
                type="select"
                name="playerType"
                label="RESERVES_PLAYER_TYPE"
                options="SELECT_PLAYER_TYPES"
                isRequired
            />
            <InputItem
                hide={(elem) => elem.playerType !== "Member"}
                type="suggest"
                name="memberName"
                label="GENERAL_MEMBER_NUMBER"
                suggestID="memberNumber"
                suggestFetch={Member.search}
                isRequired
            />
            <InputItem
                hide={(elem) => elem.playerType !== "Member"}
                type="toggle"
                name="sharesPayment"
                label="RESERVES_SHARES_PAYMENT"
                withBorder
            />
            <InputItem
                hide={(elem) => elem.playerType !== "Member" || !elem.sharesPayment}
                type="toggle"
                name="isConfirmed"
                label="RESERVES_IS_CONFIRMED"
                withBorder
            />

            <InputItem
                hide={(elem) => elem.playerType !== "Guest"}
                name="firstName"
                label="GENERAL_FIRST_NAME"
                isRequired
            />
            <InputItem
                hide={(elem) => elem.playerType !== "Guest"}
                name="lastName"
                label="GENERAL_LAST_NAME"
                isRequired
            />
            <InputItem
                hide={(elem) => elem.playerType !== "Guest"}
                type="select"
                name="documentType"
                label="GENERAL_DOCUMENT_TYPE"
                options="SELECT_DOCUMENT_TYPES"
                isRequired
            />
            <InputItem
                hide={(elem) => elem.playerType !== "Guest"}
                name="documentValue"
                label="GENERAL_DOCUMENT_VALUE"
                isRequired
            />
            <InputItem
                hide={(elem) => elem.playerType !== "Guest"}
                type="email"
                name="email"
                label="GENERAL_EMAIL"
                isRequired
            />
            <InputItem
                hide={(elem) => elem.playerType !== "Guest"}
                type="select"
                name="ageRange"
                label="GENERAL_AGE"
                options="SELECT_AGE_RANGES"
                isRequired
            />
        </InputField>
    </Dialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReserveEdit.propTypes = {
    open          : PropTypes.bool.isRequired,
    onClose       : PropTypes.func.isRequired,
    onSubmit      : PropTypes.func.isRequired,
    reserveTypeID : PropTypes.number.isRequired,
    date          : PropTypes.string.isRequired,
    elemID        : PropTypes.number,
    hour          : PropTypes.string,
    courtID       : PropTypes.number,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
ReserveEdit.defaultProps = {
    hour    : "",
    courtID : 0,
};

export default ReserveEdit;
