import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";
import { Member }           from "Utils/API";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Ticket Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TicketFilter(props) {
    const { open, filters, onClose, onSubmit } = props;

    const { areas, departments, subjects, waitlists, credentials } = Store.useState("ticket");


    // The Initial Data
    const initialData = {
        period             : 0,
        fromDate           : "",
        toDate             : "",
        ticketID           : "",
        priority           : [],
        ticketAreaID       : 0,
        ticketDepartmentID : 0,
        ticketSubjectID    : 0,
        ticketWaitlistID   : 0,
        memberNumber       : "",
        memberName         : "",
        credentialID       : [],
        onlyMine           : 0,
    };

    // The Filter State
    const {
        loading, data, handleChange, handlePeriod, handleSubmit,
    } = useFilter("ticket", open, initialData, filters, onSubmit);


    // Do the Render
    return <FilterDialog
        open={open}
        title="RESERVES_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                options={Period.getSelect()}
                value={data.period}
                onChange={handlePeriod}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="number"
            name="ticketID"
            label="GENERAL_ID"
            value={data.ticketID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="priority"
            label="GENERAL_PRIORITY"
            options="SELECT_PRIORITIES"
            value={data.priority}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="ticketAreaID"
            label="TICKET_AREAS_SINGULAR"
            options={areas}
            value={data.ticketAreaID}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="ticketDepartmentID"
            label="TICKET_DEPARTMENTS_SINGULAR"
            options={departments[data.ticketAreaID] ?? []}
            value={data.ticketDepartmentID}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="ticketSubjectID"
            label="TICKET_SUBJECTS_SINGULAR"
            options={subjects[data.ticketDepartmentID] ?? []}
            value={data.ticketSubjectID}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="ticketWaitlistID"
            label="TICKET_WAITLISTS_SINGULAR"
            options={waitlists}
            value={data.ticketWaitlistID}
            onChange={handleChange}
        />
        <InputField
            type="suggest"
            name="memberName"
            label="GENERAL_MEMBER"
            value={data.memberName}
            suggestID="memberNumber"
            suggestFetch={Member.search}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="credentialID"
            label="TICKETS_ASSIGNED"
            options={credentials}
            value={data.credentialID}
            onChange={handleChange}
        />
        <InputField
            type="toggle"
            name="onlyMine"
            label="TICKETS_ONLY_MINE"
            value={data.onlyMine}
            onChange={handleChange}
            withBorder
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TicketFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
};

export default TicketFilter;
