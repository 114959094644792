import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import useList              from "Dashboard/Hooks/List";

// Components
import ProviderLogView      from "./ProviderLogView";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Provider Log List
 * @returns {React.ReactElement}
 */
function ProviderLogList() {
    const { load, loadFilter } = useList("providerLog");

    const data = Store.useState("providerLog");
    const { loading, canEdit, list, total, filters, sort } = data;

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();



    // Do the Render
    return <Main>
        <Header message="PROVIDERS_LOG_NAME" icon="provider-log" />
        <Content>
            <Filter
                onFilter={loadFilter}
                values={filters}
                hasSearch
            />
            <Table
                fetch={load}
                sort={sort}
                none="PROVIDERS_LOG_NONE_AVAILABLE"
                isLoading={loading}
                hasFilter
            >
                <TableHead>
                    <TableHeader field="createdTime"  message="GENERAL_TIME"           maxWidth="160" />
                    <TableHeader field="provider"     message="PROVIDERS_LOG_PROVIDER" maxWidth="160" isTitle />
                    <TableHeader field="method"       message="PROVIDERS_LOG_METHOD"   maxWidth="120" />
                    <TableHeader field="action"       message="PROVIDERS_LOG_ACTION"   />
                    <TableHeader field="memberNumber" message="GENERAL_MEMBER_NUMBER"  maxWidth="160" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.logID} elemID={elem.logID}>
                        <TableCell message={elem.createdTimeText} />
                        <TableCell message={elem.provider}        />
                        <TableCell message={elem.method}          className={elem.errorClass} />
                        <TableCell message={elem.action}          />
                        <TableCell message={elem.memberNumber}    />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="VIEW" message="PROVIDERS_LOG_VIEW_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <ProviderLogView
            open={action.isVCE}
            elemID={elemID}
            onClose={endAction}
        />
    </Main>;
}

export default ProviderLogList;
