import React                from "react";
import PropTypes            from "prop-types";
import MediaType            from "Dashboard/Core/MediaType";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import useMedia             from "Dashboard/Hooks/Media";

// Components
import MediaField           from "Components/Utils/Inputs/MediaField";
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import InputField           from "Dashboard/Components/Form/InputField";
import EditorField          from "Dashboard/Components/Form/EditorField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The News Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function NewsEdit(props) {
    const { open, isCopy, elemID, onClose, onSubmit } = props;

    const { categories, statuses } = Store.useState("news");
    const { editElem } = Store.useAction("news");


    // The Current State
    const [ tab, setTab ] = React.useState("info");

    // The Initial Data
    const initialData = {
        newsID         : 0,
        newsCategoryID : 0,
        publishDate    : "",
        title          : "",
        summary        : "",
        content        : "",
        image          : "",
        showBefore     : 1,
        showInHome     : 0,
        forNazar       : 0,
        addTitleBanner : 0,
        status         : "Active",
    };

    // Handles the Set
    const handleSet = (elem) => {
        setTab("info");
        setElem(elem);
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await editElem({
            ...data,
            newsID : isCopy ? 0 : (elemID || 0),
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("news", initialData, handleEdit, onSubmit);

    // The Media State
    const {
        showMedia, mediaType, handleMediaOpen, handleMediaSubmit, handleMediaClose,
    } = useMedia();

    // Load the Data
    const { loading } = useDialog("news", open, elemID, {}, handleSet);


    // Returns the Error Count in the Step
    const getErrorCount = (tab) => {
        let total = 0;
        for (const [ key, error ] of Object.entries(errors)) {
            if (!error) {
                continue;
            }
            if (tab === "content" && key === "content") {
                total += 1;
            } else if (tab === "info" && key !== "content") {
                total += 1;
            }
        }
        return total;
    };

    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "NEWS_COPY_TITLE";
        }
        if (elemID) {
            return "NEWS_EDIT_TITLE";
        }
        return "NEWS_CREATE_TITLE";
    }, [ isCopy, elemID ]);


    // Do the Render
    return <>
        <EditDialog
            open={open}
            icon="news"
            title={title}
            error={errors.form}
            onSubmit={handleSubmit}
            onClose={onClose}
            isLoading={loading}
            isWide
        >
            <TabList selected={tab} onClick={setTab} inDialog>
                <TabItem message="GENERAL_INFO"    value="info"    badge={getErrorCount("info")}    />
                <TabItem message="GENERAL_CONTENT" value="content" badge={getErrorCount("content")} />
            </TabList>

            <Columns isHidden={tab !== "info"}>
                <InputField
                    type="date"
                    name="publishDate"
                    label="ENTRIES_PUBLISH_DATE"
                    value={data.publishDate}
                    error={errors.publishDate}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="toggle"
                    name="showBefore"
                    label="ENTRIES_SHOW_BEFORE_PUBLISH"
                    value={data.showBefore}
                    onChange={handleChange}
                    withBorder
                />

                <InputField
                    type="toggle"
                    name="showInHome"
                    label="NEWS_SHOW_IN_HOME"
                    value={data.showInHome}
                    onChange={handleChange}
                    withBorder
                />
                <InputField
                    type="toggle"
                    name="forNazar"
                    label="NEWS_SHOW_IN_NAZAR"
                    value={data.forNazar}
                    onChange={handleChange}
                    withBorder
                />
                <InputField
                    className="columns-double"
                    type="select"
                    name="newsCategoryID"
                    label="CATEGORIES_SINGULAR"
                    options={categories}
                    value={data.newsCategoryID}
                    error={errors.newsCategoryID}
                    onChange={handleChange}
                    isRequired
                />

                <InputField
                    name="title"
                    label="GENERAL_TITLE"
                    value={data.title}
                    error={errors.title}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="toggle"
                    name="addTitleBanner"
                    label="NEWS_ADD_TITLE_BANNER"
                    value={data.addTitleBanner}
                    onChange={handleChange}
                    withBorder
                />

                <InputField
                    className="columns-double"
                    type="textarea"
                    name="summary"
                    label="ENTRIES_SUMMARY"
                    value={data.summary}
                    error={errors.summary}
                    onChange={handleChange}
                    isRequired
                />
                <MediaField
                    name="image"
                    label="GENERAL_IMAGE"
                    value={data.image}
                    error={errors.image}
                    onChange={handleChange}
                    mediaType={MediaType.IMAGE}
                    isRequired
                    onlyImages
                />

                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                    value={data.status}
                    error={errors.status}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
            </Columns>

            <EditorField
                isHidden={tab !== "content"}
                name="content"
                value={data.content}
                error={errors.content}
                onChange={handleChange}
                onMedia={handleMediaOpen}
            />
        </EditDialog>

        <MediaDialog
            open={showMedia}
            mediaType={mediaType}
            onSubmit={handleMediaSubmit}
            onClose={handleMediaClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
NewsEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    isCopy   : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default NewsEdit;
