import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Reserve }          from "Utils/API";



// The initial State
const initialState = {
    loading      : true,
    error        : false,
    canEdit      : false,
    reserveType  : {},
    reserveTypes : [],
    reserves     : [],
    blocks       : [],
    invalids     : [],
    blanks       : [],
    courts       : [],
    times        : [],
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "RESERVE_CALENDAR_LOADING" });
    },

    /**
     * Fetches the Reserve Calendar
     * @param {Function} dispatch
     * @param {String}   type
     * @param {Number}   reserveTypeID
     * @param {Number}   time
     * @param {Boolean=} withLoader
     * @returns {Promise}
     */
    async fetchList(dispatch, type, reserveTypeID, time, withLoader = true) {
        if (withLoader) {
            dispatch({ type : "RESERVE_CALENDAR_LOADING" });
        }
        let data;
        if (type === "API") {
            data = await Reserve.getKiosk({ reserveTypeID, time });
        } else {
            data = await Reserve.getCalendar({ reserveTypeID, time });
        }
        dispatch({ type : "RESERVE_CALENDAR_LIST", data });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "RESERVE_CALENDAR_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "RESERVE_CALENDAR_LOADING":
        return {
            ...state,
            loading     : true,
        };

    case "RESERVE_CALENDAR_LIST":
        return {
            ...state,
            loading      : false,
            error        : false,
            canEdit      : action.data.canEdit,
            reserveType  : action.data.reserveType,
            reserveTypes : action.data.reserveTypes,
            reserves     : action.data.reserves,
            blocks       : action.data.blocks,
            invalids     : action.data.invalids,
            blanks       : action.data.blanks,
            courts       : action.data.courts,
            times        : action.data.times,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
