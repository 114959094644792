import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The State Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function StateEdit(props) {
    const { open, elemCode, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("memberState");


    // The Initial Data
    const initialData = {
        stateCode    : 0,
        newStateCode : 0,
        name         : "",
        allowsAccess : 0,
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({
            ...elem,
            newStateCode : elem.stateCode,
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("memberState", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("memberState", open, elemCode, {}, handleSet);


    // Do the Render
    return <EditDialog
        open={open}
        icon="state"
        title={elemCode ? "MEMBERS_STATES_EDIT_TITLE" : "MEMBERS_STATES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            type="number"
            name="newStateCode"
            label="GENERAL_CODE"
            value={data.newStateCode}
            error={errors.newStateCode}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="toggle"
            name="allowsAccess"
            label="MEMBERS_STATES_ALLOWS_ACCESS"
            value={data.allowsAccess}
            error={errors.allowsAccess}
            onChange={handleChange}
            withBorder
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
StateEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemCode : PropTypes.number,
};

export default StateEdit;
