import Store                        from "Dashboard/Core/Store";

// Core
import AuthStore                    from "Stores/Core/AuthStore";

// Profile
import ProfileStore                 from "Stores/App/Profile/ProfileStore";

// Entry
import NewsStore                    from "Stores/App/Entry/NewsStore";
import NewsCategoryStore            from "Stores/App/Entry/NewsCategoryStore";
import BillboardStore               from "Stores/App/Entry/BillboardStore";


// Activity
import ActivityStore                from "Stores/App/Activity/ActivityStore";
import ButtonStore                  from "Stores/App/Activity/ButtonStore";
import PageStore                    from "Stores/App/Activity/PageStore";

// Activity: Course
import CourseStore                  from "Stores/App/Activity/Course/CourseStore";
import CourseSubscriptionStore      from "Stores/App/Activity/Course/CourseSubscriptionStore";
import CoursePersonStore            from "Stores/App/Activity/Course/CoursePersonStore";

// Activity: Shift
import ShiftStore                   from "Stores/App/Activity/Shift/ShiftStore";
import ShiftSubscriptionStore       from "Stores/App/Activity/Shift/ShiftSubscriptionStore";
import ShiftCourseStore             from "Stores/App/Activity/Shift/ShiftCourseStore";

// Activity: Training
import TrainingStore                from "Stores/App/Activity/Training/TrainingStore";
import TrainingSubscriptionStore    from "Stores/App/Activity/Training/TrainingSubscriptionStore";
import TrainingWhiteListStore       from "Stores/App/Activity/Training/TrainingWhiteListStore";
import TrainingMemberStore          from "Stores/App/Activity/Training/TrainingMemberStore";


// Reserve
import ReserveStore                 from "Stores/App/Reserve/ReserveStore";
import ReserveCalendarStore         from "Stores/App/Reserve/ReserveCalendarStore";
import ReserveTypeStore             from "Stores/App/Reserve/ReserveTypeStore";
import CourtStore                   from "Stores/App/Reserve/CourtStore";
import CourtBlockStore              from "Stores/App/Reserve/CourtBlockStore";
import CourtBlockTypeStore          from "Stores/App/Reserve/CourtBlockTypeStore";

// Schedule
import ScheduleStore                from "Stores/App/Schedule/ScheduleStore";
import ScheduleCategoryStore        from "Stores/App/Schedule/ScheduleCategoryStore";
import ScheduleHolidayStore         from "Stores/App/Schedule/ScheduleHolidayStore";

// Administration
import TitleStore                   from "Stores/App/Administration/TitleStore";
import FeeStore                     from "Stores/App/Administration/FeeStore";
import BalanceStore                 from "Stores/App/Administration/BalanceStore";

// Contact
import ContactStore                 from "Stores/App/Contact/ContactStore";
import ContactCategoryStore         from "Stores/App/Contact/ContactCategoryStore";
import ContactSocialStore           from "Stores/App/Contact/ContactSocialStore";

// Ticket
import TicketStore                  from "Stores/App/Ticket/TicketStore";
import TicketAreaStore              from "Stores/App/Ticket/TicketAreaStore";
import TicketDepartmentStore        from "Stores/App/Ticket/TicketDepartmentStore";
import TicketSubjectStore           from "Stores/App/Ticket/TicketSubjectStore";
import TicketWaitlistStore          from "Stores/App/Ticket/TicketWaitlistStore";

// Member
import MemberStore                  from "Stores/App/Member/MemberStore";
import MemberStateStore             from "Stores/App/Member/MemberStateStore";
import MemberCategoryStore          from "Stores/App/Member/MemberCategoryStore";
import MemberRelationStore          from "Stores/App/Member/MemberRelationStore";
import ProvinceStore                from "Stores/App/Member/ProvinceStore";

// Document
import DocumentStore                from "Stores/App/Document/DocumentStore";

// Setup
import AdminStore                   from "Stores/App/Setup/AdminStore";
import NotificationTypeStore        from "Stores/App/Setup/NotificationTypeStore";
import VersionStore                 from "Stores/App/Setup/VersionStore";
import MediaStore                   from "Stores/App/Setup/MediaStore";
import NotificationStore            from "Stores/App/Setup/NotificationStore";

// Email
import EmailTemplateStore           from "Stores/App/Email/EmailTemplateStore";
import EmailQueueStore              from "Stores/App/Email/EmailQueueStore";
import EmailWhiteListStore          from "Stores/App/Email/EmailWhiteListStore";

// Log
import ProviderLogStore             from "Stores/App/Log/ProviderLogStore";
import ActionLogStore               from "Stores/App/Log/ActionLogStore";
import ErrorLogStore                from "Stores/App/Log/ErrorLogStore";



// The Combined Stores
export default Store.configureStore({
    auth                    : AuthStore,

    // Profile
    profile                 : ProfileStore,

    // Entry
    news                    : NewsStore,
    newsCategory            : NewsCategoryStore,
    billboard               : BillboardStore,

    // Activity
    activity                : ActivityStore,
    button                  : ButtonStore,
    page                    : PageStore,

    // Activity: Course
    course                  : CourseStore,
    courseSubscription      : CourseSubscriptionStore,
    coursePerson            : CoursePersonStore,

    // Activity: Shift
    shift                   : ShiftStore,
    shiftSubscription       : ShiftSubscriptionStore,
    shiftCourse             : ShiftCourseStore,

    // Activity: Training
    training                : TrainingStore,
    trainingSubscription    : TrainingSubscriptionStore,
    trainingWhiteList       : TrainingWhiteListStore,
    trainingMember          : TrainingMemberStore,


    // Reserve
    reserve                 : ReserveStore,
    reserveCalendar         : ReserveCalendarStore,
    reserveType             : ReserveTypeStore,
    court                   : CourtStore,
    courtBlock              : CourtBlockStore,
    courtBlockType          : CourtBlockTypeStore,

    // Schedule
    schedule                : ScheduleStore,
    scheduleCategory        : ScheduleCategoryStore,
    scheduleHoliday         : ScheduleHolidayStore,

    // Administration
    title                   : TitleStore,
    fee                     : FeeStore,
    balance                 : BalanceStore,

    // Contact
    contact                 : ContactStore,
    contactCategory         : ContactCategoryStore,
    contactSocial           : ContactSocialStore,

    // Ticket
    ticket                  : TicketStore,
    ticketArea              : TicketAreaStore,
    ticketDepartment        : TicketDepartmentStore,
    ticketSubject           : TicketSubjectStore,
    ticketWaitlist          : TicketWaitlistStore,

    // Member
    member                  : MemberStore,
    memberState             : MemberStateStore,
    memberCategory          : MemberCategoryStore,
    memberRelation          : MemberRelationStore,
    province                : ProvinceStore,

    // Document
    document                : DocumentStore,

    // Setup
    admin                   : AdminStore,
    notificationType        : NotificationTypeStore,
    version                 : VersionStore,
    media                   : MediaStore,
    notification            : NotificationStore,

    // Email
    emailTemplate           : EmailTemplateStore,
    emailQueue              : EmailQueueStore,
    emailWhiteList          : EmailWhiteListStore,

    // Log
    providerLog             : ProviderLogStore,
    actionLog               : ActionLogStore,
    errorLog                : ErrorLogStore,

});
