import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { Version }          from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canFilter : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    sort      : {
        orderBy  : "version",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "VERSION_LOADING" });
    },

    /**
     * Fetches the Version List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await Version.getAll(params);
        data.sort  = params;
        dispatch({ type : "VERSION_LIST", data });
    },

    /**
     * Fetches a single Version
     * @param {Function} dispatch
     * @param {Number}   versionID
     * @returns {Promise}
     */
    async fetchElem(dispatch, versionID) {
        const data = await Version.getOne({ versionID });
        dispatch({ type : "VERSION_ELEM", data });
    },

    /**
     * Edits/Creates a Version
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return Version.edit(data);
    },

    /**
     * Deletes a Version
     * @param {Function} dispatch
     * @param {Number}   versionID
     * @returns {Promise}
     */
    deleteElem(dispatch, versionID) {
        return Version.delete({ versionID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.environmentName = NLS.get("SELECT_ENVIRONMENTS", elem.environment);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "VERSION_LIST", "VERSION_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "VERSION_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "VERSION_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canFilter : action.data.canFilter,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case "VERSION_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
