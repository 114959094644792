import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import ViewField            from "Dashboard/Components/Form/ViewField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Email Queue View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function EmailQueueView(props) {
    const { open, elemID, onClose } = props;

    const { fetchElem, resendElem } = Store.useAction("emailQueue");


    // The Current State
    const [ update,     setUpdate     ] = React.useState(false);
    const [ showResend, setShowResend ] = React.useState(false);

    // Load the Data
    const { loading, elem } = useDialog("emailQueue", open, elemID);


    // Handles the Resend Submit
    const handleResend = async () => {
        const response = await resendElem(elemID);
        if (response.success) {
            fetchElem(elemID);
            setShowResend(false);
            setUpdate(true);
        }
    };

    // Handles the Dialog Close
    const handleClose = () => {
        onClose(update);
    };


    // Do the Render
    return <>
        <ViewDialog
            open={open}
            icon="email-queue"
            title="EMAIL_QUEUE_VIEW_TITLE"
            secondary="EMAIL_QUEUE_RESEND_TITLE"
            onSecondary={() => setShowResend(true)}
            onClose={handleClose}
            isLoading={loading}
            withSpacing
        >
            <Columns amount={elem.sentTime ? "2" : "1"}>
                <ViewField
                    label="GENERAL_CREATED_AT"
                    value={elem.createdDateText}
                />
                <ViewField
                    label="GENERAL_SENT_TO"
                    value={elem.sentDateText}
                />
            </Columns>

            <ViewField
                label="GENERAL_RESULT"
                value={elem.emailResultText}
                viewClass={elem.errorClass}
            />

            <Columns>
                <ViewField
                    label="GENERAL_SEND_TO"
                    value={elem.sendToText}
                    showEmpty
                />
                <ViewField
                    label="GENERAL_MEMBER_NUMBER"
                    value={elem.dataID}
                    showEmpty
                />
            </Columns>

            <ViewField
                label="GENERAL_SUBJECT"
                value={elem.subject}
            />
            <ViewField
                label="GENERAL_MESSAGE"
                value={elem.message}
            />
        </ViewDialog>

        <ConfirmDialog
            open={showResend}
            icon="send"
            title="EMAIL_QUEUE_RESEND_TITLE"
            message={NLS.pluralize("EMAIL_QUEUE_RESEND_TEXT", 1)}
            onSubmit={handleResend}
            onClose={() => setShowResend(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
EmailQueueView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default EmailQueueView;
