import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    ShiftSubscription,
} from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canFilter : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    shift     : {},
    elem      : {
        persons : [],
    },
    statuses  : [],
    courses   : [],
    filters   : {
        search   : "",
        status   : "",
        fromDate : "",
        toDate   : "",
    },
    sort      : {
        orderBy  : "createdTime",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "SHIFT_SUBSCRIPTION_LOADING" });
    },

    /**
     * Fetches the Shift Subscription List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "SHIFT") {
            params.shiftID = elemID;
        }

        const data   = await ShiftSubscription.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "SHIFT_SUBSCRIPTION_LIST", data });
    },

    /**
     * Fetches a single Shift Subscription
     * @param {Function} dispatch
     * @param {Number}   subscriptionID
     * @returns {Promise}
     */
    async fetchElem(dispatch, subscriptionID) {
        const data = await ShiftSubscription.getOne({ subscriptionID });
        dispatch({ type : "SHIFT_SUBSCRIPTION_ELEM", data });
    },
};



/**
 * Parses a single Element
 * @param {Object}   elem
 * @param {Object[]} courses
 * @returns {Object}
 */
function parseElem(elem, courses) {
    elem.createdTimeText = DateTime.formatString(elem.createdTime, false);
    elem.name            = `${elem.memberNumber} - ${elem.memberName}`;
    elem.numberText      = `#${elem.number}`;

    const timeDate = DateTime.create(elem.time);
    elem.timeText  = `${timeDate.toString("time")} - ${timeDate.moveMins(30).toString("time")}`;

    if (elem.subscriptionType === "Me") {
        elem.persons.push({
            shiftPersonID   : 0,
            name            : elem.memberName,
            memberNumber    : elem.memberNumber,
            birthDate       : elem.memberBirthDate,
            age             : DateTime.getAge(elem.memberBirthDate),
            courseName      : elem.courseName,
            pastCourseNames : getCourseNames(courses, elem.pastCourseIDs),
        });
    } else {
        for (const person of elem.persons) {
            person.age             = DateTime.getAge(person.birthDate);
            person.pastCourseNames = getCourseNames(courses, person.pastCourseIDs);
        }
    }
    return elem;
}

/**
 * Returns the names of the Courses
 * @param {Object[]} courses
 * @param {String}   pastCourseIDs
 * @returns {String}
 */
function getCourseNames(courses, pastCourseIDs) {
    if (!courses || !courses.length) {
        return "";
    }

    const courseIDs   = String(pastCourseIDs).split(",");
    const courseNames = [];
    for (const courseID of courseIDs) {
        const name = Utils.getValue(courses, "key", courseID, "value");
        if (name) {
            courseNames.push(name);
        }
    }
    return courseNames.join(", ");
}


/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "SHIFT_SUBSCRIPTION_LIST", "SHIFT_SUBSCRIPTION_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "SHIFT_SUBSCRIPTION_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "SHIFT_SUBSCRIPTION_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canFilter : action.data.canFilter,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            shift     : action.data.shift,
            statuses  : action.data.statuses,
            filters   : action.data.filters,
            sort      : action.data.sort,
        };

    case "SHIFT_SUBSCRIPTION_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem, action.data.courses),
            courses   : action.data.courses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
