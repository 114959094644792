import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { NotificationType } from "Utils/API";



// The initial State
const initialState = {
    loading    : true,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    canFilter  : false,
    canImport  : false,
    canExport  : false,
    list       : [],
    total      : 0,
    elem       : {},
    activities : [],
    sort       : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "NOTIFICATION_TYPE_LOADING" });
    },

    /**
     * Fetches the Notification Type List
     * @param {Function} dispatch
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, params = {}) {
        const data = await NotificationType.getAll(params);
        data.sort = params;
        dispatch({ type : "NOTIFICATION_TYPE_LIST", data });
    },

    /**
     * Fetches a single Notification Type
     * @param {Function} dispatch
     * @param {Number}   notificationTypeID
     * @returns {Promise}
     */
    async fetchElem(dispatch, notificationTypeID) {
        const data = await NotificationType.getOne({ notificationTypeID });
        dispatch({ type : "NOTIFICATION_TYPE_ELEM", data });
    },

    /**
     * Fetches the Notification Type Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await NotificationType.getEditData();
        dispatch({ type : "NOTIFICATION_TYPE_EDIT", data });
    },

    /**
     * Edits/Creates a Notification Type
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return NotificationType.edit(data);
    },

    /**
     * Deletes a Notification Type
     * @param {Function} dispatch
     * @param {Number}   notificationTypeID
     * @returns {Promise}
     */
    deleteElem(dispatch, notificationTypeID) {
        return NotificationType.delete({ notificationTypeID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.typeName = elem.forActivity ? "ACTIVITIES_NAME" : "NOTIFICATION_TYPES_ADMINISTRATION";
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "NOTIFICATION_TYPE_LIST", "NOTIFICATION_TYPE_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "NOTIFICATION_TYPE_LOADING":
        return {
            ...state,
            loading    : true,
        };

    case "NOTIFICATION_TYPE_LIST":
        return {
            ...state,
            loading    : false,
            error      : false,
            canCreate  : action.data.canCreate,
            canEdit    : action.data.canEdit,
            canFilter  : action.data.canFilter,
            canImport  : action.data.canImport,
            canExport  : action.data.canExport,
            list       : Utils.parseList(action.data.list, parseElem),
            total      : action.data.total,
            sort       : action.data.sort,
        };

    case "NOTIFICATION_TYPE_ELEM":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            elem       : parseElem(action.data.elem),
            activities : action.data.activities,
        };

    case "NOTIFICATION_TYPE_EDIT":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            activities : action.data.activities,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
