import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableRow             from "Dashboard/Components/Table/TableRow";



/**
 * The Subscription View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubscriptionView(props) {
    const { open, elemID, onClose } = props;

    const { elem, courses } = Store.useState("shiftSubscription");


    // Load the Data
    const { loading } = useDialog("shiftSubscription", open, elemID);



    // Do the Render
    return <ViewDialog
        open={open}
        icon="subscription"
        title="SUBSCRIPTIONS_VIEW_TITLE"
        onClose={onClose}
        isLoading={loading}
        withSpacing
        isWide
    >
        <Columns amount={6}>
            <ViewField
                label="GENERAL_CREATED_AT_FEM"
                value={elem.createdTimeText}
            />
            <ViewField
                label="GENERAL_NUMBER"
                value={elem.numberText}
            />
            <ViewField
                label="SCHEDULES_SINGULAR"
                value={elem.timeText}
            />
            <ViewField
                label="GENERAL_MEMBER_NUMBER"
                value={elem.memberNumber}
            />
            <ViewField
                label="GENERAL_MEMBER"
                value={elem.memberName}
            />
            <ViewField
                label="GENERAL_STATUS"
                value={elem.statusName}
                textColor={elem.statusColor}
            />
        </Columns>

        <Table noSorting notFixed>
            <TableHead>
                <TableHeader field="name"            message="GENERAL_NAME"          isTitle />
                <TableHeader field="memberNumber"    message="GENERAL_MEMBER_NUMBER" maxWidth="120" />
                <TableHeader field="birthDate"       message="GENERAL_BIRTH_DATE"    maxWidth="140" />
                <TableHeader field="age"             message="GENERAL_AGE"           maxWidth="80" />
                <TableHeader field="pastCourseNames" message="SHIFTS_PAST_COURSES"   isHidden={!courses.length} />
                <TableHeader field="courseName"      message="SHIFTS_REQ_COURSE"     isHidden={!courses.length} />
            </TableHead>
            <TableBody>
                {elem.persons.map((item) => <TableRow key={item.shiftPersonID}>
                    <TableCell message={item.name}            />
                    <TableCell message={item.memberNumber}    />
                    <TableCell message={item.birthDate}       />
                    <TableCell message={item.age}             />
                    <TableCell message={item.pastCourseNames} />
                    <TableCell message={item.courseName}      />
                </TableRow>)}
            </TableBody>
        </Table>
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubscriptionView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default SubscriptionView;
