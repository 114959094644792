import NLS                  from "Dashboard/Core/NLS";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { CourtBlock }       from "Utils/API";



// The initial State
const initialState = {
    loading    : true,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    canFilter  : false,
    canImport  : false,
    canExport  : false,
    list       : [],
    total      : 0,
    activityID : 0,
    elem       : {},
    activities : [],
    blockTypes : [],
    courts     : {},
    statuses   : [],
    filters    : {},
    hasFilters : false,
    sort       : {
        filter   : "Current",
        orderBy  : "name",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "COURT_BLOCK_LOADING" });
    },

    /**
     * Fetches the Court Block List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "RESERVE_TYPE") {
            params.reserveTypeID = elemID;
        }

        const data   = await CourtBlock.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "COURT_BLOCK_LIST", data });
    },

    /**
     * Fetches a single Court Block
     * @param {Function} dispatch
     * @param {Number}   courtBlockID
     * @returns {Promise}
     */
    async fetchElem(dispatch, courtBlockID) {
        const data = await CourtBlock.getOne({ courtBlockID });
        dispatch({ type : "COURT_BLOCK_ELEM", data });
    },

    /**
     * Fetches the Court Block Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await CourtBlock.getEditData();
        dispatch({ type : "COURT_BLOCK_EDIT", data });
    },

    /**
     * Edits/Creates a Court Block
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return CourtBlock.edit(data);
    },

    /**
     * Deletes a Court Block
     * @param {Function} dispatch
     * @param {Number}   courtBlockID
     * @returns {Promise}
     */
    deleteElem(dispatch, courtBlockID) {
        return CourtBlock.delete({ courtBlockID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.fromTimeDate = DateTime.formatDate(elem.fromTime, "dashes");
    elem.fromDate     = DateTime.formatDate(elem.fromTime, "dashesReverse");
    elem.toTimeDate   = DateTime.formatIf(elem.toTime, "dashes");
    elem.toDate       = DateTime.formatIf(elem.toTime, "dashesReverse");
    elem.repeatText   = elem.repeatsWeekly ? NLS.format("COURT_BLOCKS_REPEATS_TEXT", DateTime.dayToName(elem.repeatsDay), elem.fromHour, elem.toHour) : "";
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "COURT_BLOCK_LIST", "COURT_BLOCK_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "COURT_BLOCK_LOADING":
        return {
            ...state,
            loading    : true,
        };

    case "COURT_BLOCK_LIST":
        return {
            ...state,
            loading    : false,
            error      : false,
            canCreate  : action.data.canCreate,
            canEdit    : action.data.canEdit,
            canFilter  : action.data.canFilter,
            canImport  : action.data.canImport,
            canExport  : action.data.canExport,
            list       : Utils.parseList(action.data.list, parseElem),
            total      : action.data.total,
            activityID : action.data.activityID,
            filters    : action.data.filters,
            hasFilters : !Utils.isEmpty(action.data.filters),
            sort       : action.data.sort,
        };

    case "COURT_BLOCK_ELEM":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            elem       : parseElem(action.data.elem),
            activities : action.data.activities,
            blockTypes : action.data.blockTypes,
            courts     : action.data.courts,
            statuses   : action.data.statuses,
        };

    case "COURT_BLOCK_EDIT":
        return {
            ...state,
            error      : false,
            edition    : state.edition + 1,
            activities : action.data.activities,
            blockTypes : action.data.blockTypes,
            courts     : action.data.courts,
            statuses   : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
