import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import useMedia             from "Dashboard/Hooks/Media";

// Components
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import EditorField          from "Dashboard/Components/Form/EditorField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Page Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PageEdit(props) {
    const { open, isCopy, elemID, activityID, onClose, onSubmit } = props;

    const { statuses } = Store.useState("page");
    const { editElem } = Store.useAction("page");


    // The Initial Data
    const initialData = {
        pageID     : 0,
        activityID : 0,
        name       : "",
        content    : "",
        status     : "Active",
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await editElem({
            ...data,
            pageID : isCopy ? 0 : (elemID || 0),
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("page", initialData, handleEdit, onSubmit);

    // The Media State
    const {
        showMedia, mediaType, handleMediaOpen, handleMediaSubmit, handleMediaClose,
    } = useMedia();

    // Load the Data
    const { loading } = useDialog("page", open, elemID, { activityID }, setElem);


    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "PAGES_COPY_TITLE";
        }
        if (elemID) {
            return "PAGES_EDIT_TITLE";
        }
        return "PAGES_CREATE_TITLE";
    }, [ isCopy, elemID ]);


    // Do the Render
    return <>
        <EditDialog
            open={open}
            icon="page"
            title={title}
            error={errors.form}
            onSubmit={handleSubmit}
            onClose={onClose}
            isLoading={loading}
            isWide
        >
            <Columns>
                <InputField
                    name="name"
                    label="GENERAL_NAME"
                    value={data.name}
                    error={errors.name}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                    value={data.status}
                    error={errors.status}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
            </Columns>

            <EditorField
                name="content"
                value={data.content}
                error={errors.content}
                onChange={handleChange}
                onMedia={handleMediaOpen}
            />
        </EditDialog>

        <MediaDialog
            open={showMedia}
            mediaType={mediaType}
            onSubmit={handleMediaSubmit}
            onClose={handleMediaClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PageEdit.propTypes = {
    open       : PropTypes.bool.isRequired,
    isCopy     : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    elemID     : PropTypes.number,
    activityID : PropTypes.number,
};

export default PageEdit;
