import React                from "react";
import PropTypes            from "prop-types";
import MediaType            from "Dashboard/Core/MediaType";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Balance Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function BalanceEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { statuses } = Store.useState("balance");
    const { editElem } = Store.useAction("balance");


    // The Initial Data
    const initialData = {
        balanceID : 0,
        name      : "",
        file      : "",
        status    : "Active",
        position  : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("balance", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("balance", open, elemID, {}, setElem);


    // Do the Render
    return <EditDialog
        open={open}
        icon="balance"
        title={elemID ? "BALANCES_EDIT_TITLE" : "BALANCES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <MediaField
            name="file"
            label="GENERAL_FILE"
            value={data.file}
            error={errors.file}
            onChange={handleChange}
            mediaType={MediaType.PDF}
            isRequired
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
BalanceEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default BalanceEdit;
