import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { ShiftCourse }      from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canFilter : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    statuses  : [],
    sort      : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "SHIFT_COURSE_LOADING" });
    },

    /**
     * Fetches the Shift Course List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "ACTIVITY") {
            params.activityID = elemID;
        }

        const data = await ShiftCourse.getAll(params);
        data.sort = params;
        dispatch({ type : "SHIFT_COURSE_LIST", data });
    },

    /**
     * Fetches a single Shift Course
     * @param {Function} dispatch
     * @param {Number}   shiftCourseID
     * @returns {Promise}
     */
    async fetchElem(dispatch, shiftCourseID) {
        const data = await ShiftCourse.getOne({ shiftCourseID });
        dispatch({ type : "SHIFT_COURSE_ELEM", data });
    },

    /**
     * Fetches the Shift Course Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await ShiftCourse.getEditData();
        dispatch({ type : "SHIFT_COURSE_EDIT", data });
    },

    /**
     * Edits/Creates a Shift Course
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return ShiftCourse.edit(data);
    },

    /**
     * Deletes a Shift Course
     * @param {Function} dispatch
     * @param {Number}   shiftCourseID
     * @returns {Promise}
     */
    deleteElem(dispatch, shiftCourseID) {
        return ShiftCourse.delete({ shiftCourseID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.isPastText = Utils.toYesNo(elem.isPast);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "SHIFT_COURSE_LIST", "SHIFT_COURSE_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "SHIFT_COURSE_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "SHIFT_COURSE_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canFilter : action.data.canFilter,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            sort      : action.data.sort,
        };

    case "SHIFT_COURSE_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
            statuses  : action.data.statuses,
        };

    case "SHIFT_COURSE_EDIT":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            statuses  : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
