import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import ShiftEdit            from "./ShiftEdit";

// Dashboard
import Filter               from "Dashboard/Components/Filter/Filter";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Shift Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ShiftContent(props) {
    const { type, isCreating, setCreating } = props;
    const { fetch, load, loadFilter } = useList("shift", type);

    const { activityID } = Navigate.useParams();

    const data = Store.useState("shift");
    const { loading, canEdit, list, total, statuses, filters, sort } = data;

    const { deleteElem } = Store.useAction("shift");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Open the Create Dialog
    React.useEffect(() => {
        if (isCreating) {
            startAction("CREATE");
        }
    }, [ isCreating ]);

    // Handles the Edit Submit
    const handleEdit = () => {
        handleClose();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        handleClose();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Close
    const handleClose = () => {
        setCreating(false);
        endAction();
    };

    // Returns true if the Edit is hidden
    const hideEdit = (elemID) => {
        return !Utils.getValue(list, "shiftID", elemID, "canEdit");
    };


    // Do the Render
    const elemName = Utils.getValue(list, "shiftID", elemID, "name");

    return <>
        <Filter
            onFilter={loadFilter}
            values={filters}
            hasSearch
        >
            <FilterItem
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
            />
        </Filter>

        <Table
            fetch={load}
            sort={sort}
            none="SHIFTS_NONE_AVAILABLE"
            isLoading={loading}
            hasTabs
            hasFilter
        >
            <TableHead>
                <TableHeader field="name"               message="GENERAL_NAME"           isTitle bigMobile />
                <TableHeader field="fromTime"           message="GENERAL_DATE"           maxWidth="120" />
                <TableHeader field="scheduleText"       message="SCHEDULES_SINGULAR"     maxWidth="120" noSorting />
                <TableHeader field="availableFromTime"  message="GENERAL_AVAILABLE_FROM" maxWidth="160" />
                <TableHeader field="availableToTime"    message="GENERAL_AVAILABLE_TO"   maxWidth="160" />
                <TableHeader field="totalSubscriptions" message="SUBSCRIPTIONS_NAME"     maxWidth="100" align="center" />
                <TableHeader field="status"             message="GENERAL_STATUS"         maxWidth="100" align="center" />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow key={elem.shiftID} elemID={elem.shiftID}>
                    <TableCell message={elem.name}                  />
                    <TableCell message={elem.fromTimeDate}          />
                    <TableCell message={elem.scheduleText}          />
                    <TableCell message={elem.availableFromDateTime} />
                    <TableCell message={elem.availableToDateTime}   />
                    <TableCell message={elem.subscriptionText}      />
                    <TableCell message={elem.statusName}            textColor={elem.statusColor} />
                </TableRow>)}
            </TableBody>
            <TablePaging total={total} />
            <TableActionList onAction={startAction} canEdit={canEdit}>
                <TableAction action="VIEW"   message="SHIFTS_VIEW_TITLE"   navigate />
                <TableAction action="EDIT"   message="SHIFTS_EDIT_TITLE"   hide={hideEdit} />
                <TableAction action="COPY"   message="SHIFTS_COPY_TITLE"   />
                <TableAction action="DELETE" message="SHIFTS_DELETE_TITLE" />
            </TableActionList>
        </Table>

        <ShiftEdit
            open={action.isVCCE}
            isCopy={action.isCopy}
            elemID={elemID}
            activityID={activityID}
            onSubmit={handleEdit}
            onClose={handleClose}
        />
        <DeleteDialog
            open={action.isDelete}
            title="SHIFTS_DELETE_TITLE"
            message="SHIFTS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={handleClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ShiftContent.propTypes = {
    type        : PropTypes.string.isRequired,
    isCreating  : PropTypes.bool.isRequired,
    setCreating : PropTypes.func.isRequired,
};

export default ShiftContent;
