import React                from "react";

// Components
import TitleList            from "./Title/TitleList";
import FeeList              from "./Fee/FeeList";
import BalanceList          from "./Balance/BalanceList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Administration Container
 * @returns {React.ReactElement}
 */
function AdministrationContainer() {
    return <>
        <SecondaryNav icon="administration" message="ADMINISTRATION_NAME">
            <NavigationList>
                <NavigationItem
                    message="TITLES_NAME"
                    url="TITLES"
                    icon="title"
                />
                <NavigationItem
                    message="FEES_NAME"
                    url="FEES"
                    icon="fee"
                />
                <NavigationItem
                    message="BALANCES_NAME"
                    url="BALANCES"
                    icon="balance"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="TITLES"   component={TitleList}   />
            <AdminRoute url="FEES"     component={FeeList}     />
            <AdminRoute url="BALANCES" component={BalanceList} />
        </Router>
    </>;
}

export default AdministrationContainer;
