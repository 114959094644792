// The App Params
const params = {
    "CODE"                : "code",
    "ACTIVITY"            : "activityID",
    "RESERVE_TYPE"        : "reserveTypeID",
    "COURSE"              : "courseID",
    "COURSE_TAB"          : "courseTab",
    "SHIFT_TAB"           : "shiftTab",
    "SHIFT"               : "shiftID",
    "TRAINING_TAB"        : "trainingTab",
    "TRAINING"            : "trainingID",
    "TRAINING_WHITE_LIST" : "trainingWhiteListID",
    "DOCUMENT_SLUG"       : "documentSlug",
};

export default params;
