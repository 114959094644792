import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Court Block Type Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CourtBlockTypeEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { colors, statuses } = Store.useState("courtBlockType");
    const { editElem } = Store.useAction("courtBlockType");


    // The Initial Data
    const initialData = {
        courtBlockTypeID : 0,
        name             : "",
        priority         : 1,
        color            : "#f2f2f2",
        status           : "Active",
        position         : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("courtBlockType", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("courtBlockType", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="block-type"
        title={elemID ? "COURT_BLOCK_TYPES_EDIT_TITLE" : "COURT_BLOCK_TYPES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="number"
            name="priority"
            label="GENERAL_PRIORITY"
            helperText="COURT_BLOCK_TYPES_PRIORITY_HELP"
            value={data.priority}
            error={errors.priority}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="color"
            name="color"
            label="GENERAL_COLOR"
            options={colors}
            value={data.color}
            error={errors.color}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CourtBlockTypeEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default CourtBlockTypeEdit;
