import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import SubscriptionEdit     from "./SubscriptionEdit";
import PersonView           from "../Person/PersonView";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import Html                 from "Dashboard/Components/Common/Html";



/**
 * The Subscription View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubscriptionView(props) {
    const { open, elemID, onClose } = props;

    const { elem } = Store.useState("courseSubscription");
    const { fetchElem, nullifyElem } = Store.useAction("courseSubscription");


    // The Current State
    const [ update, setUpdate ] = React.useState(false);
    const [ action, itemID, startAction, endAction ] = Action.useActionID();

    // Load the Data
    const { loading } = useDialog("courseSubscription", open, elemID);


    // Handles the Edit Submit
    const handleEdit = () => {
        fetchElem(elemID);
        setUpdate(true);
        endAction();
    };

    // Handles the Nullify
    const handleNullify = async () => {
        const response = await nullifyElem(elemID);
        if (response.success) {
            endAction();
            onClose(true);
        }
    };

    // Handles the Close
    const handleClose = () => {
        endAction();
        onClose(update);
        onClose(true);
    };


    // Do the Render
    return <>
        <ViewDialog
            open={open}
            icon="subscription"
            title="SUBSCRIPTIONS_VIEW_TITLE"
            secondary="GENERAL_EDIT"
            onSecondary={() => startAction("EDIT")}
            tertiary={elem.canEdit ? "GENERAL_NULLIFY" : ""}
            onTertiary={() => startAction("NULLIFY")}
            onClose={handleClose}
            isLoading={loading}
            withSpacing
            isWide
        >
            <Columns amount={5}>
                <ViewField
                    label="GENERAL_CREATED_AT_FEM"
                    value={elem.createdTimeText}
                />
                <ViewField
                    label="GENERAL_NUMBER"
                    value={elem.numberText}
                />
                <ViewField
                    label="GENERAL_MEMBER_NUMBER"
                    value={elem.memberNumber}
                />
                <ViewField
                    label="GENERAL_MEMBER"
                    value={elem.memberName}
                />
                <ViewField
                    label="GENERAL_STATUS"
                    value={elem.statusName}
                    textColor={elem.statusColor}
                />
            </Columns>

            <Table noSorting notFixed>
                <TableHead>
                    <TableHeader field="personType"      message="GENERAL_TYPE"             maxWidth="120" />
                    <TableHeader field="name"            message="COURSES_PERSONS_SINGULAR" isTitle />
                    <TableHeader field="document"        message="GENERAL_DOCUMENT_VALUE"   />
                    <TableHeader field="birthDate"       message="GENERAL_BIRTH"            maxWidth="100" />
                    <TableHeader field="age"             message="GENERAL_AGE"              maxWidth="60" align="center" />
                    <TableHeader field="status"          message="GENERAL_STATUS"           maxWidth="100" />
                    <TableHeader field="courseGroupName" message="COURSES_GROUP"            />
                </TableHead>
                <TableBody>
                    {elem.persons.map((item) => <TableRow key={item.coursePersonID} elemID={item.coursePersonID}>
                        <TableCell><Html>{item.personTypeName}</Html></TableCell>
                        <TableCell message={item.name}            />
                        <TableCell message={item.document}        />
                        <TableCell message={item.birthDate}       />
                        <TableCell message={item.age}             />
                        <TableCell message={item.statusName}      textColor={item.statusColor} />
                        <TableCell message={item.courseGroupName} />
                    </TableRow>)}
                </TableBody>
                <TableActionList onAction={startAction} canEdit>
                    <TableAction action="VIEW" message="COURSES_PERSONS_VIEW_TITLE" />
                </TableActionList>
            </Table>
        </ViewDialog>

        <PersonView
            open={action.isView}
            elemID={itemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <SubscriptionEdit
            open={action.isEdit}
            elemID={elemID}
            courseID={elem.courseID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isNullify}
            icon="nullify"
            title="SUBSCRIPTIONS_NULLIFY_TITLE"
            message="SUBSCRIPTIONS_NULLIFY_TEXT"
            primary="GENERAL_NULLIFY"
            onSubmit={handleNullify}
            onClose={endAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubscriptionView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default SubscriptionView;
