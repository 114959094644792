import React                from "react";

// Components
import TicketList           from "./Ticket/TicketList";
import AreaList             from "./Area/AreaList";
import DepartmentList       from "./Department/DepartmentList";
import SubjectList          from "./Subject/SubjectList";
import WaitlistList         from "./Waitlist/WaitlistList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Ticket Container
 * @returns {React.ReactElement}
 */
function TicketContainer() {
    return <>
        <SecondaryNav icon="ticket" message="TICKETS_SINGULAR">
            <NavigationList>
                <NavigationItem
                    message="TICKETS_NAME"
                    url="TICKETS"
                    icon="contact"
                />
            </NavigationList>
            <NavigationList>
                <NavigationItem
                    message="TICKET_AREAS_NAME"
                    url="AREAS"
                    icon="area"
                />
                <NavigationItem
                    message="TICKET_DEPARTMENTS_NAME"
                    url="DEPARTMENTS"
                    icon="department"
                />
                <NavigationItem
                    message="TICKET_SUBJECTS_NAME"
                    url="SUBJECTS"
                    icon="subject"
                />
                <NavigationItem
                    message="TICKET_WAITLISTS_NAME"
                    url="WAITLISTS"
                    icon="waitlist"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="TICKETS"     component={TicketList}     />
            <AdminRoute url="AREAS"       component={AreaList}       />
            <AdminRoute url="DEPARTMENTS" component={DepartmentList} />
            <AdminRoute url="SUBJECTS"    component={SubjectList}    />
            <AdminRoute url="WAITLISTS"   component={WaitlistList}   />
        </Router>
    </>;
}

export default TicketContainer;
