import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Button Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ButtonEdit(props) {
    const { open, isCopy, elemID, activityID, onClose, onSubmit } = props;

    const { buttonTypes, pages, statuses } = Store.useState("button");
    const { editElem } = Store.useAction("button");


    // The Initial Data
    const initialData = {
        buttonID   : 0,
        activityID : 0,
        pageID     : 0,
        buttonType : "",
        name       : "",
        url        : "",
        path       : "",
        status     : "Active",
        position   : 0,
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await editElem({
            ...data,
            buttonID : isCopy ? 0 : (elemID || 0),
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("button", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("button", open, elemID, { activityID }, setElem);


    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "BUTTONS_COPY_TITLE";
        }
        if (elemID) {
            return "BUTTONS_EDIT_TITLE";
        }
        return "BUTTONS_CREATE_TITLE";
    }, [ isCopy, elemID ]);


    // Do the Render
    return <EditDialog
        open={open}
        icon="button"
        title={title}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="buttonType"
            label="BUTTONS_TYPE"
            options={buttonTypes}
            value={data.buttonType}
            error={errors.buttonType}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={data.buttonType !== "Url"}
            name="url"
            label="GENERAL_URL"
            value={data.url}
            error={errors.url}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={data.buttonType !== "Route"}
            name="path"
            label="BUTTONS_ROUTE"
            value={data.path}
            error={errors.path}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={data.buttonType !== "Page"}
            type="select"
            name="pageID"
            label="PAGES_SINGULAR"
            options={pages}
            value={data.pageID}
            error={errors.pageID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ButtonEdit.propTypes = {
    open       : PropTypes.bool.isRequired,
    isCopy     : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    elemID     : PropTypes.number,
    activityID : PropTypes.number,
};

export default ButtonEdit;
