import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import { Member }           from "Utils/API";

// Dashboard
import Avatar               from "Dashboard/Components/Avatar/Avatar";
import Html                 from "Dashboard/Components/Common/Html";
import IconLink             from "Dashboard/Components/Link/IconLink";
import HyperLink            from "Dashboard/Components/Link/HyperLink";
import Menu                 from "Dashboard/Components/Menu/Menu";
import MenuItem             from "Dashboard/Components/Menu/MenuItem";



// Styles
const Message = Styled.section`
    position: relative;
    display: flex;
    padding: 16px 24px;
    gap: 16px;
    color: var(--black-color);

    &:not(:last-child) {
        border-bottom: 1px solid var(--border-color-light);
    }
`;

const Photo = Styled(Avatar)`
    flex-shrink: 0;
`;

const Content = Styled.div`
    flex-grow: 2;
`;

const File = Styled(HyperLink)`
    display: inline-block;
    margin-top: 8px;
    padding: 8px;
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Image = Styled.img`
    display: block;
    max-width: 200px;
    border-radius: var(--border-radius);
`;

const Header = Styled.header`
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    color: var(--title-color);
`;

const Title = Styled.h3`
    margin: 0 16px 0 0;
    font-size: 14px;
`;

const Subtitle = Styled.h4`
    margin: 0;
    color: var(--font-dark);
    font-size: 11px;
    font-weight: 400;
`;

const IconMore = Styled(IconLink)`
    position: absolute;
    top: 12px;
    right: 16px;
`;



/**
 * The Ticket Message
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TicketMessage(props) {
    const { elem, startAction } = props;


    // The References
    const targetRef = React.useRef(null);

    // The Current State
    const [ showMenu, setShowMenu ] = React.useState(false);


    // Handles the Menu Open
    const openMenu = (e) => {
        setShowMenu(true);
        e.preventDefault();
    };

    // Handles the Menu Close
    const closeMenu = () => {
        setShowMenu(false);
    };

    // Handles the Menu Action
    const handleAction = (action) => {
        startAction(action, elem);
    };


    // Do the Render
    return <Message>
        <Photo
            name={elem.name}
            avatar={elem.credentialID ? elem.avatar : Member.getAvatar({ memberNumber : elem.memberNumber })}
            size={32}
        />
        <Content>
            <Header>
                <Title>{elem.fullName}</Title>
                <Subtitle>{elem.createdString}</Subtitle>
            </Header>
            <Html addLinks>{elem.messageHtml}</Html>
            {elem.file && <File
                variant="opacity"
                href={elem.file}
                target="_blank"
            >
                {elem.hasImage ? <Image
                    src={elem.file}
                    alt={String(elem.ticketMessageID)}
                /> : NLS.format("GENERAL_VIEW_FILE", elem.extension)}
            </File>}
        </Content>

        {elem.canEdit && <>
            <IconMore
                passedRef={targetRef}
                variant="light"
                icon="more"
                onClick={openMenu}
                dontStop
                isSmall
            />
            <Menu
                open={showMenu}
                targetRef={targetRef}
                direction="bottom left"
                onAction={handleAction}
                onClose={closeMenu}
                gap={4}
            >
                <MenuItem
                    action="EDIT"
                    message="TICKETS_EDIT_MESSAGE"
                />
                <MenuItem
                    action="FILE"
                    message="TICKETS_DELETE_FILE"
                    isHidden={!elem.file}
                />
                <MenuItem
                    action="DELETE"
                    message="TICKETS_DELETE_MESSAGE"
                />
            </Menu>
        </>}
    </Message>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TicketMessage.propTypes = {
    startAction : PropTypes.func.isRequired,
    elem        : PropTypes.object.isRequired,
};

export default TicketMessage;
