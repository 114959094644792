import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import MediaType            from "Dashboard/Core/MediaType";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import DropZone             from "Dashboard/Components/Media/DropZone";
import MediaList            from "Dashboard/Components/Media/MediaList";
import PromptDialog         from "Dashboard/Components/Dialogs/PromptDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Media Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MediaContent(props) {
    const {
        mediaType, path, initialSelect,
        canSelect, onSelect,
        inDialog, create, endCreate,
    } = props;

    const data = Store.useState("media");
    const { loading, canCreate, canEdit, list } = data;

    const { fetchList, createElem, uploadElem, renameElem, moveElem, deleteElem } = Store.useAction("media");
    const { showResult } = Store.useAction("core");


    // The Initial Data
    const initialElem = {
        name  : "",
        isDir : false,
    };

    // The Current State
    const [ elem,         setElem         ] = React.useState({ ...initialElem });
    const [ selectedPath, setSelectedPath ] = React.useState(initialSelect);
    const [ action,       setAction       ] = Action.useAction();


    // Load the Data
    React.useEffect(() => {
        fetch(path, initialSelect);
    }, []);

    // Open the Create Dialog
    React.useEffect(() => {
        if (create) {
            startAction("CREATE");
        }
    }, [ create ]);


    // Fetches the content
    const fetch = (path = data.path, selected = "") => {
        return fetchList(mediaType, path, selected);
    };

    // Handles the Upload Submit
    const handleUpload = async (files) => {
        setAction();
        for (const file of files) {
            await uploadElem(data.path, file);
        }
        const newData = await fetch();
        const file    = Utils.getValue(newData.list, "name", files[0].name);
        handleAction(Action.get("SELECT"), file);
    };

    // Handles the Upload Error
    const handleUploadError = (files) => {
        showResult("error", "MEDIA_ERROR_UPLOAD_SIZE" + (files > 1 ? "S" : ""));
    };

    // Handles the Create Submit
    const handleCreate = async (name) => {
        handleCloseCreate();
        const result = await createElem(data.path, name);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Close Create
    const handleCloseCreate = () => {
        setAction();
        if (endCreate) {
            endCreate();
        }
    };

    // Handles the Edit Submit
    const handleEdit = async (newName) => {
        const oldName = elem.name;
        setAction();
        const result = await renameElem(data.path, oldName, newName);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        const name = elem.name;
        setAction();
        const result = await deleteElem(data.path, name);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Move Submit
    const handleMove = async (elem, directory) => {
        const oldPath = elem.mvPath.replace(elem.name, "");
        const result  = await moveElem(oldPath || "/", directory.mvPath, elem.name);
        if (result.success) {
            handleUnselect();
            fetch();
        }
    };


    // Starts an Action
    const startAction = (action, elem = { ...initialElem }) => {
        setAction(action);
        setElem(elem);
    };

    // Handles the Action
    const handleAction = (action, elem) => {
        if (action.isSelect) {
            if (elem.isDir || elem.isBack) {
                fetch(elem.path);
            } else if (canSelect) {
                if (selectedPath !== elem.path) {
                    setSelectedPath(elem.path);
                    selectMedia(elem.path);
                } else {
                    handleUnselect();
                }
            }
        } else if (action.isView && elem.isDir) {
            fetch(elem.path);
        } else if (action.isView) {
            window.open(elem.url);
        } else {
            setAction(action);
            setElem(elem);
            handleUnselect();
        }
    };

    // Handles the Menu Close
    const handleUnselect = () => {
        setSelectedPath("");
        selectMedia();
    };

    // Sends the Selection
    const selectMedia = (file) => {
        if (onSelect) {
            onSelect(file);
        }
    };


    // Do the Render
    return <>
        <DropZone
            isHidden={!canCreate}
            onDrop={handleUpload}
            onError={handleUploadError}
            onlyImages={MediaType.onlyImages(mediaType)}
            maxSize={process.env.REACT_APP_MAX_SIZE}
        />
        <MediaList
            onAction={handleAction}
            onDrop={handleMove}
            isLoading={loading}
            canEdit={canEdit}
            canDrag={canEdit}
            canSelect={canSelect}
            inDialog={inDialog}
            selectedPath={selectedPath}
            path={data.path || "/"}
            items={list}
        />

        <PromptDialog
            open={action.isCreate}
            icon="directory"
            title="MEDIA_CREATE_TITLE"
            inputLabel="GENERAL_NAME"
            onSubmit={handleCreate}
            onClose={handleCloseCreate}
        />
        <PromptDialog
            open={action.isEdit}
            icon="edit"
            title={elem.isDir ? "MEDIA_EDIT_DIR_TITLE" : "MEDIA_EDIT_FILE_TITLE"}
            inputLabel="GENERAL_NAME"
            initialValue={elem.name}
            spellCheck="false"
            onSubmit={handleEdit}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title={elem.isDir ? "MEDIA_DELETE_DIR_TITLE" : "MEDIA_DELETE_FILE_TITLE"}
            message={elem.isDir ? "MEDIA_DELETE_DIR_TEXT" : "MEDIA_DELETE_FILE_TEXT"}
            content={elem.name}
            onSubmit={handleDelete}
            onClose={setAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MediaContent.propTypes = {
    path          : PropTypes.string,
    mediaType     : PropTypes.string,
    onSelect      : PropTypes.func,
    canSelect     : PropTypes.bool,
    initialSelect : PropTypes.string,
    inDialog      : PropTypes.bool,
    create        : PropTypes.bool,
    endCreate     : PropTypes.func,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
MediaContent.defaultProps = {
    mediaType     : MediaType.ANY,
    path          : "",
    initialSelect : "",
    create        : false,
};

export default MediaContent;
