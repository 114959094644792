import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Subject Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubjectEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { areas, departments, statuses } = Store.useState("ticketSubject");
    const { editElem } = Store.useAction("ticketSubject");


    // The Initial Data
    const initialData = {
        ticketSubjectID    : 0,
        ticketAreaID       : 0,
        ticketDepartmentID : 0,
        name               : "",
        message            : "",
        status             : "Active",
        position           : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("ticketSubject", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("ticketSubject", open, elemID, {}, setElem);


    // Do the Render
    return <EditDialog
        open={open}
        icon="subject"
        title={elemID ? "TICKET_SUBJECTS_EDIT_TITLE" : "TICKET_SUBJECTS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="ticketAreaID"
            label="TICKET_AREAS_NAME"
            options={areas}
            value={data.ticketAreaID}
            error={errors.ticketAreaID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="ticketDepartmentID"
            label="TICKET_DEPARTMENTS_NAME"
            options={departments[data.ticketAreaID] || []}
            value={data.ticketDepartmentID}
            error={errors.ticketDepartmentID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="textarea"
            name="message"
            label="GENERAL_MESSAGE"
            value={data.message}
            error={errors.message}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubjectEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default SubjectEdit;
