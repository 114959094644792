import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import useMedia             from "Dashboard/Hooks/Media";

// Components
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import EditorField          from "Dashboard/Components/Form/EditorField";



/**
 * The Training Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TrainingEdit(props) {
    const { open, isCopy, elemID, activityID, onClose, onSubmit } = props;

    const { whiteLists, statuses } = Store.useState("training");
    const { editElem } = Store.useAction("training");


    // The Current State
    const [ tab, setTab ] = React.useState("info");

    // The Initial Data
    const initialData = {
        trainingID          : 0,
        trainingWhiteListID : 0,
        activityID          : 0,
        name                : "",
        fromDate            : "",
        toDate              : "",
        content             : "",
        emailContent        : "",
        status              : "Active",
    };

    // Handles the Set
    const handleSet = (elem) => {
        setTab("info");
        setElem(elem);
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await editElem({
            ...data,
            trainingID : isCopy ? 0 : (elemID || 0),
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("training", initialData, handleEdit, onSubmit);

    // The Media State
    const {
        showMedia, mediaType, handleMediaOpen, handleMediaSubmit, handleMediaClose,
    } = useMedia();

    // Load the Data
    const { loading } = useDialog("training", open, elemID, { activityID }, handleSet);


    // Returns the Error Count in the Step
    const getErrorCount = (tab) => {
        if (tab === "info") {
            return Object.values(errors).filter((error) => !!error).length;
        }
        return 0;
    };

    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "TRAININGS_COPY_TITLE";
        }
        if (elemID) {
            return "TRAININGS_EDIT_TITLE";
        }
        return "TRAININGS_CREATE_TITLE";
    }, [ isCopy, elemID ]);


    // Do the Render
    return <>
        <EditDialog
            open={open}
            icon="training"
            title={title}
            error={errors.form}
            onSubmit={handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <TabList selected={tab} onClick={setTab} inDialog>
                <TabItem message="GENERAL_INFO"    value="info"    badge={getErrorCount("info")}    />
                <TabItem message="GENERAL_CONTENT" value="content" badge={getErrorCount("content")} />
                <TabItem message="GENERAL_EMAIL"   value="email"   badge={getErrorCount("email")}   />
            </TabList>

            <Columns isHidden={tab !== "info"} amount="1">
                <InputField
                    name="name"
                    label="GENERAL_NAME"
                    value={data.name}
                    error={errors.name}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="date"
                    name="fromDate"
                    label="GENERAL_FROM_DATE"
                    value={data.fromDate}
                    error={errors.fromDate}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="date"
                    name="toDate"
                    label="GENERAL_TO_DATE"
                    value={data.toDate}
                    error={errors.toDate}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!whiteLists.length}
                    type="select"
                    name="trainingWhiteListID"
                    label="TRAININGS_WHITE_LISTS_SINGULAR"
                    options={whiteLists}
                    value={data.trainingWhiteListID}
                    error={errors.trainingWhiteListID}
                    onChange={handleChange}
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                    value={data.status}
                    error={errors.status}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
            </Columns>

            <EditorField
                isHidden={tab !== "content"}
                name="content"
                value={data.content}
                error={errors.content}
                onChange={handleChange}
                onMedia={handleMediaOpen}
            />
            <EditorField
                isHidden={tab !== "email"}
                name="emailContent"
                value={data.emailContent}
                error={errors.emailContent}
                onChange={handleChange}
                onMedia={handleMediaOpen}
            />
        </EditDialog>

        <MediaDialog
            open={showMedia}
            mediaType={mediaType}
            onSubmit={handleMediaSubmit}
            onClose={handleMediaClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TrainingEdit.propTypes = {
    open       : PropTypes.bool.isRequired,
    isCopy     : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    elemID     : PropTypes.number,
    activityID : PropTypes.number,
};

export default TrainingEdit;
