import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import useForm              from "Dashboard/Hooks/Form";

// Components
import AuthContainer        from "Components/Utils/Auth/AuthContainer";
import AuthHeader           from "Components/Utils/Auth/AuthHeader";
import AuthForm             from "Components/Utils/Auth/AuthForm";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Change Password Page
 * @returns {React.ReactElement}
 */
function ChangePassPage() {
    const { email      } = Store.useState("auth");
    const { changePass } = Store.useAction("auth");

    const navigate = Navigate.useGoto();


    // The Initial Data
    const initialData = {
        email           : "",
        oldPassword     : "",
        newPassword     : "",
        confirmPassword : "",
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        data.email = email;
        await changePass(data);
        navigate("LOGIN");
    };

    // The Form State
    const {
        loading, data, errors, handleChange, handleSubmit,
    } = useForm("changePass", initialData, handleEdit, null, false);



    // Do the Render
    return <AuthContainer>
        <AuthHeader
            title="CHANGE_TITLE"
            help="CHANGE_HELP"
        />
        <AuthForm
            url="LOGIN"
            link="GENERAL_CANCEL"
            button="CHANGE_BUTTON"
            error={errors.form}
            onSubmit={handleSubmit}
            isLoading={loading}
        >
            <InputField
                type="password"
                name="oldPassword"
                label="CHANGE_OLD_PASSWORD"
                autoComplete="new-password"
                value={data.oldPassword}
                error={errors.oldPassword}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="password"
                name="newPassword"
                label="CHANGE_NEW_PASSWORD"
                autoComplete="new-password"
                value={data.newPassword}
                error={errors.newPassword}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="password"
                name="confirmPassword"
                label="GENERAL_PASSWORD_CONFIRM"
                autoComplete="new-password"
                value={data.confirmPassword}
                error={errors.confirmPassword}
                onChange={handleChange}
                isRequired
            />
        </AuthForm>
    </AuthContainer>;
}

export default ChangePassPage;
