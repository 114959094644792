import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import CalendarPage         from "Components/App/Reserve/Calendar/CalendarPage";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import APIRoute             from "Dashboard/Components/Route/APIRoute";



/**
 * The API Router
 * @returns {React.ReactElement}
 */
function APIRouter() {
    const { isAPI } = Store.useState("auth");


    // Do the Render
    if (!isAPI) {
        return <React.Fragment />;
    }
    return <Router type="API">
        <APIRoute
            url="RESERVE"
            component={CalendarPage}
        />
        <APIRoute
            url="RESERVE_KIOSK"
            component={CalendarPage}
        />
    </Router>;
}

export default APIRouter;
