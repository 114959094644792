/* spell-checker: ignore configuracion, codigo, categorias, administracion, titulos */
const urls = {

    // General Urls
    NONE                : "",
    CATEGORIES          : "categorias",
    SETTINGS            : "configuracion",

    // Auth Urls
    LOGIN               : "ingresar",
    PASS_RECOVER        : "recuperar",
    PASS_CODE           : "codigo",
    PASS_RESET          : "resetear",
    PASS_RESET_CODE     : "resetear/:code",
    PASS_CHANGE         : "cambiar",

    // Profile Urls
    PROFILE             : "perfil",

    // Entry Urls
    ENTRIES             : "entradas",
    NEWS                : "novedades",
    BILLBOARD           : "cartelera",


    // Activity Urls
    ACTIVITIES          : "actividades",
    ACTIVITY            : "actividades/:activityID",
    ACTIVITY_RESERVE    : "actividades/:activityID/tipos-reserva/:reserveTypeID",
    BUTTONS             : "botones",
    PAGES               : "paginas",

    // Activity: Course Urls
    COURSES             : "cursos",
    COURSE              : "cursos/:courseID",
    COURSE_TAB          : "cursos/:courseID/:courseTab",
    SUBSCRIPTIONS       : "suscripciones",
    PERSONS             : "personas",

    // Activity: Shift Urls
    SHIFT_PAGE          : "turno",
    SHIFT_TAB           : "turno/:shiftTab",
    SHIFTS              : "turnos",
    SHIFT               : "turno/turnos/:shiftID",

    // Activity: Training Urls
    TRAINING_PAGE       : "entrenamiento",
    TRAINING_TAB        : "entrenamiento/:trainingTab",
    TRAININGS           : "entrenamientos",
    TRAINING            : "entrenamiento/entrenamientos/:trainingID",
    TRAINING_WHITE_LIST : "entrenamiento/lista-blanca/:trainingWhiteListID",
    WHITE_LIST          : "lista-blanca",


    // Reserve Urls
    RESERVE             : "reserva",
    RESERVE_KIOSK       : "reserva/:reserveTypeID",
    RESERVE_TYPE        : "reserva/tipos-reserva/:reserveTypeID",
    RESERVE_TYPES       : "tipos-reserva",
    CALENDAR            : "calendario",
    RESERVES            : "reservas",
    COURTS              : "canchas",
    COURT_BLOCKS        : "bloqueos-canchas",
    COURT_BLOCK_TYPES   : "tipos-bloqueos",

    // Schedule Urls
    SCHEDULE            : "horario",
    SCHEDULES           : "horarios",
    HOLIDAYS            : "feriados",

    // Administration Urls
    ADMINISTRATION      : "administracion",
    TITLES              : "titulos",
    FEES                : "tarifas",
    BALANCES            : "balances",

    // Contact Urls
    CONTACT             : "contacto",
    CONTACTS            : "contactos",
    SOCIALS             : "sociales",

    // Ticket Urls
    TICKET              : "gestion",
    TICKETS             : "gestiones",
    AREAS               : "areas",
    DEPARTMENTS         : "departamentos",
    SUBJECTS            : "temas",
    WAITLISTS           : "listas-espera",

    // Member Urls
    MEMBER              : "socio",
    MEMBERS             : "socios",
    STATES              : "estados",
    RELATIONS           : "relaciones",
    PROVINCES           : "provincias",

    // Document Urls
    DOCUMENTS           : "documentos",
    DOCUMENT            : "documentos/:documentSlug",

    // Setup Urls
    ADMINS              : "admins",
    VERSIONS            : "versiones",
    MEDIA               : "archivos",
    NOTIFICATION_TYPES  : "tipos-notificaciones",
    NOTIFICATIONS       : "notificaciones",

    // Email Urls
    EMAIL_TEMPLATES     : "plantillas-emails",
    EMAIL_QUEUE         : "cola-emails",
    EMAIL_WHITE_LIST    : "emails-blancos",

    // Log Urls
    LOG_PROVIDERS       : "log-proveedores",
    LOG_ACTIONS         : "log-acciones",
    LOG_ERRORS          : "log-errores",

};

export default urls;
