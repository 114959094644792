import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";

// Components
import ReserveTypeEdit      from "./ReserveType/ReserveTypeEdit";
import CalendarPage         from "./Calendar/CalendarPage";
import ReserveList          from "./Reserve/ReserveList";
import CourtList            from "./Court/CourtList";
import CourtBlockList       from "./CourtBlock/CourtBlockList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Reserve Type Container
 * @returns {React.ReactElement}
 */
function ReserveTypeContainer() {
    const { reserveTypeID } = Navigate.useParams();

    const { closeMenu } = Store.useAction("core");

    const { elem } = Store.useState("reserveType");
    const { fetchElem } = Store.useAction("reserveType");

    // The Current State
    const [ action, setAction ] = Action.useAction();



    // Load the Data
    React.useEffect(() => {
        fetchElem(reserveTypeID);
    }, [ reserveTypeID ]);

    // Handles the Action
    const handleAction = (action) => {
        if (action.isCE) {
            closeMenu();
            setAction(action);
        }
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        fetchElem(reserveTypeID);
        setAction();
    };


    // Do the Render
    return <>
        <SecondaryNav
            message={elem.name}
            fallback="RESERVE_TYPES_SINGULAR"
            href={Navigate.useFrom()}
            onAction={handleAction}
            canEdit
        >
            <NavigationList>
                <NavigationItem
                    message="RESERVE_CALENDAR_NAME"
                    url="CALENDAR"
                    icon="calendar"
                />
                <NavigationItem
                    message="RESERVES_NAME"
                    url="RESERVES"
                    icon="reserve"
                />
            </NavigationList>
            <NavigationList>
                <NavigationItem
                    message="COURTS_NAME"
                    url="COURTS"
                    icon="court"
                />
                <NavigationItem
                    message="COURT_BLOCKS_NAME"
                    url="COURT_BLOCKS"
                    icon="court-block"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="RESERVE_TYPE">
            <AdminRoute url="CALENDAR"     component={CalendarPage}   />
            <AdminRoute url="RESERVES"     component={ReserveList}    />
            <AdminRoute url="COURTS"       component={CourtList}      />
            <AdminRoute url="COURT_BLOCKS" component={CourtBlockList} />
        </Router>

        <ReserveTypeEdit
            open={action.isEdit}
            elemID={reserveTypeID}
            onSubmit={handleEdit}
            onClose={setAction}
        />
    </>;
}

export default ReserveTypeContainer;
