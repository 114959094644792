import React                from "react";

// Components
import ReserveTypeList      from "./ReserveType/ReserveTypeList";
import ReserveList          from "./Reserve/ReserveList";
import CourtList            from "./Court/CourtList";
import CourtBlockList       from "./CourtBlock/CourtBlockList";
import CourtBlockTypeList   from "./CourtBlockType/CourtBlockTypeList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Reserve Container
 * @returns {React.ReactElement}
 */
function ReserveContainer() {
    return <>
        <SecondaryNav icon="calendar" message="RESERVES_NAME">
            <NavigationList>
                <NavigationItem
                    message="RESERVE_TYPES_NAME"
                    url="RESERVE_TYPES"
                    icon="reserve-type"
                />
                <NavigationItem
                    message="RESERVES_NAME"
                    url="RESERVES"
                    icon="reserve"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="COURTS_NAME"
                    url="COURTS"
                    icon="court"
                />
                <NavigationItem
                    message="COURT_BLOCKS_NAME"
                    url="COURT_BLOCKS"
                    icon="court-block"
                />
                <NavigationItem
                    message="COURT_BLOCK_TYPES_NAME"
                    url="COURT_BLOCK_TYPES"
                    icon="block-type"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="RESERVE_TYPES"     component={ReserveTypeList}    />
            <AdminRoute url="RESERVES"          component={ReserveList}        />
            <AdminRoute url="COURTS"            component={CourtList}          />
            <AdminRoute url="COURT_BLOCKS"      component={CourtBlockList}     />
            <AdminRoute url="COURT_BLOCK_TYPES" component={CourtBlockTypeList} />
        </Router>
    </>;
}

export default ReserveContainer;
