import React                from "react";
import PropTypes            from "prop-types";
import MediaType            from "Dashboard/Core/MediaType";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Schedule Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ScheduleEdit(props) {
    const { open, isCopy, elemID, onClose, onSubmit } = props;

    const { categories, activities, statuses } = Store.useState("schedule");
    const { editElem } = Store.useAction("schedule");


    // The Initial Data
    const initialData = {
        scheduleID          : 0,
        scheduleCategoryID  : 0,
        forNazar            : 0,
        activityID          : 0,
        name                : "",
        image               : "",
        description         : "",
        timeTables          : "[{}]",
        closedDays          : "[]",
        showAsNoService     : 0,
        status              : "Active",
        position            : 0,
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({
            ...elem,
            schedules  : !isCopy && elemID ? elemID : 0,
            timeTables : elem?.timeTables?.length ? JSON.stringify(elem.timeTables) : "[{}]",
            closedDays : elem?.closedDays?.length ? JSON.stringify(elem.closedDays) : "[]",
            position   : isCopy ? 0 : elem.position,
        });
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await editElem({
            ...data,
            scheduleID : isCopy ? 0 : (elemID || 0),
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("schedule", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("schedule", open, elemID, {}, handleSet);


    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "SCHEDULES_COPY_TITLE";
        }
        if (elemID) {
            return "SCHEDULES_EDIT_TITLE";
        }
        return "SCHEDULES_CREATE_TITLE";
    }, [ isCopy, elemID ]);


    // Do the Render
    return <EditDialog
        open={open}
        icon="schedule"
        title={title}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />

        <Columns>
            <InputField
                type="select"
                name="scheduleCategoryID"
                label="CATEGORIES_SINGULAR"
                options={categories}
                value={data.scheduleCategoryID}
                error={errors.scheduleCategoryID}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="toggle"
                name="forNazar"
                label="SCHEDULES_FOR_NAZAR"
                value={data.forNazar}
                error={errors.forNazar}
                onChange={handleChange}
                withBorder
            />

            <InputField
                type="select"
                name="activityID"
                label="ACTIVITIES_SINGULAR"
                options={activities}
                value={data.activityID}
                error={errors.activityID}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="textarea"
            name="description"
            label="GENERAL_DESCRIPTION"
            value={data.description}
            error={errors.description}
            onChange={handleChange}
        />
        <MediaField
            name="image"
            label="GENERAL_IMAGE"
            value={data.image}
            error={errors.image}
            onChange={handleChange}
            mediaType={MediaType.IMAGE}
            onlyImages
        />

        <InputField
            type="fields"
            name="timeTables"
            title="GENERAL_PERIOD_TITLE"
            addButton="GENERAL_ADD_PERIOD"
            value={data.timeTables}
            errors={errors}
            onChange={handleChange}
            columns="2"
            isSortable
        >
            <InputItem
                type="chooser"
                name="days"
                label="GENERAL_DAYS"
                options="DATE_DAY_HOLIDAY_NAMES_MONDAY"
                columns="2"
                isRequired
            />
            <InputItem
                type="time"
                name="from"
                label="GENERAL_FROM"
                isRequired
            />
            <InputItem
                type="time"
                name="to"
                label="GENERAL_TO"
                isRequired
            />
        </InputField>

        <InputField
            type="list"
            name="closedDays"
            label="SCHEDULES_CLOSED_DAYS"
            addButton="GENERAL_ADD_DAY"
            inputType="date"
            value={data.closedDays}
            errors={errors}
            onChange={handleChange}
            isSortable
        />

        <InputField
            type="toggle"
            name="showAsNoService"
            label="SCHEDULES_SHOW_AS_NO_SERVICE"
            value={data.showAsNoService}
            error={errors.showAsNoService}
            onChange={handleChange}
            withBorder
        />

        <Columns>
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
                value={data.status}
                error={errors.status}
                onChange={handleChange}
                hideClear
                isRequired
            />
            <InputField
                type="number"
                name="position"
                label="GENERAL_POSITION"
                value={data.position}
                error={errors.position}
                onChange={handleChange}
                minValue={0}
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ScheduleEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    isCopy   : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default ScheduleEdit;
