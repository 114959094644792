import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import ReserveEdit          from "./ReserveEdit";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableRow             from "Dashboard/Components/Table/TableRow";



/**
 * The Reserve View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReserveView(props) {
    const { open, elemID, reserveTypeID, onClose } = props;

    const { elem } = Store.useState("reserve");
    const { fetchElem, nullifyElem } = Store.useAction("reserve");


    // The Current State
    const [ showEdit,    setShowEdit    ] = React.useState(false);
    const [ showNullify, setShowNullify ] = React.useState(false);
    const [ update,      setUpdate      ] = React.useState(false);

    // Load the Data
    const { loading } = useDialog("reserve", open, elemID);


    // Handles the Edit Submit
    const handleEdit = () => {
        fetchElem(elemID);
        setShowEdit(false);
        setUpdate(true);
    };

    // Handles the Nullify
    const handleNullify = async () => {
        const response = await nullifyElem(elemID);
        if (response.success) {
            setShowNullify(false);
            onClose(true);
        }
    };

    // Handles the Close
    const handleClose = () => {
        setShowEdit(false);
        setShowNullify(false);
        onClose(update);
    };


    // Get the Players
    const players = React.useMemo(() => {
        const result = elem.players.length ? [ ...elem.players ] : [];
        if (elem.memberNumber) {
            result.unshift({
                reservePlayerID : 0,
                playerTypeName  : NLS.get("SELECT_PLAYER_TYPES", "Member"),
                playerName      : `${elem.memberNumber} - ${elem.memberName}`,
                properties      : "",
            });
        }
        return result;
    }, [ elem.memberNumber, JSON.stringify(elem.players) ]);


    // Do the Render
    const showType = Boolean(!elemID && !reserveTypeID);

    return <>
        <ViewDialog
            open={open}
            icon="reserve"
            title="RESERVES_VIEW_TITLE"
            secondary={elem.canEdit ? "GENERAL_EDIT" : ""}
            onSecondary={() => setShowEdit(true)}
            tertiary={elem.canEdit ? "GENERAL_NULLIFY" : ""}
            onTertiary={() => setShowNullify(true)}
            onClose={handleClose}
            isLoading={loading}
            withSpacing
        >
            <Columns amount={3}>
                <ViewField
                    isHidden={!showType}
                    label="RESERVE_TYPES_SINGULAR"
                    value={elem.reserveTypeName}
                />
                <ViewField
                    label="RESERVES_DATE"
                    value={elem.date}
                />
                <ViewField
                    label="RESERVES_HOUR"
                    value={elem.hour}
                />
                <ViewField
                    label="RESERVES_DURATION"
                    value={elem.duration}
                />
                <ViewField
                    label="COURTS_SINGULAR"
                    value={elem.courtName}
                />
                <ViewField
                    label="RESERVES_MATCH_TYPE"
                    value={elem.matchTypeName}
                />
                <ViewField
                    label="GENERAL_STATUS"
                    value={elem.statusName}
                    textColor={elem.statusColor}
                />
            </Columns>
            <ViewField
                label="GENERAL_COMMENTS"
                value={elem.comments}
            />

            <Table noSorting notFixed>
                <TableHead>
                    <TableHeader field="playerType" message="GENERAL_TYPE"    maxWidth="60" />
                    <TableHeader field="fullName"   message="RESERVES_PLAYER" isTitle />
                    <TableHeader field="properties" message=""                />
                </TableHead>
                <TableBody>
                    {players.map((item) => <TableRow key={item.reservePlayerID}>
                        <TableCell message={item.playerTypeName} />
                        <TableCell message={item.playerName}     />
                        <TableCell message={item.properties}     />
                    </TableRow>)}
                </TableBody>
            </Table>
        </ViewDialog>

        <ReserveEdit
            open={showEdit}
            elemID={elemID}
            reserveTypeID={reserveTypeID}
            date={elem.date || ""}
            onSubmit={handleEdit}
            onClose={() => setShowEdit(false)}
        />
        <ConfirmDialog
            open={showNullify}
            icon="nullify"
            title="RESERVES_NULLIFY_TITLE"
            message="RESERVES_NULLIFY_TEXT"
            primary="GENERAL_NULLIFY"
            onSubmit={handleNullify}
            onClose={() => setShowNullify(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReserveView.propTypes = {
    open          : PropTypes.bool.isRequired,
    onClose       : PropTypes.func.isRequired,
    reserveTypeID : PropTypes.number.isRequired,
    elemID        : PropTypes.number,
};

export default ReserveView;
