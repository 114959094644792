import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";
import { Member }           from "Utils/API";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Reserve Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReserveFilter(props) {
    const { open, filters, reserveTypeID, onClose, onSubmit } = props;

    const { reserveTypes, courts, statuses } = Store.useState("reserve");


    // The Initial Data
    const initialData = {
        period       : 0,
        fromDate     : "",
        toDate       : "",
        reserveType  : reserveTypeID,
        courtID      : [],
        matchTypes   : [],
        memberNumber : "",
        memberName   : "",
        status       : [],
    };

    // Handles the Update
    const handleUpdate = async (name, value) => {
        if (name === "reserveType") {
            setData({
                reserveType : value,
                courtID     : [],
                matchTypes  : [],
            });
        } else {
            handleChange(name, value);
        }
    };

    // The Filter State
    const {
        loading, data, setData, handleChange, handlePeriod, handleSubmit,
    } = useFilter("reserve", open, initialData, filters, onSubmit);


    // Do the Render
    const allowsDoubles = Utils.getValue(reserveTypes, "key", data.reserveType, "allowsDoubles");

    return <FilterDialog
        open={open}
        title="RESERVES_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                options={Period.getSelect()}
                value={data.period}
                onChange={handlePeriod}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            isHidden={!!reserveTypeID}
            type="select"
            name="reserveType"
            label="RESERVE_TYPES_SINGULAR"
            options={reserveTypes}
            value={data.reserveType}
            onChange={handleUpdate}
        />
        <InputField
            type="chooser"
            name="courtID"
            label="COURTS_NAME"
            options={courts?.[data.reserveType] ?? []}
            value={data.courtID}
            onChange={handleChange}
        />
        <InputField
            isHidden={!allowsDoubles}
            type="chooser"
            name="matchType"
            label="GENERAL_TYPE"
            options="SELECT_MATCH_TYPES"
            value={data.matchType}
            onChange={handleChange}
        />
        <InputField
            type="suggest"
            name="memberName"
            label="GENERAL_MEMBER"
            value={data.memberName}
            suggestID="memberNumber"
            suggestFetch={Member.search}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReserveFilter.propTypes = {
    open          : PropTypes.bool.isRequired,
    onClose       : PropTypes.func.isRequired,
    onSubmit      : PropTypes.func.isRequired,
    reserveTypeID : PropTypes.number.isRequired,
    filters       : PropTypes.object,
};

export default ReserveFilter;
