import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Department Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DepartmentEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { areas, activities, credentials, statuses } = Store.useState("ticketDepartment");
    const { editElem } = Store.useAction("ticketDepartment");


    // The Initial Data
    const initialData = {
        ticketDepartmentID : 0,
        ticketAreaID       : 0,
        activityID         : 0,
        credentialID       : 0,
        name               : "",
        status             : "Active",
        position           : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("ticketDepartment", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("ticketDepartment", open, elemID, {}, setElem);


    // Do the Render
    return <EditDialog
        open={open}
        icon="area"
        title={elemID ? "TICKET_DEPARTMENTS_EDIT_TITLE" : "TICKET_DEPARTMENTS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="ticketAreaID"
            label="TICKET_AREAS_SINGULAR"
            options={areas}
            value={data.ticketAreaID}
            error={errors.ticketAreaID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="activityID"
            label="ACTIVITIES_SINGULAR"
            options={activities}
            value={data.activityID}
            error={errors.activityID}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="credentialID"
            label="TICKET_DEPARTMENTS_ASSIGN_TO"
            options={credentials}
            value={data.credentialID}
            error={errors.credentialID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DepartmentEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default DepartmentEdit;
