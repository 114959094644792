import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Member Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MemberFilter(props) {
    const { open, filters, onClose, onSubmit } = props;

    const { categories, states } = Store.useState("member");


    // The Initial Data
    const initialData = {
        memberNumber : "",
        familyGroup  : "",
        fullName     : "",
        dni          : "",
        categoryCode : [],
        stateCode    : [],
        isRegistered : 0,
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("member", open, initialData, filters, onSubmit);


    // Do the Render
    return <FilterDialog
        open={open}
        title="MEMBERS_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns>
            <InputField
                type="number"
                name="memberNumber"
                label="GENERAL_MEMBER_NUMBER"
                value={data.memberNumber}
                onChange={handleChange}
                hasClear
            />
            <InputField
                type="number"
                name="familyGroup"
                label="MEMBERS_FAMILY_GROUP"
                value={data.familyGroup}
                onChange={handleChange}
                hasClear
            />
            <InputField
                type="number"
                name="dni"
                label="GENERAL_DNI"
                value={data.dni}
                onChange={handleChange}
                hasClear
            />
            <InputField
                name="fullName"
                label="GENERAL_FULL_NAME"
                value={data.fullName}
                onChange={handleChange}
                hasClear
            />
        </Columns>

        <InputField
            type="chooser"
            name="categoryCode"
            label="CATEGORIES_SINGULAR"
            options={categories}
            value={data.categoryCode}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="stateCode"
            label="GENERAL_STATUS"
            options={states}
            value={data.stateCode}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="isRegistered"
            label="MEMBERS_IS_REGISTERED"
            options="GENERAL_YES_NO_ANY"
            value={data.isRegistered}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MemberFilter.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    filters  : PropTypes.object,
};

export default MemberFilter;
