import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import SubscriptionView     from "./SubscriptionView";
import ShiftEdit            from "../Shift/ShiftEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import Filter               from "Dashboard/Components/Filter/Filter";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";

// The API
import {
    ShiftSubscription,
} from "Utils/API";



/**
 * The Course Subscription List
 * @returns {React.ReactElement}
 */
function SubscriptionList() {
    const { fetch, load, loadFilter } = useList("shiftSubscription", "SHIFT");

    const parent = Navigate.useParent(1);

    const data = Store.useState("shiftSubscription");
    const { loading, canEdit, list, total, shift, statuses, filters, sort } = data;

    // The Current State
    const [ exporting, setExporting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isExport) {
            setExporting(true);
            endAction();
            const source = ShiftSubscription.export({ ...filters, shiftID : shift.id });
            await Utils.download(source, "inscripciones.xlsx");
            setExporting(false);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };


    // Do the Render
    return <Main>
        <Header
            message={shift.name || "SUBSCRIPTIONS_NAME"}
            icon="shift"
            href={parent}
            subTitle={shift.statusName}
            subCircle={shift.statusColor}
        >
            <ActionList data={data} onAction={handleAction} isExporting={exporting}>
                <ActionItem
                    isHidden={!shift.canEdit}
                    action="EDIT"
                />
            </ActionList>
        </Header>
        <Content>
            <Filter
                onFilter={loadFilter}
                values={filters}
                hasSearch
            >
                <FilterItem
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                />
            </Filter>

            <Table
                fetch={load}
                sort={sort}
                none="SUBSCRIPTIONS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="createdTime"  message="GENERAL_CREATED_AT_FEM" minWidth="170" maxWidth="170" isTitle />
                    <TableHeader field="number"       message="GENERAL_NUMBER"         maxWidth="80" />
                    <TableHeader field="time"         message="SCHEDULES_SINGULAR"     maxWidth="100" />
                    <TableHeader field="memberNumber" message="GENERAL_MEMBER_NUMBER"  maxWidth="120" />
                    <TableHeader field="firstName"    message="GENERAL_MEMBER"         />
                    <TableHeader field="totalPersons" message="COURSES_PERSONS_NAME"   maxWidth="100" align="center" />
                    <TableHeader field="status"       message="GENERAL_STATUS"         maxWidth="100" align="center" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.subscriptionID} elemID={elem.subscriptionID}>
                        <TableCell message={elem.createdTimeText} />
                        <TableCell message={elem.numberText}      />
                        <TableCell message={elem.timeText}        />
                        <TableCell message={elem.memberNumber}    />
                        <TableCell message={elem.memberName}      />
                        <TableCell message={elem.totalPersons}    />
                        <TableCell message={elem.statusName}      textColor={elem.statusColor} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW" message="SUBSCRIPTIONS_VIEW_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <ShiftEdit
            open={action.isEdit}
            isCopy={false}
            elemID={shift.id}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <SubscriptionView
            open={action.isView}
            elemID={elemID}
            onClose={endAction}
        />
    </Main>;
}

export default SubscriptionList;
