import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Admin Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AdminEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { accesses, statuses } = Store.useState("admin");
    const { editElem } = Store.useAction("admin");


    // The Initial Data
    const initialData = {
        credentialID  : 0,
        access        : "",
        firstName     : "",
        lastName      : "",
        email         : "",
        password      : "",
        reqPassChange : 0,
        status        : "Active",
        sendEmails    : 1,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("admin", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("admin", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="admin"
        title={elemID ? "ADMINS_EDIT_TITLE" : "ADMINS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="select"
            name="access"
            label="GENERAL_ACCESS"
            options={accesses}
            value={data.access}
            error={errors.access}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="firstName"
            label="GENERAL_FIRST_NAME"
            value={data.firstName}
            error={errors.firstName}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="lastName"
            label="GENERAL_LAST_NAME"
            value={data.lastName}
            error={errors.lastName}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="email"
            name="email"
            label="GENERAL_EMAIL"
            value={data.email}
            error={errors.email}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="password"
            name="password"
            label={elemID ? "GENERAL_NEW_PASSWORD" : "GENERAL_PASSWORD"}
            value={data.password}
            error={errors.password}
            onChange={handleChange}
            autoComplete="new-password"
            isRequired={!elemID}
            generateCode
        />
        <InputField
            type="toggle"
            name="reqPassChange"
            label="GENERAL_REQ_PASS_CHANGE"
            value={!!data.reqPassChange}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
        <InputField
            type="toggle"
            name="sendEmails"
            label="GENERAL_SEND_EMAILS"
            value={data.sendEmails}
            onChange={handleChange}
            withBorder
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AdminEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default AdminEdit;
