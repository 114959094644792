import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import ReserveFilter        from "./ReserveFilter";
import ReserveView          from "./ReserveView";
import ReserveEdit          from "./ReserveEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";



/**
 * The Reserve List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReserveList(props) {
    const { type } = props;
    const { fetch, load, loadTab, loadFilter } = useList("reserve", type);

    const { reserveTypeID } = Navigate.useParams();

    const data = Store.useState("reserve");
    const { loading, canEdit, list, total, filters, sort } = data;

    const { nullifyElem } = Store.useAction("reserve");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isTab) {
            loadTab(elemID);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Nullify Submit
    const handleNullify = async () => {
        endAction();
        const result = await nullifyElem(elemID);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Close
    const handleClose = (update) => {
        endAction();
        if (update) {
            fetch();
        }
    };

    // Returns true if the Edit is hidden
    const hideEdit = (elemID) => {
        return !Utils.getValue(list, "reserveID", elemID, "canEdit");
    };


    // Do the Render
    const showType = type !== "RESERVE_TYPE";

    return <Main>
        <Header message="RESERVES_NAME" icon="reserve">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content>
            <TabList selected={sort.filter} onAction={handleAction}>
                <TabItem message="RESERVES_CURRENT_TAB"   value="Current"   />
                <TabItem message="RESERVES_PAST_TAB"      value="Past"      />
                <TabItem message="RESERVES_NULLIFIED_TAB" value="Nullified" />
                <TabItem message="GENERAL_ALL_FEM"        value="All"       />
            </TabList>
            <Table
                fetch={load}
                sort={sort}
                none="RESERVES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="reserveTypeName" message="GENERAL_TYPE"     isTitle isHidden={!showType} />
                    <TableHeader field="fromTime"        message="GENERAL_DATE"     maxWidth="120" />
                    <TableHeader field="fromMinutes"     message="GENERAL_FROM"     maxWidth="80"  />
                    <TableHeader field="toMinutes"       message="GENERAL_TO"       maxWidth="80"  />
                    <TableHeader field="duration"        message="GENERAL_DURATION" maxWidth="80"  />
                    <TableHeader field="matchType"       message="GENERAL_TYPE"     maxWidth="100" />
                    <TableHeader field="courtName"       message="COURTS_SINGULAR"  />
                    <TableHeader field="memberNumber"    message="GENERAL_MEMBER"   grow="2" />
                    <TableHeader field="status"          message="GENERAL_STATUS"   maxWidth="100" align="center" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.reserveID} elemID={elem.reserveID}>
                        <TableCell message={elem.reserveTypeName} />
                        <TableCell message={elem.dateText}        />
                        <TableCell message={elem.hour}            />
                        <TableCell message={elem.toHour}          />
                        <TableCell message={elem.duration}        />
                        <TableCell message={elem.matchTypeName}   />
                        <TableCell message={elem.courtName}       />
                        <TableCell message={elem.memberText}      />
                        <TableCell message={elem.statusName}      textColor={elem.statusColor} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"    message="RESERVES_VIEW_TITLE"    />
                    <TableAction action="EDIT"    message="RESERVES_EDIT_TITLE"    hide={hideEdit} />
                    <TableAction action="NULLIFY" message="RESERVES_NULLIFY_TITLE" hide={hideEdit} />
                </TableActionList>
            </Table>
        </Content>

        <ReserveFilter
            open={action.isFilter}
            filters={filters}
            reserveTypeID={reserveTypeID}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <ReserveView
            open={action.isView}
            reserveTypeID={reserveTypeID}
            elemID={elemID}
            onClose={handleClose}
        />
        <ReserveEdit
            open={action.isCE}
            reserveTypeID={reserveTypeID}
            date={DateTime.create().toDayStart().toString("dashesReverse")}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={handleClose}
        />
        <ConfirmDialog
            open={action.isNullify}
            icon="nullify"
            title="RESERVES_NULLIFY_TITLE"
            message="RESERVES_NULLIFY_TEXT"
            primary="GENERAL_NULLIFY"
            onSubmit={handleNullify}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReserveList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default ReserveList;
