import React                from "react";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import useList              from "Dashboard/Hooks/List";

// Components
import RelationEdit         from "./RelationEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import TablePaging          from "Dashboard/Components/Table/TablePaging";



/**
 * The Relation List
 * @returns {React.ReactElement}
 */
function RelationList() {
    const { fetch, load } = useList("memberRelation");

    const data = Store.useState("memberRelation");
    const { loading, canEdit, list, total, sort } = data;

    // The Current Relation
    const [ action, elemCode, startAction, endAction ] = Action.useActionCode();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };


    // Do the Render
    return <Main>
        <Header message="MEMBERS_RELATIONS_NAME" icon="relation">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="MEMBERS_RELATIONS_NONE_AVAILABLE"
                isLoading={loading}
                hasTabs
            >
                <TableHead>
                    <TableHeader field="name"          message="GENERAL_NAME"        isTitle />
                    <TableHeader field="stateCount"    message="MEMBERS_STATES_NAME" maxWidth="120" />
                    <TableHeader field="categoryCount" message="CATEGORIES_NAME"     maxWidth="120" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.relationCode} elemID={elem.relationCode}>
                        <TableCell message={elem.name}              />
                        <TableCell message={elem.stateCountText}    />
                        <TableCell message={elem.categoryCountText} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT" message="MEMBERS_RELATIONS_EDIT_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <RelationEdit
            open={action.isVCE}
            elemCode={elemCode}
            onSubmit={handleEdit}
            onClose={endAction}
        />
    </Main>;
}

export default RelationList;
