import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Version Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function VersionEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("version");


    // The Initial Data
    const initialData = {
        versionID   : 0,
        version     : "",
        environment : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("version", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("version", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="version"
        title={elemID ? "VERSIONS_EDIT_TITLE" : "VERSIONS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="version"
            label="VERSIONS_SINGULAR"
            value={data.version}
            error={errors.version}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="environment"
            label="VERSIONS_ENVIRONMENT"
            options="SELECT_ENVIRONMENTS"
            value={data.environment}
            error={errors.environment}
            onChange={handleChange}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
VersionEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default VersionEdit;
