import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Category Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CategoryEdit(props) {
    const { open, elemCode, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("memberCategory");


    // The Initial Data
    const initialData = {
        categoryCode    : 0,
        newCategoryCode : 0,
        name            : "",
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({
            ...elem,
            newCategoryCode : elem.categoryCode,
        });
    };

    // The Form Category
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("memberCategory", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("memberCategory", open, elemCode, {}, handleSet);


    // Do the Render
    return <EditDialog
        open={open}
        icon="category"
        title={elemCode ? "CATEGORIES_EDIT_TITLE" : "CATEGORIES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            type="number"
            name="newCategoryCode"
            label="GENERAL_CODE"
            value={data.newCategoryCode}
            error={errors.newCategoryCode}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CategoryEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemCode : PropTypes.number,
};

export default CategoryEdit;
