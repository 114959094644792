import Ajax                 from "Dashboard/Core/Ajax";



// Auth actions
export const Auth = {
    "login"                 : (data) => Ajax.post("/session/login",                      data),
    "loginAs"               : (data) => Ajax.post("/session/loginAs",                    data),
    "tokenLogin"            : (data) => Ajax.post("/session/tokenLogin",                 data),
    "refreshTokenLogin"     : (data) => Ajax.post("/session/refreshTokenLogin",          data),
    "logout"                : (data) => Ajax.post("/session/logout",                     data),
    "logoutAs"              : (data) => Ajax.post("/session/logoutAs",                   data),
    "requestReset"          : (data) => Ajax.post("/session/requestReset",               data),
    "verifyReset"           : (data) => Ajax.post("/session/verifyReset",                data),
    "resetPass"             : (data) => Ajax.post("/session/resetPass",                  data),
    "changePass"            : (data) => Ajax.post("/session/changePass",                 data),
    "deleteRefreshToken"    : (data) => Ajax.post("/session/deleteRefreshToken",         data),
};
export const Profile = {
    "search"                : (data) => Ajax.post("/profile/search",                     data),
    "get"                   : (data) => Ajax.post("/profile/get",                        data),
    "edit"                  : (data) => Ajax.post("/profile/edit",                       data),
    "upload"                : (data) => Ajax.post("/profile/upload",                     data),
    "delete"                : (data) => Ajax.post("/profile/delete",                     data),
    "addDevice"             : (data) => Ajax.post("/profile/addDevice",                  data),
    "removeDevice"          : (data) => Ajax.post("/profile/removeDevice",               data),
    "noNotifications"       : (data) => Ajax.post("/profile/noNotifications",            data),
};



// Entry Actions
export const News = {
    "getAll"                : (data) => Ajax.post("/news/getAll",                        data),
    "getEditData"           : (data) => Ajax.post("/news/getEditData",                   data),
    "getOne"                : (data) => Ajax.post("/news/getOne",                        data),
    "edit"                  : (data) => Ajax.post("/news/edit",                          data),
    "delete"                : (data) => Ajax.post("/news/delete",                        data),
};
export const NewsCategory = {
    "getAll"                : (data) => Ajax.post("/news/category/getAll",               data),
    "getEditData"           : (data) => Ajax.post("/news/category/getEditData",          data),
    "getOne"                : (data) => Ajax.post("/news/category/getOne",               data),
    "edit"                  : (data) => Ajax.post("/news/category/edit",                 data),
    "delete"                : (data) => Ajax.post("/news/category/delete",               data),
};
export const Billboard = {
    "getAll"                : (data) => Ajax.post("/billboard/getAll",                   data),
    "getEditData"           : (data) => Ajax.post("/billboard/getEditData",              data),
    "getOne"                : (data) => Ajax.post("/billboard/getOne",                   data),
    "edit"                  : (data) => Ajax.post("/billboard/edit",                     data),
    "delete"                : (data) => Ajax.post("/billboard/delete",                   data),
};



// Activity Actions
export const Activity = {
    "getAll"                : (data) => Ajax.post("/activity/getAll",                    data),
    "getOne"                : (data) => Ajax.post("/activity/getOne",                    data),
    "edit"                  : (data) => Ajax.post("/activity/edit",                      data),
    "delete"                : (data) => Ajax.post("/activity/delete",                    data),
};
export const Button = {
    "getAll"                : (data) => Ajax.post("/button/getAll",                      data),
    "getOne"                : (data) => Ajax.post("/button/getOne",                      data),
    "getEditData"           : (data) => Ajax.post("/button/getEditData",                 data),
    "edit"                  : (data) => Ajax.post("/button/edit",                        data),
    "delete"                : (data) => Ajax.post("/button/delete",                      data),
};
export const Page = {
    "getAll"                : (data) => Ajax.post("/page/getAll",                        data),
    "getOne"                : (data) => Ajax.post("/page/getOne",                        data),
    "getEditData"           : (data) => Ajax.post("/page/getEditData",                   data),
    "edit"                  : (data) => Ajax.post("/page/edit",                          data),
    "delete"                : (data) => Ajax.post("/page/delete",                        data),
};



// Reserve Actions
export const Reserve = {
    "getAll"                : (data) => Ajax.post("/reserve/getAll",                     data),
    "getCalendar"           : (data) => Ajax.post("/reserve/getCalendar",                data),
    "getKiosk"              : (data) => Ajax.post("/reserve/getKiosk",                   data),
    "getOne"                : (data) => Ajax.post("/reserve/getOne",                     data),
    "getEditData"           : (data) => Ajax.post("/reserve/getEditData",                data),
    "getFilterData"         : (data) => Ajax.post("/reserve/getFilterData",              data),
    "edit"                  : (data) => Ajax.post("/reserve/edit",                       data),
    "nullify"               : (data) => Ajax.post("/reserve/nullify",                    data),
};
export const ReserveType = {
    "getAll"                : (data) => Ajax.post("/reserve/type/getAll",                data),
    "getOne"                : (data) => Ajax.post("/reserve/type/getOne",                data),
    "getEditData"           : (data) => Ajax.post("/reserve/type/getEditData",           data),
    "edit"                  : (data) => Ajax.post("/reserve/type/edit",                  data),
    "delete"                : (data) => Ajax.post("/reserve/type/delete",                data),
};
export const Court = {
    "getAll"                : (data) => Ajax.post("/court/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/court/getOne",                       data),
    "getEditData"           : (data) => Ajax.post("/court/getEditData",                  data),
    "edit"                  : (data) => Ajax.post("/court/edit",                         data),
    "delete"                : (data) => Ajax.post("/court/delete",                       data),
};
export const CourtBlock = {
    "getAll"                : (data) => Ajax.post("/court/block/getAll",                 data),
    "getOne"                : (data) => Ajax.post("/court/block/getOne",                 data),
    "getEditData"           : (data) => Ajax.post("/court/block/getEditData",            data),
    "edit"                  : (data) => Ajax.post("/court/block/edit",                   data),
    "delete"                : (data) => Ajax.post("/court/block/delete",                 data),
};
export const CourtBlockType = {
    "getAll"                : (data) => Ajax.post("/court/blockType/getAll",             data),
    "getOne"                : (data) => Ajax.post("/court/blockType/getOne",             data),
    "getEditData"           : (data) => Ajax.post("/court/blockType/getEditData",        data),
    "edit"                  : (data) => Ajax.post("/court/blockType/edit",               data),
    "delete"                : (data) => Ajax.post("/court/blockType/delete",             data),
};



// Course Actions
export const Course = {
    "getAll"                : (data) => Ajax.post("/course/getAll",                      data),
    "getOne"                : (data) => Ajax.post("/course/getOne",                      data),
    "getEditData"           : (data) => Ajax.post("/course/getEditData",                 data),
    "edit"                  : (data) => Ajax.post("/course/edit",                        data),
    "confirm"               : (data) => Ajax.post("/course/confirm",                     data),
    "delete"                : (data) => Ajax.post("/course/delete",                      data),
};
export const CourseSubscription = {
    "getAll"                : (data) => Ajax.post("/course/subscription/getAll",         data),
    "getOne"                : (data) => Ajax.post("/course/subscription/getOne",         data),
    "getEditData"           : (data) => Ajax.post("/course/subscription/getEditData",    data),
    "export"                : (data) => Ajax.url("/course/subscription/export",          data),
    "edit"                  : (data) => Ajax.post("/course/subscription/edit",           data),
    "markPending"           : (data) => Ajax.post("/course/subscription/markPending",    data),
    "nullify"               : (data) => Ajax.post("/course/subscription/nullify",        data),
    "delete"                : (data) => Ajax.post("/course/subscription/delete",         data),
};
export const CoursePerson = {
    "getAll"                : (data) => Ajax.post("/course/person/getAll",               data),
    "getOne"                : (data) => Ajax.post("/course/person/getOne",               data),
    "getEditData"           : (data) => Ajax.post("/course/person/getEditData",          data),
    "edit"                  : (data) => Ajax.post("/course/person/edit",                 data),
    "multiEdit"             : (data) => Ajax.post("/course/person/multiEdit",            data),
};



// Shift Actions
export const Shift = {
    "getAll"                : (data) => Ajax.post("/shift/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/shift/getOne",                       data),
    "getEditData"           : (data) => Ajax.post("/shift/getEditData",                  data),
    "edit"                  : (data) => Ajax.post("/shift/edit",                         data),
    "delete"                : (data) => Ajax.post("/shift/delete",                       data),
};
export const ShiftSubscription = {
    "getAll"                : (data) => Ajax.post("/shift/subscription/getAll",          data),
    "getOne"                : (data) => Ajax.post("/shift/subscription/getOne",          data),
    "export"                : (data) => Ajax.url("/shift/subscription/export",           data),
};
export const ShiftCourse = {
    "getAll"                : (data) => Ajax.post("/shift/course/getAll",                data),
    "getOne"                : (data) => Ajax.post("/shift/course/getOne",                data),
    "getEditData"           : (data) => Ajax.post("/shift/course/getEditData",           data),
    "edit"                  : (data) => Ajax.post("/shift/course/edit",                  data),
    "delete"                : (data) => Ajax.post("/shift/course/delete",                data),
};



// Training Actions
export const Training = {
    "getAll"                : (data) => Ajax.post("/training/getAll",                    data),
    "getOne"                : (data) => Ajax.post("/training/getOne",                    data),
    "getEditData"           : (data) => Ajax.post("/training/getEditData",               data),
    "edit"                  : (data) => Ajax.post("/training/edit",                      data),
    "delete"                : (data) => Ajax.post("/training/delete",                    data),
};
export const TrainingSubscription = {
    "getAll"                : (data) => Ajax.post("/training/subscription/getAll",       data),
    "getOne"                : (data) => Ajax.post("/training/subscription/getOne",       data),
    "getEditData"           : (data) => Ajax.post("/training/subscription/getEditData",  data),
    "export"                : (data) => Ajax.url("/training/subscription/export",        data),
    "edit"                  : (data) => Ajax.post("/training/subscription/edit",         data),
    "delete"                : (data) => Ajax.post("/training/subscription/delete",       data),
};
export const TrainingWhiteList = {
    "getAll"                : (data) => Ajax.post("/training/whiteList/getAll",          data),
    "getOne"                : (data) => Ajax.post("/training/whiteList/getOne",          data),
    "getEditData"           : (data) => Ajax.post("/training/whiteList/getEditData",     data),
    "edit"                  : (data) => Ajax.post("/training/whiteList/edit",            data),
    "delete"                : (data) => Ajax.post("/training/whiteList/delete",          data),
};
export const TrainingMember = {
    "getAll"                : (data) => Ajax.post("/training/member/getAll",             data),
    "getOne"                : (data) => Ajax.post("/training/member/getOne",             data),
    "getEditData"           : (data) => Ajax.post("/training/member/getEditData",        data),
    "edit"                  : (data) => Ajax.post("/training/member/edit",               data),
    "delete"                : (data) => Ajax.post("/training/member/delete",             data),
};



// Schedule Actions
export const Schedule = {
    "getAll"                : (data) => Ajax.post("/schedule/getAll",                    data),
    "getEditData"           : (data) => Ajax.post("/schedule/getEditData",               data),
    "getOne"                : (data) => Ajax.post("/schedule/getOne",                    data),
    "edit"                  : (data) => Ajax.post("/schedule/edit",                      data),
    "delete"                : (data) => Ajax.post("/schedule/delete",                    data),
};
export const ScheduleCategory = {
    "getAll"                : (data) => Ajax.post("/schedule/category/getAll",           data),
    "getOne"                : (data) => Ajax.post("/schedule/category/getOne",           data),
    "getEditData"           : (data) => Ajax.post("/schedule/category/getEditData",      data),
    "edit"                  : (data) => Ajax.post("/schedule/category/edit",             data),
    "delete"                : (data) => Ajax.post("/schedule/category/delete",           data),
};
export const ScheduleHoliday = {
    "getAll"                : (data) => Ajax.post("/schedule/holiday/getAll",            data),
    "getOne"                : (data) => Ajax.post("/schedule/holiday/getOne",            data),
    "edit"                  : (data) => Ajax.post("/schedule/holiday/edit",              data),
    "delete"                : (data) => Ajax.post("/schedule/holiday/delete",            data),
};



// Administration Actions
export const Title = {
    "getAll"                : (data) => Ajax.post("/title/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/title/getOne",                       data),
    "edit"                  : (data) => Ajax.post("/title/edit",                         data),
};
export const Fee = {
    "getAll"                : (data) => Ajax.post("/fee/getAll",                         data),
    "getEditData"           : (data) => Ajax.post("/fee/getEditData",                    data),
    "getOne"                : (data) => Ajax.post("/fee/getOne",                         data),
    "edit"                  : (data) => Ajax.post("/fee/edit",                           data),
    "delete"                : (data) => Ajax.post("/fee/delete",                         data),
};
export const Balance = {
    "getAll"                : (data) => Ajax.post("/balance/getAll",                     data),
    "getEditData"           : (data) => Ajax.post("/balance/getEditData",                data),
    "getOne"                : (data) => Ajax.post("/balance/getOne",                     data),
    "edit"                  : (data) => Ajax.post("/balance/edit",                       data),
    "delete"                : (data) => Ajax.post("/balance/delete",                     data),
};



// Contact Actions
export const Contact = {
    "getAll"                : (data) => Ajax.post("/contact/getAll",                     data),
    "getEditData"           : (data) => Ajax.post("/contact/getEditData",                data),
    "getOne"                : (data) => Ajax.post("/contact/getOne",                     data),
    "edit"                  : (data) => Ajax.post("/contact/edit",                       data),
    "delete"                : (data) => Ajax.post("/contact/delete",                     data),
};
export const ContactCategory = {
    "getAll"                : (data) => Ajax.post("/contact/category/getAll",            data),
    "getOne"                : (data) => Ajax.post("/contact/category/getOne",            data),
    "getEditData"           : (data) => Ajax.post("/contact/category/getEditData",       data),
    "edit"                  : (data) => Ajax.post("/contact/category/edit",              data),
    "delete"                : (data) => Ajax.post("/contact/category/delete",            data),
};
export const ContactSocial = {
    "getAll"                : (data) => Ajax.post("/contact/social/getAll",              data),
    "getOne"                : (data) => Ajax.post("/contact/social/getOne",              data),
    "getEditData"           : (data) => Ajax.post("/contact/social/getEditData",         data),
    "edit"                  : (data) => Ajax.post("/contact/social/edit",                data),
    "delete"                : (data) => Ajax.post("/contact/social/delete",              data),
};



// Ticket Actions
export const Ticket = {
    "getAll"                : (data) => Ajax.post("/ticket/getAll",                      data),
    "getOne"                : (data) => Ajax.post("/ticket/getOne",                      data),
    "getEditData"           : (data) => Ajax.post("/ticket/getEditData",                 data),
    "create"                : (data) => Ajax.post("/ticket/create",                      data),
    "edit"                  : (data) => Ajax.post("/ticket/edit",                        data),
    "open"                  : (data) => Ajax.post("/ticket/open",                        data),
    "wait"                  : (data) => Ajax.post("/ticket/wait",                        data),
    "close"                 : (data) => Ajax.post("/ticket/close",                       data),
    "delete"                : (data) => Ajax.post("/ticket/delete",                      data),
    "addMessage"            : (data) => Ajax.post("/ticket/addMessage",                  data),
    "editMessage"           : (data) => Ajax.post("/ticket/editMessage",                 data),
    "deleteMessage"         : (data) => Ajax.post("/ticket/deleteMessage",               data),
    "deleteFile"            : (data) => Ajax.post("/ticket/deleteFile",                  data),
};
export const TicketArea = {
    "getAll"                : (data) => Ajax.post("/ticket/area/getAll",                 data),
    "getOne"                : (data) => Ajax.post("/ticket/area/getOne",                 data),
    "getEditData"           : (data) => Ajax.post("/ticket/area/getEditData",            data),
    "edit"                  : (data) => Ajax.post("/ticket/area/edit",                   data),
    "delete"                : (data) => Ajax.post("/ticket/area/delete",                 data),
};
export const TicketDepartment = {
    "getAll"                : (data) => Ajax.post("/ticket/department/getAll",           data),
    "getOne"                : (data) => Ajax.post("/ticket/department/getOne",           data),
    "getEditData"           : (data) => Ajax.post("/ticket/department/getEditData",      data),
    "edit"                  : (data) => Ajax.post("/ticket/department/edit",             data),
    "delete"                : (data) => Ajax.post("/ticket/department/delete",           data),
};
export const TicketSubject = {
    "getAll"                : (data) => Ajax.post("/ticket/subject/getAll",              data),
    "getOne"                : (data) => Ajax.post("/ticket/subject/getOne",              data),
    "getEditData"           : (data) => Ajax.post("/ticket/subject/getEditData",         data),
    "edit"                  : (data) => Ajax.post("/ticket/subject/edit",                data),
    "delete"                : (data) => Ajax.post("/ticket/subject/delete",              data),
};
export const TicketWaitlist = {
    "getAll"                : (data) => Ajax.post("/ticket/waitlist/getAll",             data),
    "getOne"                : (data) => Ajax.post("/ticket/waitlist/getOne",             data),
    "getEditData"           : (data) => Ajax.post("/ticket/waitlist/getEditData",        data),
    "edit"                  : (data) => Ajax.post("/ticket/waitlist/edit",               data),
    "delete"                : (data) => Ajax.post("/ticket/waitlist/delete",             data),
};



// Member Actions
export const Member = {
    "getAll"                : (data) => Ajax.post("/member/getAll",                      data),
    "getOne"                : (data) => Ajax.post("/member/getOne",                      data),
    "getFilterData"         : (data) => Ajax.post("/member/getFilterData",               data),
    "getAvatar"             : (data) => Ajax.url("/member/getAvatar",                    data),
    "search"                : (data) => Ajax.post("/member/search",                      data),
    "register"              : (data) => Ajax.post("/member/register",                    data),
    "unregister"            : (data) => Ajax.post("/member/unregister",                  data),
};
export const MemberState = {
    "getAll"                : (data) => Ajax.post("/member/state/getAll",                data),
    "getOne"                : (data) => Ajax.post("/member/state/getOne",                data),
    "edit"                  : (data) => Ajax.post("/member/state/edit",                  data),
    "delete"                : (data) => Ajax.post("/member/state/delete",                data),
};
export const MemberCategory = {
    "getAll"                : (data) => Ajax.post("/member/category/getAll",             data),
    "getOne"                : (data) => Ajax.post("/member/category/getOne",             data),
    "edit"                  : (data) => Ajax.post("/member/category/edit",               data),
    "delete"                : (data) => Ajax.post("/member/category/delete",             data),
};
export const MemberRelation = {
    "getAll"                : (data) => Ajax.post("/member/relation/getAll",             data),
    "getOne"                : (data) => Ajax.post("/member/relation/getOne",             data),
    "edit"                  : (data) => Ajax.post("/member/relation/edit",               data),
};
export const Province = {
    "getAll"                : (data) => Ajax.post("/province/getAll",                    data),
    "getOne"                : (data) => Ajax.post("/province/getOne",                    data),
    "edit"                  : (data) => Ajax.post("/province/edit",                      data),
    "delete"                : (data) => Ajax.post("/province/delete",                    data),
};



// Document Actions
export const Document = {
    "getAll"                : (data) => Ajax.post("/document/getAll",                    data),
    "getUrls"               : (data) => Ajax.post("/document/getUrls",                   data),
    "getOne"                : (data) => Ajax.post("/document/getOne",                    data),
    "getEditData"           : (data) => Ajax.post("/document/getEditData",               data),
    "edit"                  : (data) => Ajax.post("/document/edit",                      data),
    "save"                  : (data) => Ajax.post("/document/save",                      data),
    "delete"                : (data) => Ajax.post("/document/delete",                    data),
};



// Setup Actions
export const Admin = {
    "getAll"                : (data) => Ajax.post("/admin/getAll",                       data),
    "getOne"                : (data) => Ajax.post("/admin/getOne",                       data),
    "edit"                  : (data) => Ajax.post("/admin/edit",                         data),
    "delete"                : (data) => Ajax.post("/admin/delete",                       data),
};
export const NotificationType = {
    "getAll"                : (data) => Ajax.post("/notificationType/getAll",            data),
    "getOne"                : (data) => Ajax.post("/notificationType/getOne",            data),
    "getEditData"           : (data) => Ajax.post("/notificationType/getEditData",       data),
    "edit"                  : (data) => Ajax.post("/notificationType/edit",              data),
    "delete"                : (data) => Ajax.post("/notificationType/delete",            data),
};
export const Version = {
    "getAll"                : (data) => Ajax.post("/version/getAll",                     data),
    "getOne"                : (data) => Ajax.post("/version/getOne",                     data),
    "edit"                  : (data) => Ajax.post("/version/edit",                       data),
    "delete"                : (data) => Ajax.post("/version/delete",                     data),
};
export const Media = {
    "getAll"                : (data) => Ajax.post("/media/getAll",                       data),
    "create"                : (data) => Ajax.post("/media/create",                       data),
    "upload"                : (data) => Ajax.post("/media/upload",                       data),
    "rename"                : (data) => Ajax.post("/media/rename",                       data),
    "move"                  : (data) => Ajax.post("/media/move",                         data),
    "delete"                : (data) => Ajax.post("/media/delete",                       data),
};
export const Notification = {
    "getAll"                : (data) => Ajax.get("/notification/getAll",                 data),
    "getOne"                : (data) => Ajax.get("/notification/getOne",                 data),
};



// Email Actions
export const EmailTemplate = {
    "getAll"                : (data) => Ajax.post("/email/template/getAll",              data),
    "getOne"                : (data) => Ajax.post("/email/template/getOne",              data),
};
export const EmailQueue = {
    "getAll"                : (data) => Ajax.get("/email/queue/getAll",                  data),
    "getOne"                : (data) => Ajax.get("/email/queue/getOne",                  data),
    "resend"                : (data) => Ajax.get("/email/queue/resend",                  data),
};
export const EmailWhiteList = {
    "getAll"                : (data) => Ajax.get("/email/whiteList/getAll",              data),
    "getOne"                : (data) => Ajax.get("/email/whiteList/getOne",              data),
    "edit"                  : (data) => Ajax.post("/email/whiteList/edit",               data),
    "remove"                : (data) => Ajax.post("/email/whiteList/remove",             data),
};



// Log Actions
export const ProviderLog = {
    "getAll"                : (data) => Ajax.post("/log/provider/getAll",                data),
    "getOne"                : (data) => Ajax.post("/log/provider/getOne",                data),
};
export const ActionLog = {
    "getAll"                : (data) => Ajax.post("/log/action/getAll",                  data),
    "getAllForClient"       : (data) => Ajax.post("/log/action/getAllForClient",         data),
    "getAllForUser"         : (data) => Ajax.post("/log/action/getAllForUser",           data),
};
export const ErrorLog = {
    "getAll"                : (data) => Ajax.post("/log/error/getAll",                   data),
    "getOne"                : (data) => Ajax.post("/log/error/getOne",                   data),
    "markResolved"          : (data) => Ajax.post("/log/error/markResolved",             data),
    "delete"                : (data) => Ajax.post("/log/error/delete",                   data),
};
