import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Reserve }          from "Utils/API";



// The initial State
const initialState = {
    loading      : true,
    error        : false,
    edition      : 0,
    canCreate    : false,
    canEdit      : false,
    canFilter    : false,
    canImport    : false,
    canExport    : false,
    list         : [],
    total        : 0,
    elem         : {
        players : [],
    },
    reserveTypes : [],
    reserveType  : {},
    hours        : [],
    courts       : {},
    durations    : {},
    totalPlayers : {},
    statuses     : [],
    filters      : {},
    hasFilters   : false,
    sort         : {
        filter   : "Current",
        orderBy  : "fromTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "RESERVE_LOADING" });
    },

    /**
     * Fetches the Reserve List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "RESERVE_TYPE") {
            params.reserveTypeID = elemID;
        }

        const data   = await Reserve.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "RESERVE_LIST", data });
    },

    /**
     * Fetches a single Reserve
     * @param {Function} dispatch
     * @param {Number}   reserveID
     * @returns {Promise}
     */
    async fetchElem(dispatch, reserveID) {
        const data = await Reserve.getOne({ reserveID });
        dispatch({ type : "RESERVE_ELEM", data });
    },

    /**
     * Fetches the Reserve Edit data
     * @param {Function} dispatch
     * @param {Number}   reserveTypeID
     * @param {String}   date
     * @param {Boolean=} isInitial
     * @returns {Promise}
     */
    async fetchEditData(dispatch, reserveTypeID, date, isInitial = true) {
        const data = await Reserve.getEditData({ reserveTypeID, date });
        data.isInitial = isInitial;
        dispatch({ type : "RESERVE_EDIT", data });
    },

    /**
     * Fetches the Reserve Filter data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchFilterData(dispatch) {
        const data = await Reserve.getFilterData();
        dispatch({ type : "RESERVE_FILTER", data });
    },

    /**
     * Edits/Creates a Reserve
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return Reserve.edit(data);
    },

    /**
     * Nullifies a Reserve
     * @param {Function} dispatch
     * @param {Number}   reserveID
     * @returns {Promise}
     */
    nullifyElem(dispatch, reserveID) {
        return Reserve.nullify({ reserveID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.memberText = elem.memberName ? `${elem.memberNumber} - ${elem.memberName}` : elem.memberNumber;

    for (const player of elem.players) {
        player.playerTypeName = NLS.get("SELECT_PLAYER_TYPES", player.playerType);
        if (player.playerType === "Guest") {
            player.playerName = `${player.firstName} ${player.lastName} - ${player.documentValue}`;
        } else {
            player.playerName = `${player.memberNumber} - ${player.memberName}`;
        }

        const properties = [];
        if (player.sharesPayment) {
            properties.push(NLS.get("RESERVES_SHARES_PAYMENT"));
        }
        if (player.isConfirmed) {
            properties.push(NLS.get("RESERVES_IS_CONFIRMED"));
        }
        player.properties = properties.join(", ");
    }
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "RESERVE_LIST", "RESERVE_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "RESERVE_LOADING":
        return {
            ...state,
            loading     : true,
        };

    case "RESERVE_LIST":
        return {
            ...state,
            loading     : false,
            error       : false,
            canCreate   : action.data.canCreate,
            canEdit     : action.data.canEdit,
            canFilter   : action.data.canFilter,
            canImport   : action.data.canImport,
            canExport   : action.data.canExport,
            list        : Utils.parseList(action.data.list, parseElem),
            total       : action.data.total,
            filters     : action.data.filters,
            hasFilters  : !Utils.isEmpty(action.data.filters),
            sort        : action.data.sort,
        };

    case "RESERVE_ELEM":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            elem         : parseElem(action.data.elem),
            reserveTypes : [],
            reserveType  : action.data.reserveType,
            hours        : action.data.hours,
            courts       : action.data.courts,
            durations    : action.data.durations,
            totalPlayers : action.data.totalPlayers,
        };

    case "RESERVE_EDIT":
        return {
            ...state,
            error        : false,
            edition      : state.edition + (action.data.isInitial ? 1 : 0),
            reserveTypes : action.data.reserveTypes,
            reserveType  : action.data.reserveType,
            hours        : action.data.hours,
            courts       : action.data.courts,
            durations    : action.data.durations,
            totalPlayers : action.data.totalPlayers,
        };

    case "RESERVE_FILTER":
        return {
            ...state,
            error        : false,
            edition      : state.edition + 1,
            reserveTypes : action.data.reserveTypes,
            courts       : action.data.courts,
            statuses     : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
