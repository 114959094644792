import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Person View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PersonView(props) {
    const { open, elemID, onSubmit, onClose } = props;

    const { elem, statuses, groups } = Store.useState("coursePerson");
    const { editElem } = Store.useAction("coursePerson");


    // The Current State
    const initialData = {
        subscriptionID : 0,
        courseID       : 0,
        coursePersonID : 0,
        courseGroupID  : 0,
        status         : "Pending",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("coursePerson", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("coursePerson", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="subscription"
        title="COURSES_PERSONS_VIEW_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns amount="2">
            <ViewField
                label="GENERAL_CREATED_AT_FEM"
                value={elem.createdTimeText}
            />
            <ViewField
                label="GENERAL_NUMBER"
                value={elem.numberText}
            />
            <ViewField
                label="GENERAL_MEMBER_RESPONSIBLE"
                value={elem.parentName}
            />
            <ViewField
                label="COURSES_PERSONS_SINGULAR"
                value={elem.name}
            />
            <ViewField
                label="GENERAL_TYPE"
                value={elem.personTypeName}
            />
            <ViewField
                label="GENERAL_DOCUMENT_VALUE"
                value={elem.document}
            />
            <ViewField
                label="GENERAL_BIRTH_DATE"
                value={elem.birthDate}
            />
            <ViewField
                label="GENERAL_AGE"
                value={elem.age}
            />
            <ViewField
                className="columns-double"
                label="COURSES_QUESTION_ANSWERS"
                value={elem.answersText}
            />
        </Columns>

        <Columns amount="1" className="top-space-huge">
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
                value={data.status}
                error={errors.status}
                onChange={handleChange}
                hideClear
                isRequired
            />
            <InputField
                isHidden={data.status !== "Approved" || !groups.length}
                type="select"
                name="courseGroupID"
                label="COURSES_GROUP"
                options={groups}
                value={data.courseGroupID}
                error={errors.courseGroupID}
                onChange={handleChange}
                isRequired
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PersonView.propTypes = {
    open     : PropTypes.bool.isRequired,
    onSubmit : PropTypes.func.isRequired,
    onClose  : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default PersonView;
