import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import MemberFilter         from "./MemberFilter";
import MemberView           from "./MemberView";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";



/**
 * The Member List
 * @returns {React.ReactElement}
 */
function MemberList() {
    const { fetch, load, loadFilter } = useList("member");

    const data = Store.useState("member");
    const { loading, canEdit, list, total, filters, sort } = data;

    const { registerElem, unregisterElem } = Store.useAction("member");


    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
    };

    // Handles the Register Submit
    const handleRegister = async () => {
        const response = await registerElem(elemID);
        if (response.success) {
            fetch();
        }
        endAction();
    };

    // Handles the Unregister Submit
    const handleUnregister = async () => {
        const response = await unregisterElem(elemID);
        if (response.success) {
            fetch();
        }
        endAction();
    };


    // Returns true if the Member can be registered
    const canRegister = (elemID) => {
        const isRegistered = Utils.getValue(list, "memberNumber", elemID, "isRegistered");
        return !(canEdit && !isRegistered);
    };

    // Returns true if the Member can be unregistered
    const canUnregister = (elemID) => {
        const isRegistered = Utils.getValue(list, "memberNumber", elemID, "isRegistered");
        return !(canEdit && isRegistered);
    };


    // Do the Render
    const elemName = Utils.getValue(list, "memberNumber", elemID, "fullName");

    return <Main>
        <Header message="MEMBERS_NAME" icon="member">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="MEMBERS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="memberNumber" message="GENERAL_MEMBER_NUMBER" isTitle maxWidth="160" />
                    <TableHeader field="familyGroup"  message="MEMBERS_FAMILY_GROUP"  maxWidth="160" />
                    <TableHeader field="dni"          message="GENERAL_DNI"           />
                    <TableHeader field="firstName"    message="GENERAL_FIRST_NAME"    />
                    <TableHeader field="lastName"     message="GENERAL_LAST_NAME"     />
                    <TableHeader field="email"        message="GENERAL_EMAIL"         />
                    <TableHeader field="categoryCode" message="CATEGORIES_SINGULAR"   />
                    <TableHeader field="stateCode"    message="GENERAL_STATUS"        />
                    <TableHeader field="isRegistered" message="MEMBERS_IS_REGISTERED" maxWidth="90" align="center" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.memberNumber} elemID={elem.memberNumber}>
                        <TableCell message={elem.memberNumber}     />
                        <TableCell message={elem.familyGroup}      />
                        <TableCell message={elem.dni}              />
                        <TableCell message={elem.firstName}        />
                        <TableCell message={elem.lastName}         />
                        <TableCell message={elem.email}            />
                        <TableCell message={elem.categoryName}     />
                        <TableCell message={elem.stateName}        />
                        <TableCell message={elem.isRegisteredText} className={elem.isRegisteredClass} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="VIEW"       message="MEMBERS_VIEW_TITLE"       />
                    <TableAction action="REGISTER"   message="MEMBERS_REGISTER_TITLE"   hide={canRegister} />
                    <TableAction action="UNREGISTER" message="MEMBERS_UNREGISTER_TITLE" hide={canUnregister} />
                </TableActionList>
            </Table>
        </Content>

        <MemberFilter
            open={action.isFilter}
            filters={filters}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <MemberView
            open={action.isView}
            elemID={elemID}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isRegister}
            icon="check"
            title="MEMBERS_REGISTER_TITLE"
            message="MEMBERS_REGISTER_TEXT"
            content={elemName}
            onSubmit={handleRegister}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isUnregister}
            icon="check"
            title="MEMBERS_UNREGISTER_TITLE"
            message="MEMBERS_UNREGISTER_TEXT"
            content={elemName}
            onSubmit={handleUnregister}
            onClose={endAction}
        />
    </Main>;
}

export default MemberList;
