import React                from "react";

// Components
import NewsList             from "./News/NewsList";
import CategoryList         from "./Category/CategoryList";
import BillboardList        from "./Billboard/BillboardList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Administration Container
 * @returns {React.ReactElement}
 */
function AdministrationContainer() {
    return <>
        <SecondaryNav
            icon="entry"
            message="ENTRIES_NAME"
            noBack
        >
            <NavigationList>
                <NavigationItem
                    message="NEWS_NAME"
                    url="NEWS"
                    icon="news"
                />
                <NavigationItem
                    message="CATEGORIES_NAME"
                    url="CATEGORIES"
                    icon="category"
                />
            </NavigationList>
            <NavigationList>
                <NavigationItem
                    message="BILLBOARDS_NAME"
                    url="BILLBOARD"
                    icon="billboard"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="NEWS"       component={NewsList}      />
            <AdminRoute url="CATEGORIES" component={CategoryList}  />
            <AdminRoute url="BILLBOARD"  component={BillboardList} />
        </Router>
    </>;
}

export default AdministrationContainer;
