import React                from "react";

// Components
import ScheduleList         from "./Schedule/ScheduleList";
import CategoryList         from "./Category/CategoryList";
import HolidayList          from "./Holiday/HolidayList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Schedule Container
 * @returns {React.ReactElement}
 */
function ScheduleContainer() {
    return <>
        <SecondaryNav icon="schedule" message="SCHEDULES_SINGULAR">
            <NavigationList>
                <NavigationItem
                    message="SCHEDULES_NAME"
                    url="SCHEDULES"
                    icon="schedule"
                />
                <NavigationItem
                    message="CATEGORIES_NAME"
                    url="CATEGORIES"
                    icon="category"
                />
                <NavigationItem
                    message="HOLIDAYS_NAME"
                    url="HOLIDAYS"
                    icon="holiday"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="SCHEDULES"  component={ScheduleList} />
            <AdminRoute url="CATEGORIES" component={CategoryList} />
            <AdminRoute url="HOLIDAYS"   component={HolidayList}  />
        </Router>
    </>;
}

export default ScheduleContainer;
