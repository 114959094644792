import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Course Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CourseEdit(props) {
    const { open, elemID, activityID, onClose, onSubmit } = props;

    const { activities, statuses } = Store.useState("shiftCourse");
    const { editElem } = Store.useAction("shiftCourse");


    // The Initial Data
    const initialData = {
        shiftCourseID : 0,
        activityID    : 0,
        name          : "",
        description   : "",
        isPast        : 0,
        status        : "Active",
        position      : 0,
    };

    // Handles the Edit
    const handleEdit = (data) => {
        if (activityID) {
            return editElem({ ...data, activityID });
        }
        return editElem({ ...data });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("shiftCourse", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("shiftCourse", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="course"
        title={elemID ? "COURSES_EDIT_TITLE" : "COURSES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!!activityID}
            type="select"
            name="activityID"
            label="ACTIVITIES_SINGULAR"
            options={activities}
            value={data.activityID}
            error={errors.activityID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="textarea"
            name="description"
            label="GENERAL_DESCRIPTION"
            value={data.description}
            error={errors.description}
            onChange={handleChange}
        />
        <InputField
            type="toggle"
            name="isPast"
            label="COURSES_IS_PAST"
            value={data.isPast}
            error={errors.isPast}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
            minValue={0}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CourseEdit.propTypes = {
    open       : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    elemID     : PropTypes.number,
    activityID : PropTypes.number,
};

export default CourseEdit;
