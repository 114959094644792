import React                from "react";

// Components
import MemberList           from "./Member/MemberList";
import StateList            from "./State/StateList";
import CategoryList         from "./Category/CategoryList";
import RelationList         from "./Relation/RelationList";
import ProvinceList         from "./Province/ProvinceList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Member Container
 * @returns {React.ReactElement}
 */
function MemberContainer() {
    return <>
        <SecondaryNav icon="member" message="MEMBERS_NAME">
            <NavigationList>
                <NavigationItem
                    message="MEMBERS_NAME"
                    url="MEMBERS"
                    icon="member"
                />
                <NavigationItem
                    message="MEMBERS_STATES_NAME"
                    url="STATES"
                    icon="state"
                />
                <NavigationItem
                    message="CATEGORIES_NAME"
                    url="CATEGORIES"
                    icon="category"
                />
                <NavigationItem
                    message="MEMBERS_RELATIONS_NAME"
                    url="RELATIONS"
                    icon="relation"
                />
                <NavigationItem
                    message="PROVINCES_NAME"
                    url="PROVINCES"
                    icon="province"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="MEMBERS"    component={MemberList}   />
            <AdminRoute url="STATES"     component={StateList}    />
            <AdminRoute url="CATEGORIES" component={CategoryList} />
            <AdminRoute url="RELATIONS"  component={RelationList} />
            <AdminRoute url="PROVINCES"  component={ProvinceList} />
        </Router>
    </>;
}

export default MemberContainer;
