import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Contact Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { categories, activities, statuses } = Store.useState("contact");
    const { editElem } = Store.useAction("contact");


    // The Initial Data
    const initialData = {
        contactID         : 0,
        contactCategoryID : 0,
        activityID        : 0,
        name              : "",
        whatsApp          : "",
        phone             : "",
        email             : "",
        timeTables        : "[{}]",
        status            : "Active",
        position          : 0,
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({
            ...elem,
            timeTables : elem?.timeTables?.length ? JSON.stringify(elem.timeTables) : "[{}]",
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("contact", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("contact", open, elemID, {}, handleSet);


    // Do the Render
    return <EditDialog
        open={open}
        icon="contact"
        title={elemID ? "CONTACTS_EDIT_TITLE" : "CONTACTS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />

        <Columns>
            <InputField
                type="select"
                name="contactCategoryID"
                label="CATEGORIES_SINGULAR"
                options={categories}
                value={data.contactCategoryID}
                error={errors.contactCategoryID}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="activityID"
                label="ACTIVITIES_SINGULAR"
                options={activities}
                value={data.activityID}
                error={errors.activityID}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="textarea"
            name="description"
            label="GENERAL_DESCRIPTION"
            value={data.description}
            error={errors.description}
            onChange={handleChange}
        />

        <Columns amount="3">
            <InputField
                type="number"
                name="whatsApp"
                label="GENERAL_WHATSAPP"
                value={data.whatsApp}
                error={errors.whatsApp}
                onChange={handleChange}
            />
            <InputField
                type="number"
                name="phone"
                label="GENERAL_PHONE"
                value={data.phone}
                error={errors.phone}
                onChange={handleChange}
            />
            <InputField
                name="email"
                label="GENERAL_EMAIL"
                value={data.email}
                error={errors.email}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="fields"
            name="timeTables"
            title="GENERAL_PERIOD_TITLE"
            addButton="GENERAL_ADD_PERIOD"
            value={data.timeTables}
            errors={errors}
            onChange={handleChange}
            columns="2"
            isSortable
        >
            <InputItem
                type="chooser"
                name="days"
                label="GENERAL_DAYS"
                options="DATE_DAY_HOLIDAY_NAMES_MONDAY"
                columns="2"
                isRequired
            />
            <InputItem
                type="time"
                name="from"
                label="GENERAL_FROM"
                isRequired
            />
            <InputItem
                type="time"
                name="to"
                label="GENERAL_TO"
                isRequired
            />
        </InputField>

        <Columns>
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
                value={data.status}
                error={errors.status}
                onChange={handleChange}
                hideClear
                isRequired
            />
            <InputField
                type="number"
                name="position"
                label="GENERAL_POSITION"
                value={data.position}
                error={errors.position}
                onChange={handleChange}
                minValue={0}
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default ContactEdit;
