import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    TrainingMember,
} from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canFilter : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    whiteList : {},
    elem      : {},
    statuses  : [],
    sort      : {
        orderBy  : "memberNumber",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "TRAINING_MEMBER_LOADING" });
    },

    /**
     * Fetches the Training White List List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "TRAINING_WHITE_LIST") {
            params.trainingWhiteListID = elemID;
        }

        const data = await TrainingMember.getAll(params);
        data.sort  = params;
        dispatch({ type : "TRAINING_MEMBER_LIST", data });
    },

    /**
     * Fetches a single Training Member
     * @param {Function} dispatch
     * @param {Number}   trainingMemberID
     * @returns {Promise}
     */
    async fetchElem(dispatch, trainingMemberID) {
        const data = await TrainingMember.getOne({ trainingMemberID });
        dispatch({ type : "TRAINING_MEMBER_ELEM", data });
    },

    /**
     * Fetches the Training Member Edit data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchEditData(dispatch) {
        const data = await TrainingMember.getEditData();
        dispatch({ type : "TRAINING_MEMBER_EDIT", data });
    },

    /**
     * Edits/Creates a Training Member
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return TrainingMember.edit(data);
    },

    /**
     * Deletes a Training Member
     * @param {Function} dispatch
     * @param {Number}   trainingMemberID
     * @returns {Promise}
     */
    deleteElem(dispatch, trainingMemberID) {
        return TrainingMember.delete({ trainingMemberID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.isPastText = Utils.toYesNo(elem.isPast);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "TRAINING_MEMBER_LIST", "TRAINING_MEMBER_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "TRAINING_MEMBER_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "TRAINING_MEMBER_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canFilter : action.data.canFilter,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
            whiteList : action.data.whiteList,
            sort      : action.data.sort,
        };

    case "TRAINING_MEMBER_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            elem      : parseElem(action.data.elem),
            statuses  : action.data.statuses,
        };

    case "TRAINING_MEMBER_EDIT":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            statuses  : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
