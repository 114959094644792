import React                from "react";
import PropTypes            from "prop-types";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import useDialog            from "Dashboard/Hooks/Dialog";
import useForm              from "Dashboard/Hooks/Form";
import { Member }           from "Utils/API";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Subscription Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubscriptionEdit(props) {
    const { open, trainingID, elemID, onClose, onSubmit } = props;

    const { members, statuses } = Store.useState("trainingSubscription");
    const { fetchEditData, editElem } = Store.useAction("trainingSubscription");


    // The Initial Data
    const initialData = {
        subscriptionID      : 0,
        trainingID          : 0,
        memberNumber        : 0,
        memberName          : "",
        subscriptionType    : "Me",
        relatedMemberNumber : 0,
        file                : null,
        fileName            : "",
        fileUrl             : "",
        useWhiteList        : 0,
        status              : "Pending",
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({ ...elem, trainingID });
    };

    // Handles the Date
    const handleUpdate = async (name, value, suggestID, newID) => {
        handleChange(name, value, suggestID, newID);
        if (suggestID === "memberNumber") {
            await fetchEditData(data.trainingID, newID, false);
        }
    };

    // Handles the File
    const handleFile = () => {
        if (data.fileUrl) {
            Navigate.gotoUrl(data.fileUrl, true);
        }
    };

    // The Form State
    const {
        data, errors, setElem, setError, handleChange, handleSubmit,
    } = useForm("trainingSubscription", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("trainingSubscription", open, elemID, { trainingID }, handleSet);


    // Variables
    const isEdit = Boolean(elemID);


    // Do the Render
    return <EditDialog
        open={open}
        icon="subscription"
        title={isEdit ? "SUBSCRIPTIONS_EDIT_TITLE" : "SUBSCRIPTIONS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            type="suggest"
            name="memberName"
            label="GENERAL_MEMBER"
            value={data.memberName}
            error={errors.memberNumber}
            suggestID="memberNumber"
            suggestFetch={Member.search}
            onChange={handleUpdate}
            isRequired
        />

        <InputField
            type="radio"
            name="subscriptionType"
            label="SUBSCRIPTIONS_TYPE"
            options="SELECT_SUBSCRIPTION_TYPES"
            value={data.subscriptionType}
            error={errors.subscriptionType}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={data.subscriptionType === "Me" || !data.memberNumber}
            type="select"
            name="relatedMemberNumber"
            label="SUBSCRIPTIONS_MEMBER"
            options={members}
            value={data.relatedMemberNumber}
            error={errors.relatedMemberNumber}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="file"
            name="file"
            label="TRAININGS_FILE"
            helperText="GENERAL_FILE_SIZE_TIP"
            button={data.fileUrl ? "GENERAL_FILE" : ""}
            onClick={handleFile}
            value={data.fileName}
            error={errors.file}
            onChange={handleUpdate}
            onError={setError}
            maxSize={process.env.REACT_APP_MAX_SIZE}
        />

        <InputField
            type="toggle"
            name="useWhiteList"
            label="TRAININGS_USE_WHITE_LIST"
            value={data.useWhiteList}
            error={errors.useWhiteList}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!!data.useWhiteList}
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubscriptionEdit.propTypes = {
    open       : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    trainingID : PropTypes.number,
    elemID     : PropTypes.number,
};

export default SubscriptionEdit;
